import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-toastify';
import ProviderInfo from './components/ProviderInfo';
import handleResponse from '../common/ResponseHandler'
import { useAuth0 } from '@auth0/auth0-react';

const NewProvider = () => {
    const [state, setState] = useState({
        saveLoading: false,
        name: "",
        address: "",
        contactName: "",
        contactPhoneNumber: "",
        contactEmail: ""
    });

    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();

    const handleTextInputChange = (evt) => {
        setState({
            ...state,
            [evt.target.name]: evt.target.value
        });
    };

    const saveProvider = async () => {
        setState({ ...state, saveLoading: true });

        if (!state.name) {
            toast.error("Provider Creation Failed: Missing Name.", { toastId: "provider-missing-name" });
            setState({ ...state, saveLoading: false });
            return;
        }

        if (!state.address) {
            toast.error("Provider Creation Failed: Missing Address.", { toastId: "provider-missing-address" });
            setState({ ...state, saveLoading: false });
            return;
        }

        if (!state.contactName) {
            toast.error("Provider Creation Failed: Missing Contact Name.", { toastId: "provider-missing-contactName" });
            setState({ ...state, saveLoading: false });
            return;
        }

        if (!state.contactPhoneNumber) {
            toast.error("Provider Creation Failed: Missing Contact Phone Number.", { toastId: "provider-missing-contactPhoneNumber" });
            setState({ ...state, saveLoading: false });
            return;
        }

        if (!state.contactEmail) {
            toast.error("Provider Creation Failed: Missing Contact Email.", { toastId: "provider-missing-contactEmail" });
            setState({ ...state, saveLoading: false });
            return;
        }

        const gsmNumberRegex = /^\+[0-9\s]{3,20}$/;
        if (!gsmNumberRegex.test(state.contactPhoneNumber)) {
            toast.error("Provider Creation Failed: Invalid Phone Number", { toastId: "provider-invalid-contactPhoneNumber" });
            setState({ ...state, saveLoading: false });
            return;
        }


        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(state.contactEmail)) {
            toast.error("Provider Creation Failed: Invalid Email", { toastId: "provider-invalid-contactEmail" });
            setState({ ...state, saveLoading: false });
            return;
        }

        var provider = {
            Name: state.name,
            Address: state.address,
            ContactName: state.contactName,
            ContactPhoneNumber: state.contactPhoneNumber,
            ContactEmail: state.contactEmail
        };
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.post("api/provider/addprovider", provider, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Provider Creation Failed.");
            if (!response.data?.isError) {
                setState({
                    name: "",
                    address: "",
                    contactName: "",
                    contactPhoneNumber: "",
                    contactEmail: "",
                    saveLoading: false
                });

                navigate("/network/editprovider/" + response.data.data);
            }
        } catch (error) {
            toast.error("Provider Creation Failed.", { toastId: "provider-creationFailed-Failed" });
            setState({ ...state, saveLoading: false });
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="card">                       
                        <div className="card-body">
                            <h2 className="card-subtitle text-secondary">Create New Provider</h2>
                            <hr className="hr-fade-30 m-0 mb-1" />
                            <ProviderInfo
                                name={state.name}
                                address={state.address}
                                contactName={state.contactName}
                                contactPhoneNumber={state.contactPhoneNumber}
                                contactEmail={state.contactEmail}
                                handleTextInputChange={handleTextInputChange}
                                loading={false}
                            />
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-primary" disabled={state.saveLoading} onClick={saveProvider}>Save New Provider</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewProvider;