import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import moment from 'moment';
import handleResponse from '../common/ResponseHandler'
import { useAuth0 } from '@auth0/auth0-react';
import DataTable from 'react-data-table-component';
import customStyles from '../common/CustomDataTableStyle';


const HomeProjectDetailsNetwork = () => {
    const [state, setState] = useState({
        project: {},
        questions: [],
        invoiceLoaded: false,
        currentActiveTabIndex: 0
    });

    let { id } = useParams();

    let rejectColumns = [
        {
            name: "Rejected By",
            selector: row => <a className="btn btn-link" href={"network/editprovider/" + row.provider.id} >#{row.provider.id} - {row.provider.name}</a>
        },
        {
            name: "Reject Date",
            selector: row => <span>{moment(row.createDate).format('DD.MM.YYYY HH:mm')}</span>
        },
        {
            name: "Is Auto Rejected",
            selector: row => <span>{row.isAutoRejected? "Yes" : "No"}</span>
        },
    ];

    const { getAccessTokenSilently } = useAuth0();

    const getHomeProject = async provider => {
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get(`api/homeproject/get?id=${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Project retrieval failed.", true);
            if (!response.data.isError) {
                var questions = []
                response.data.data.homeProjectAnswers.forEach(x => {
                    if (!questions.some(y => y.id === x.answer.question.id)) {
                        questions.push({
                            id: x.answer.questionId,
                            questionText: x.answer.question.questionText
                        })
                    }
                });
                setState({
                    ...state,
                    project: response.data.data,
                    questions: questions,
                    invoiceLoaded: !isNullOrWhiteSpace(response.data.data.providerInvoiceFileName)
                });
            }
        } catch (error) {
            toast.error("Project retrieval failed.");
        }

    };

    const GetFile = async (fileName) => {
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get(`api/homeproject/getfile?name=${fileName}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.data.isError) {
                window.open(response.data.data, '_blank');
            }

        } catch (error) {
            toast.error("File retrieval failed.");
        }
    };

    const GetInvoice = async () => {
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get(`api/homeproject/getinvoice?name=${state.project.providerInvoiceFileName}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.data.isError) {
                window.open(response.data.data, '_blank');
            }

        } catch (error) {
            toast.error("File retrieval failed.");
        }
    };

    const CancelProject = async () => {
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get(`api/homeproject/cancelproject?id=${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.data.isError) {
                getHomeProject();
            }

        } catch (error) {
            toast.error("Cancel failed");
        }
    };

    const isNullOrWhiteSpace = (input) => {
        return input === null || input === undefined || input.trim() === '';
    }

    const updateTabIndex = (index) => {
        setState({ ...state, currentActiveTabIndex: index });
    }

    useEffect(() => {
        getHomeProject()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <ul className="nav mb-1 border-bottom" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                        className={"nav-link " + (state.currentActiveTabIndex === 0 ? "active" : "")}
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-providerInfo"
                        type="button"
                        role="tab"
                        onClick={() => updateTabIndex(0)}
                    >
                        Information
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={"nav-link " + (state.currentActiveTabIndex === 1 ? "active" : "")}
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-providerInfo"
                        type="button"
                        role="tab"
                        onClick={() => updateTabIndex(1)}
                    >
                        Provider Rejects
                    </button>
                </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
                <div className={"tab-pane fade " + (state.currentActiveTabIndex === 0 ? "show active" : "")} id="pills-providerInfo" role="tabpanel" tabIndex="0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card axa-provider-info-card">
                                <div className="card-body">
                                    <h2 className="card-subtitle text-secondary mt-4">Project Details</h2>
                                    <hr className="hr-fade-20 m-0 mb-3" />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4>Project Information</h4>
                                            <div className="axa-provider-info-card p-3 mb-3">
                                                <p><strong>Project ID:</strong> {state.project?.id}</p>
                                                <p><strong>Create Date:</strong> {moment(state.project?.createDate).format('DD.MM.YYYY HH:mm')}</p>
                                                <p><strong>Service Name:</strong> {state.project?.service?.name}</p>
                                                <p><strong>Customer Description:</strong> {state.project?.customerDescription}</p>
                                                <p><strong>Last Status:</strong> {state.project?.projectStatus?.name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h4>Assigned Provider</h4>
                                            <div className="axa-provider-info-card p-3 mb-3">
                                                {state.project?.provider ? (
                                                    <div>
                                                        <p><strong>Assignment Date:</strong> {moment(state.project?.providerAssignDate).format('DD.MM.YYYY HH:mm')}</p>
                                                        <p><strong>Name:</strong> {state.project?.provider?.name}</p>
                                                        <p><strong>Contact Name:</strong> {state.project?.provider?.contactName}</p>
                                                        <p><strong>Contact Email Address:</strong> {state.project?.provider?.contactEmail}</p>
                                                        <p><strong>Contact Phone Number:</strong> {state.project?.provider?.contactPhoneNumber}</p>
                                                    </div>) : (
                                                    <p><strong>There is no assigned provider.</strong></p>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4>Customer Information</h4>
                                            <div className="axa-provider-info-card p-3 mb-3">
                                                <p><strong>Customer First Name:</strong> {state.project?.customerName}</p>
                                                <p><strong>Customer Last Name:</strong> {state.project?.customerLastName}</p>
                                                <p><strong>Customer Telephone Number:</strong> {state.project?.customerGSM}</p>
                                                <p><strong>Customer Email:</strong> {state.project?.customerEmail}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h4>Customer Address Details</h4>
                                            <div className="axa-provider-info-card p-3 mb-3">
                                                <p><strong>Customer Street/House Number:</strong> {state.project?.customerStreetHouseNumber}</p>
                                                <p><strong>Customer Bus:</strong> {state.project?.customerBus}</p>
                                                <p><strong>City:</strong> {state.project?.postalArea?.city}</p>
                                                <p><strong>City Postal Area:</strong> {state.project?.postalArea?.cityPostalArea}</p>
                                                <p><strong>Post Code:</strong> {state.project?.postalArea?.postCode}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {state.questions.map((question, index) => {
                                            return (
                                                <div key={"customer-questions-" + index} className="col-md-6">
                                                    <h4>{question.questionText}</h4>
                                                    <div className="axa-provider-info-card p-3 mb-3">
                                                        <ul className="list-group">
                                                            {state.project.homeProjectAnswers.filter(x => x.answer.questionId === question.id).map((answer, index) => {
                                                                return (
                                                                    <li key={"customer-answers-" + index} className="list-group-item">
                                                                        {answer.answer.answerText}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6" style={{ display: (state.project?.homeProjectFile?.length === 0 ? "none" : "") }} >
                                            <h4>Customer Files</h4>
                                            <div className="axa-provider-info-card p-3 mb-3">
                                                <ul className="list-group">
                                                    {state.project?.homeProjectFile?.map((file, index) => {
                                                        return (
                                                            <li key={"customer-files-" + index} className="list-group-item p-0">
                                                                <button className="btn btn-link" onClick={() => GetFile(file.fileName)}>
                                                                    <FontAwesomeIcon className="text-primary me-3" icon={faDownload} />{file.fileName.substring(36)}
                                                                </button>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4>Project Status</h4>
                                            <div className="axa-provider-info-card p-3 mb-3">
                                                <p><strong>Project Status:</strong> {state.project?.projectStatus?.name}</p>
                                                <div className="d-flex flex-column flex-md-column flex-lg-row">
                                                    {(state.project?.projectStatus?.id !== 5 && state.project?.projectStatus?.id !== 4) ? (<>
                                                        <button className="btn btn-outline-danger flex-fill mx-1 mt-1" onClick={() => CancelProject()} >Cancel Project</button>
                                                    </>) : ""}
                                                </div>
                                                <div className="d-flex flex-column flex-md-column flex-lg-row">
                                                    {state.project?.noAgreementText ? (<div className="flex-fill">
                                                        <hr />
                                                        <p>
                                                            <strong>Provider Description: </strong>
                                                            {state.project?.noAgreementText}
                                                        </p>
                                                    </div>) : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h4>Invoice</h4>
                                            <div className="axa-provider-info-card p-3 mb-3">
                                                <ul className="list-group" style={{ display: (state.invoiceLoaded ? "" : "none") }}>
                                                    <li className="list-group-item p-0">
                                                        <button className="btn btn-link" onClick={() => GetInvoice()}>
                                                            <FontAwesomeIcon className="text-primary me-3" icon={faDownload} />Invoice
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"tab-pane fade " + (state.currentActiveTabIndex === 1 ? "show active" : "")} id="pills-providerInfo" role="tabpanel" tabIndex="0">
                    <DataTable
                        customStyles={customStyles}
                        data={state.project?.homeProjectRejects}
                        columns={rejectColumns}
                        pagination
                        striped
                    />
                    
                </div>
            </div>

        </div>
    );
};

export default HomeProjectDetailsNetwork;