import React, { useState, useEffect,  } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/common/Layout';
import { ToastContainer, Slide } from 'react-toastify';

import './css/custom-styles.scss';
import './css/axa.scss';
import "./css/axa-lib/css/axaDesignSystemLib.css"
import 'react-toastify/dist/ReactToastify.css';
import LocalStorageChecker from './components/common/LocalStorageChecker';

const App = () => {
    const [localStorageData, setLocalStorageData] = useState(localStorage.getItem('loginType'));

    useEffect(() => {
        // Function to handle changes in local storage
        const handleStorageChange = (e) => {
            if (e.key === 'loginType') {
                // Update the state with the new local storage data
                setLocalStorageData(e.newValue);
            }
        };

        // Add event listener to detect changes in local storage 
        window.addEventListener('storage', handleStorageChange); 

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <>
            <Layout>
                <LocalStorageChecker />
                <Routes>
                    {AppRoutes.filter(x => x.allow.some(y => y === localStorage.getItem('loginType')) || x.allow.length === 0).map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                </Routes>
            </Layout>
            <ToastContainer
                theme="colored"
                transition={Slide}
                className="mt-5"
            />
        </>
    );
}

export default App;
