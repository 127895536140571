import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const handleResponse = (response, callErrorMessage, useId = true) => {
    if (response.status === 200) {
        if (response.data.isError) {
            toast.error(response.data.userMessage, { toastId: useId ? response.data.userMessage : uuidv4() });
        } else {
            toast.success(response.data.userMessage, { toastId: useId ? response.data.userMessage : uuidv4() });
        }
    } else {
        toast.error(callErrorMessage, { toastId: useId ? callErrorMessage : uuidv4() });
    }
};

export default handleResponse;