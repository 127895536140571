import React, { useEffect } from 'react';

const CookieSettings = () => {
    useEffect(() => {
        try {
            window.OneTrust.initializeCookiePolicyHtml(true);
        } catch {

        }
        
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            {/*<div id="ot-sdk-cookie-policy"></div>*/}
            <div className="row justify-content-center mt-4">
                <div className="col-4" align="center">
                    <button id="ot-sdk-btn" className="ot-sdk-show-settings text-black">Cookie Settings</button>
                </div>
            </div>
            
        </div>
    );
};

export default CookieSettings;