import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const UserContext = createContext();

const Auth0ProviderWithHistory = ({ children }) => {
    const [userData, setUserData] = useState({});

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };
    
    useEffect(() => {
        if (!localStorage.getItem("language")) {
            var browserLanguage = (navigator.language || navigator.userLanguage).toUpperCase()
            if (browserLanguage === "FR" || browserLanguage === "NL") {
                localStorage.setItem("language", (navigator.language || navigator.userLanguage).toUpperCase());
            } else {
                localStorage.setItem("language", "FR");
            }
        }

        setUserData({
            ...userData,
            language: localStorage.getItem("language")
        })

        document.documentElement.lang = localStorage.getItem("language").toLowerCase();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <UserContext.Provider value={{ userData, setUserData }}>
            <Auth0Provider
                domain={domain}
                clientId={clientId}
                authorizationParams={{
                    redirect_uri: window.location.origin + "/login/landingpage",
                    audience: "https://freemium-api.axa-assistance.com",
                    scope: "openid offline_access profile email urn:axa.partners.home.assistance.projects.read_write urn:axa.partners.home.assistance.providers.read_write",
                }}
                onRedirectCallback={onRedirectCallback}
                cacheLocation="localstorage"
                useRefreshTokens="true"
            >

                {children}
            </Auth0Provider>
        </UserContext.Provider >
    );
};

export { UserContext, Auth0ProviderWithHistory };