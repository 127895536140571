import { Link } from 'react-router-dom'

const CookiePolicyFR = () => {
    return (
        <div align="left">
            <div align="left">
                <div className="mb-4" align="center">
                    <h2>POLITIQUE RELATIVE AU DEPOT DE COOKIES</h2>
                </div>
                <div className="axa-bg-viridian p-5 pb-3 mb-5 text-white" align="center">
                    <p>Un cookie est un petit fichier de données (fichier texte) qu'un site internet, lorsqu'il est consulté par un utilisateur, demande à votre navigateur de stocker sur votre appareil afin de mémoriser des informations vous concernant, telles que vos préférences linguistiques ou informations de connexion. AXA Partners collecte des cookies lors de votre visite sur le site internet « HomeCare.be » et configure les cookies appelés cookies « internes ». </p>
                </div>
                <div className="mb-4">
                    <h3>MISE A JOUR DE LA PRESENTE POLITIQUE DE CONFIDENTIALITE </h3>
                    <p>AXA Partners peut mettre à jour cette politique en réponse à des changements relatifs au dépôt de cookies sur le site HomeCare.be. Cette politique a été mise à jour pour la dernière fois le 04/12/2023.</p>
                </div>
                <div className="mb-4">
                    <h3>QUI SONT LES RESPONSABLES DE TRAITEMENT ?</h3>
                    <p>INTER PARTNER ASSISTANCE, Société anonyme de droit belge dont le siège social est établi Boulevard du Régent 7 à B-1000 Bruxelles, inscrite à la Banque-Carrefour des Entreprises sous le n° 0415.591.055, agit en tant que Responsable de traitement de vos données à caractère personnel (c’est-à-dire qu’il détermine les finalités et les moyens du traitement de vos informations).</p>
                    <p>Le responsable de traitement est désigné dans la présente Politique par « AXA Partners » ou « le Responsable de traitement » ou « nous ».</p>
                </div>
                <div className="axa-bg-viridian p-5 pb-3 mb-5 text-white" align="left">
                    <h3>QUELS SONT VOS DROITS SUR VOS DONNEES A CARACTERE PERSONNEL ? </h3>
                    <p>
                        Il est possible que le dépôt de ces cookies implique un traitement de données à caractère personnel. Dans un tel cas, et conformément au RGPD, vous disposez de droits les concernant. Pour les connaître, veuillez-vous référer à la <Link to="/privacypolicy" className="axa-text-trendy-pink">Politique de confidentialité</Link>.
                    </p>
                    <p className="mb-4">
                        Par ailleurs, vous disposez du droit de retirer votre consentement (« opt-out ») au dépôt de cookies optionnels. Vous modifier vos préférences à tout moment pour tout ou partie des cookies déposés via le <Link to="/cookiesettings"> Centre de Préférence Cookies</Link>.
                    </p>
                    <h3>COMMENT CONTACTER LE DELEGUE A LA PROTECTION DES DONNEES (« DPD » OU « DPO ») POUR TOUTE QUESTION OU COMMENT EXERCER VOS DROITS ?</h3>
                    <p>Pour toute question, réclamation ou commentaire concernant la présente Politique ou pour exercer vos droits, veuillez adresser votre demande à dpo.bnl@axa-assistance.com. AXA Partners peut vous demander des renseignements complémentaires pour confirmer votre identité et / ou pour aider AXA Partners à trouver les données que vous recherchez.</p>
                    <h3>COMMENT EMETTRE UNE RECLAMATION AUPRES D’UNE AUTORITE DE CONTRÔLE ?</h3>
                    <p>
                        Vous avez le droit de faire part, à une autorité de contrôle compétente, en particulier dans l'Etat membre de votre résidence habituelle, de votre lieu de travail ou du lieu où vous pensez qu’un manquement à vos droits s'est produit, de toute préoccupation concernant la manière dont vos données à caractère personnel sont traitées La liste des autorités de contrôle au sein de l’Union Européenne est disponible en cliquant sur le lien suivant: <Link to="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"> https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm</Link>
                        . Si votre pays est situé hors Union Européenne, veuillez-vous référer au site internet de votre autorité de contrôle en matière de protection des données.
                    </p>
                </div>
                <div>
                    <h3 className="mb-4">QUELLES SONT LES CATEGORIES DE COOKIES DEPOSES LORS DE VOTRE VISITE ET QUELLES EN SONT LES FINALITES ?</h3>
                    <div className="row">
                        <div className="col-12 d-grid">
                            <table className="border border-info">
                                <thead className="bg-info text-white">
                                    <tr>
                                        <th className="p-1 pt-2">
                                            <h3>COOKIES FONCTIONNELS ET TECHNIQUES (COOKIES STRICTEMENT NÉCESSAIRES)</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="p-1 pt-2">
                                            <p>Ces cookies sont nécessaires au fonctionnement du site internet et ne peuvent pas être désactivés dans nos systèmes. Ils sont généralement établis en tant que réponse à des actions que vous avez effectuées et qui constituent une demande de services, telles que la définition de vos préférences en matière de confidentialité, la connexion ou le remplissage de formulaires. Vous pouvez configurer votre navigateur afin de bloquer ou être informé de l'existence de ces cookies, mais certaines parties du site internet peuvent être affectées.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="border border-info mb-4">
                                <thead className="bg-info text-white">
                                    <tr>
                                        <th className="p-1 pt-2">
                                            <h4>Sous-groupe de cookies</h4>
                                        </th>
                                        <th className="p-1 pt-2">
                                            <h4>Cookies</h4>
                                        </th>
                                        <th className="p-1 pt-2">
                                            <h4>Cookies utilisés</h4>
                                        </th>
                                        <th className="p-1 pt-2">
                                            <h4>Durées de vie</h4>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="p-1">
                                            Internal Cookie
                                        </td>
                                        <td className="p-1">
                                            Ce cookie est défini par la solution de conformité aux cookies de OneTrust. Il stocke des informations sur les catégories de cookies utilisées par le site et indique si les visiteurs ont donné ou retiré leur consentement à l'utilisation de chaque catégorie. Cela permet aux propriétaires de sites d'empêcher les cookies de chaque catégorie d'être installés dans le navigateur de l'utilisateur, lorsque celui-ci n'a pas donné son consentement. Le cookie a une durée de vie normale d'un an, de sorte que les visiteurs qui reviennent sur le site verront leurs préférences mémorisées. Il ne contient aucune information permettant d'identifier le visiteur du site
                                        </td>
                                        <td className="p-1">
                                            OptanonConsent
                                        </td>
                                        <td className="p-1">
                                            179 Days
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-1">
                                            Internal Cookie
                                        </td>
                                        <td className="p-1">
                                            Ce cookie est placé par les sites web qui utilisent certaines versions de la solution de conformité à la loi sur les cookies de OneTrust.  Il est placé après que les visiteurs ont vu un avis d'information sur les cookies et, dans certains cas, seulement lorsqu'ils ferment activement l'avis.  Il permet au site web de ne pas montrer le message plus d'une fois à un utilisateur.  Le cookie ne contient aucune information personnelle.
                                        </td>
                                        <td className="p-1">
                                            OptanonAlertBoxClosed
                                        </td>
                                        <td className="p-1">
                                            179 Days
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-1">

                                        </td>
                                        <td className="p-1">
                                            Ce cookie est placé par les sites web qui utilisent certaines versions de la solution de conformité à la loi sur les cookies de OneTrust.  Il est placé après que les visiteurs ont vu un avis d'information sur les cookies et, dans certains cas, seulement lorsqu'ils ferment activement l'avis.  Il permet au site web de ne pas montrer le message plus d'une fois à un utilisateur.  Le cookie ne contient aucune information personnelle.
                                        </td>
                                        <td className="p-1">

                                        </td>
                                        <td className="p-1">

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-1">
                                            Internal
                                        </td>
                                        <td className="p-1">
                                            Ce cookie est défini par les sites web fonctionnant sur la plateforme Windows Azure. Il est utilisé pour l'équilibrage de la charge afin de s'assurer que les demandes de pages des visiteurs sont acheminées vers le même serveur lors de chaque session de navigation.
                                        </td>
                                        <td className="p-1">
                                            ARRAffinity
                                        </td>
                                        <td className="p-1">
                                            Session Cookie
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="border border-info">
                                <thead className="bg-info text-white">
                                    <tr>
                                        <th className="p-1 pt-2">
                                            <h3>COOKIES DE MESURE D’AUDIENCE</h3>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="p-1 pt-2">
                                            <p>Ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic, afin de mesurer et d’améliorer les performances de notre site internet. Ils nous aident également à identifier les pages les plus / moins visitées et d’évaluer comment les visiteurs naviguent sur le site internet. Toutes les informations collectées par ces cookies sont agrégées. Si vous n'acceptez pas ces cookies, AXA Partners ne sera pas informé de votre visite sur notre site.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="border border-info mb-4">
                                <thead className="bg-info text-white">
                                    <tr>
                                        <th className="p-1 pt-2">
                                            <h4>Sous-groupe de cookies</h4>
                                        </th>
                                        <th className="p-1 pt-2">
                                            <h4>Cookies</h4>
                                        </th>
                                        <th className="p-1 pt-2">
                                            <h4>Cookies utilisés</h4>
                                        </th>
                                        <th className="p-1 pt-2">
                                            <h4>Durées de vie</h4>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="p-1">
                                            Internal Cookie
                                        </td>
                                        <td className="p-1">
                                            Le principal cookie utilisé par Google Analytics permet au service de distinguer les visiteurs les uns des autres.
                                        </td>
                                        <td className="p-1">
                                            _ga_xxxxxxx
                                        </td>
                                        <td className="p-1">
                                            365 days
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-1">
                                            Internal Cookie
                                        </td>
                                        <td className="p-1">
                                            Ce nom de cookie est associé à Google Universal Analytics - qui est une mise à jour importante du service d'analyse de Google le plus couramment utilisé. Ce cookie est utilisé pour distinguer les utilisateurs uniques en attribuant un numéro généré de manière aléatoire comme identifiant client. Il est inclus dans chaque requête de page d'un site et utilisé pour calculer les données relatives aux visiteurs, aux sessions et aux campagnes pour les rapports d'analyse du site.  Par défaut, il est paramétré pour expirer au bout de deux ans, mais les propriétaires de sites web peuvent personnaliser cette durée.
                                        </td>
                                        <td className="p-1">
                                            _ga
                                        </td>
                                        <td className="p-1">
                                            365 Days
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-1">

                                        </td>
                                        <td className="p-1">
                                            Ce nom de cookie est associé à Google Universal Analytics - qui est une mise à jour importante du service d'analyse de Google le plus couramment utilisé. Ce cookie est utilisé pour distinguer les utilisateurs uniques en attribuant un numéro généré de manière aléatoire comme identifiant client. Il est inclus dans chaque requête de page d'un site et utilisé pour calculer les données relatives aux visiteurs, aux sessions et aux campagnes pour les rapports d'analyse du site.  Par défaut, il est paramétré pour expirer au bout de deux ans, mais les propriétaires de sites web peuvent personnaliser cette durée.
                                        </td>
                                        <td className="p-1">

                                        </td>
                                        <td className="p-1">

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-1">
                                            Internal
                                        </td>
                                        <td className="p-1">
                                            Ce cookie est défini par les sites web fonctionnant sur la plateforme Windows Azure. Il est utilisé pour l'équilibrage de la charge afin de s'assurer que les demandes de pages des visiteurs sont acheminées vers le même serveur lors de chaque session de navigation.
                                        </td>
                                        <td className="p-1">
                                            ARRAffinitySameSite
                                        </td>
                                        <td className="p-1">
                                            Session Cookie
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div>
                    <h3>COMMENT ACCEPTER OU REFUSER DE CONSENTIR AU DÉPÔT DE COOKIES ?</h3>
                    <p>Un bandeau apparaît la première fois que vous vous connectez à HomeCare.be. Ce bandeau vous permet :</p>
                    <ul>
                        <li>
                            de 
                            <b> consentir </b>
                            au dépôt de 
                            <b> tous les cookies </b>
                            optionnels qui ne sont pas strictement nécessaires au bon fonctionnement du site, ou 
                        </li>
                        <li>
                            de
                            <b> refuser </b>
                            le dépôt de
                            <b> tous les cookies </b>
                            optionnels, qui ne sont pas strictement nécessaires au bon fonctionnement du site, toutes catégories confondues, ou 
                        </li>
                        <li>
                            d’accéder à une interface de 
                            <b> personnalisation </b>
                            de vos 
                            <b> choix de consentement </b>
                            (cela signifie que vous pouvez choisir de consentir ou non au dépôt de chaque cookie qui n'est pas indispensable au fonctionnement normal du site).
                        </li>
                    </ul>
                    <p>
                        Nous conservons votre choix quel qu'il soit pendant une période de [6] mois. A l'issue de cette période, nous vous demanderons de renouveler votre choix. Vous pouvez modifier vos préférences à tout moment en cliquant sur <Link to="/cookiesettings" className="axa-text-underline">Centre de Préférences Cookies</Link>
                        .
                    </p>
                </div>
                <div>
                    <h3>LE DÉPÔT DE COOKIES EST-IL OBLIGATOIRE ?</h3>
                    <p>Le caractère nécessaire ou optionnel de chaque catégorie de cookies vous est indiqué au moment du recueil de votre consentement à leur dépôt dans la bannière cookies lors de votre première visite, ainsi que dans le Centre de Préférences Cookies.</p>
                    <p>
                        Le <Link to="/cookiesettings" className="axa-text-underline">Centre de Préférences Cookies </Link>
                        est accessible à tout moment en bas de page du site ainsi que via le bouton « Personnaliser mes choix » dans la bannière cookies.
                    </p>
                    <p>En cas de refus de certaines catégories de cookies optionnels, il se peut que certaines fonctionnalités, pages ou espaces du site internet ne soient pas accessibles ; ce dont nous ne saurions être responsables.</p>
                </div>
            </div>
        </div>
    );
};

export default CookiePolicyFR;