
const PrivacyPolicyNL = () => {
    return (
        <div align="left" >
            <h1 className="text-info">HOMECARE</h1>
            <h1 className="text-info">Privacybeleid</h1>
            <p>Dit document vormt het beleid inzake de verwerking van persoonsgegevens dat van toepassing is op het gebruik van de Web Applicatie.</p>
            <p>Dit document kan in de toekomst worden aangepast. We nodigen u uit om het regelmatig te raadplegen.</p>
            <div className="ms-5">
                <h2 className="text-secondary">WETTELIJKE KENNISGEVING - GEGEVENSBEHEERDER</h2>
                <br />
                <span>SA INTERPARTNER BIJSTAND</span>
                <br />
                <span>Lid van de AXA ASSISTANCE-groep</span>
                <br />
                <span>Maatschappelijke zetel: Regentenlaan 7, 1000 Brussel,  Ingeschreven bij de Kruispuntbank van Ondernemingen onder nr. 0415.591.055, RPM Brussel Verzekeringsmaatschappij erkend onder code 0487</span>
                <br />
                <br />
                <p>(hierna "AXA ASSISTANCE" genoemd).</p>
                <h2 className="text-secondary">DEFINITIES</h2>
                <p>De Uitgever vestigt de aandacht van de Gebruikers van zijn site op de volgende punten:</p>
                <ul>
                    <li>"Webapplicatie" verwijst naar HOMECARE.</li>
                    <li>"AXA ASSISTANCE" verwijst naar SA INTER PARTNER ASSISTANCE, zoals hierboven beschreven;</li>
                    <li>"Gegevens" verwijst naar persoonlijke gegevens;</li>
                    <li>"Uitgever" betekent AXA ASSISTANCE;</li>
                    <li>"Wetgeving" betekent de Belgische, Europese en internationale wet- en regelgeving inzake de bescherming van de persoonlijke levenssfeer en de verwerking van persoonsgegevens, in het bijzonder de GDPR en de Belgische wetten en besluiten ter aanvulling van deze Verordening;</li>
                    <li>"Gegevensbeheerder" betekent de gegevensbeheerder zoals gedefinieerd door de RGPD; de gegevensbeheerder is AXA ASSISTANCE ;</li>
                    <li>"GDPR": Verordening (EU) 2016/679 van het Europees Parlement en de Raad van 27 april 2016 betreffende de bescherming van natuurlijke personen in verband met de verwerking van persoonsgegevens en betreffende het vrije verkeer van die gegevens en tot intrekking van Richtlijn 95/46/EG ;</li>
                    <li>"Onderaannemer" betekent de onderaannemer, zoals gedefinieerd door de GDPR;</li>
                    <li>"Gebruiker" betekent iedere persoon die gebruik maakt van de Mobiele Applicatie;</li>
                    <li>"U" of "Begunstigde" verwijst naar de begunstigde van Bijstandsdiensten (zoals gedefinieerd in de contractuele voorwaarden van uw contract of polis), die toegang heeft tot de Mobiele Applicatie, deze raadpleegt en gebruikt voor de implementatie van Bijstandsdiensten.</li>
                </ul>
                <h2 className="text-secondary">DOEL VAN DE VERWERKING</h2>
                <p>De persoonsgegevens die u via de Webapplicatie aan AXA ASSISTANCE  verstrekt, kunnen door AXA ASSISTANCE voor de volgende doeleinden worden verwerkt:</p>
                <ul>
                    <li>Het leveren van hulpdiensten,</li>
                    <li>De ontwikkeling, het beheer, de aanpassing en het bijwerken van de Mobiele Applicatie en/of de functionaliteiten ervan,</li>
                    <li>analyse (met inbegrip van technische of statistische analyse) van het gebruik van de Mobiele Applicatie en/of de functionaliteiten en verbindingen met de Applicatie,</li>
                    <li>beheer van het personeelsdossier,</li>
                    <li>klantenservice,</li>
                    <li>fraude op te sporen, te voorkomen en te bestrijden,</li>
                    <li>de strijd tegen het witwassen van geld en de financiering van terrorisme,</li>
                    <li>portfoliomonitoring,</li>
                    <li>naleving van de toepasselijke wetgeving (belastingen, sociale zekerheid, boekhouding, enz.) bijstandsdiensten in het kader van uw contract of verzekeringspolis, • statistische onderzoeken.</li>
                </ul>
                <h2 className="text-secondary">GEGEVENS VERWERKT VIA DE WEBTOEPASSING</h2>
                <p>AXA ASSISTANCE kan via de Web Applicatie de volgende Persoonlijke Gegevens verzamelen: uw voor- en achternaam, uw telefoonnummer, uw adres, uw e-mailadres, het type van de gevraagde dienst, uw IP-adres, uw taal, uw verbindingsgegevens, evenals gegevens met betrekking tot gebeurtenissen die verband houden met de apparaten die u gebruikt (systeemactiviteit, datum en tijd van verbinding, hardwareparameters, technische problemen, enz.)</p>
                <h2 className="text-secondary">RECHTSGRONDSLAG VOOR VERWERKING</h2>
                <p>AXA ASSISTANCE verwerkt uw Persoonsgegevens op de volgende rechtsgronden:</p>
                <ul>
                    <li>De uitvoering van uw verzekeringscontract of polis en de algemene gebruiksvoorwaarden van de Mobiele Applicatie: om u hulpdiensten te verlenen, om uw contract of polis te beheren of uit te voeren en om de Mobiele Applicatie te beheren,</li>
                    <li>Het nastreven van de rechtmatige belangen van AXA ASSISTANCE: om de Mobiele Applicatie te beheren, om technische of statistische analyses uit te voeren, om de relatie met de verzekerde, de Begunstigde of de Gebruiker te beheren, om fraude op te sporen, te voorkomen en te bestrijden, om haar portefeuille te bewaken;</li>
                    <li>Voldoen aan wettelijke en reglementaire verplichtingen: met het oog op de naleving en toepassing van de toepasselijke wetgeving (belastingen, sociale zekerheid, boekhouding, enz.) op de bijstandsdiensten die worden geleverd in het kader van uw contract of verzekeringspolis.</li>
                </ul>
                <p>De Persoonsgegevens die AXA ASSISTANCE u vraagt te verstrekken zijn noodzakelijk voor een goed gebruik van de Mobiele Applicatie en gerelateerde assistentiediensten. Het niet verstrekken van deze gegevens kan het onmogelijk of moeilijk maken om de Mobiele Applicatie, de functionaliteiten ervan en/of de gerelateerde assistentiediensten af te sluiten of er goed gebruik van te maken.</p>
                <h2 className="text-secondary">OVERDRACHT VAN GEGEVENS</h2>
                <p>Voor zover de mededeling van persoonsgegevens noodzakelijk is om de hierboven opgesomde doeleinden te bereiken, kan AXA ASSISTANCE uw persoonsgegevens doorgeven aan andere personen, hetzij andere bedrijven van de AXA Groep of derden van andere bedrijven van de AXA Groep, aan bedrijven en/of personen die met hen in contact staan (hulpverleners, advocaten, deskundigen, medisch adviseurs, herverzekeraars, medeverzekeraars, verzekeringstussenpersonen, dienstverleners, andere verzekeringsmaatschappijen, vertegenwoordigers, acceptatiekantoren, schaderegelingskantoren, Datassur). De andere bedrijven van de AXA Groep, de bedrijven en/of personen die met hen in contact staan en aan wie Persoonsgegevens worden meegedeeld, kunnen zowel binnen als buiten de Europese Unie gevestigd zijn.</p>
                <p>In het geval van overdracht van persoonsgegevens aan derden buiten de Europese Unie, leeft AXA ASSISTANCE de geldende wettelijke en reglementaire bepalingen inzake dergelijke overdrachten na. In het bijzonder waarborgt zij een adequaat beschermingsniveau voor persoonsgegevens die op deze wijze worden doorgegeven op basis van alternatieve mechanismen die door de Europese Commissie zijn ingesteld, zoals standaard contractuele clausules, of de bindende bedrijfsregels   van de AXA Groep in geval van doorgifte binnen de groep (Mon. B. 6/10/2014, p. 78547).</p>
                <p>Deze gegevens kunnen ook worden meegedeeld aan de toezichthoudende autoriteiten, de bevoegde overheidsdiensten en elke andere openbare of particuliere organisatie waarmee AXA ASSISTANCE persoonsgegevens moet uitwisselen in overeenstemming met de toepasselijke wetgeving.</p>
                <h2 className="text-secondary">BEWAREN VAN GEGEVENS</h2>
                <p>AXA ASSISTANCE bewaart de Persoonsgegevens die via de mobiele Applicatie zijn verzameld voor de gehele duur van het gebruik van deze Applicatie en de levering van de bijbehorende hulpverleningsdiensten. Deze periode kan worden verlengd met de wettelijke bewaartermijn (met name in boekhoudkundige of fiscale aangelegenheden) of met de verjaringstermijn om verzoeken of enig verhaal te kunnen behandelen die kunnen worden geïnitieerd na het einde van het gebruik van de Mobiele Applicatie of na het afsluiten van het schadedossier.</p>
                <p>AXA ASSISTANCE verbindt zich ertoe uw Persoonlijke Gegevens veilig, vertrouwelijk en nauwkeurig te bewaren gedurende de periode van gebruik die hierin is toegestaan. Aan het einde van de bewaarperiode worden uw Persoonlijke Gegevens anoniem gemaakt of vernietigd.</p>
                <h2 className="text-secondary">VERTROUWELIJKHEID</h2>
                <p>AXA ASSISTANCE heeft alle nodige maatregelen genomen om de vertrouwelijkheid van de persoonsgegevens te bewaren en om deze te beschermen tegen ongeoorloofde toegang, misbruik, wijziging of verwijdering.</p>
                <p>Daartoe volgt AXA ASSISTANCE de normen voor beveiliging en continuïteit van de dienstverlening en evalueert zij regelmatig het beveiligingsniveau van haar processen, systemen en toepassingen en die van haar partners.</p>
                <h2 className="text-secondary">RECHTEN VAN DE BETROKKEN PERSOON</h2>
                <p>Je hebt het recht om:</p>
                <ul>
                    <li>om van AXA ASSISTANCE de bevestiging te krijgen dat Persoonsgegevens die op u betrekking hebben al dan niet worden verwerkt en, indien dit wel het geval is, om toegang te krijgen tot deze gegevens;</li>
                    <li>om uw Persoonsgegevens te laten rectificeren en, indien nodig, aan te vullen als ze onjuist of onvolledig zijn;</li>
                    <li>om uw Persoonsgegevens in bepaalde omstandigheden te laten verwijderen;</li>
                    <li>om de verwerking van uw Persoonsgegevens in bepaalde omstandigheden te beperken;</li>
                    <li>om bezwaar te maken, om redenen die verband houden met uw bijzondere situatie, tegen de verwerking van uw Persoonsgegevens, op grond van de gerechtvaardigde belangen van AXA ASSISTANCE. AXA ASSISTANCE zal de Persoonsgegevens niet langer verwerken, tenzij zij kan aantonen dat er dwingende gerechtvaardigde gronden zijn voor de verwerking die zwaarder wegen dan de belangen en de rechten en vrijheden van de betrokkene;</li>
                    <li>bezwaar te maken tegen de verwerking van uw Persoonsgegevens voor direct marketingdoeleinden, inclusief profilering voor direct marketingdoeleinden;</li>
                    <li>niet het onderwerp te zijn van een besluit dat uitsluitend is gebaseerd op geautomatiseerde verwerking, met inbegrip van profilering, die rechtsgevolgen heeft die hem betreffen of die hem in aanmerkelijke mate treffen; indien deze geautomatiseerde verwerking echter noodzakelijk is voor het sluiten of uitvoeren van een contract, hebt u het recht om menselijke tussenkomst van AXA ASSISTANCE te verkrijgen, uw standpunt kenbaar te maken en het besluit van AXA ASSISTANCE te betwisten;</li>
                    <li>om uw Persoonsgegevens te ontvangen die aan AXA ASSISTANCE zijn verstrekt, in een gestructureerd, algemeen gebruikt en machineleesbaar formaat; om deze gegevens door te geven aan een andere gegevensbeheerder, wanneer (i) de verwerking van uw Persoonsgegevens is gebaseerd op haar toestemming of voor de behoeften van de uitvoering van een contract en (ii) de verwerking wordt uitgevoerd met behulp van geautomatiseerde processen; en om te verkrijgen dat uw Persoonsgegevens rechtstreeks door AXA ASSISTANCE worden doorgegeven aan een gegevensbeheerder, wanneer dit technisch mogelijk is;</li>
                    <li>om uw toestemming te allen tijde in te trekken, zonder afbreuk te doen aan de verwerking die rechtmatig is uitgevoerd voorafgaand aan de intrekking van de toestemming, wanneer de verwerking van uw Persoonsgegevens is gebaseerd op uw toestemming.</li>
                </ul>
                <h2 className="text-secondary">CONTACT OPNEMEN MET AXA ASSISTANCE</h2>
                <p>U kunt contact opnemen met AXA ASSISTANCE om uw rechten uit te oefenen op de volgende adressen. Uw verzoek moet vergezeld gaan van een fotokopie van beide zijden van uw identiteitskaart (of een ander identiteitsbewijs):</p>
                <ul>
                    <li>
                        <span>per post:</span>
                        <br />
                        <span>Functionaris voor gegevensbescherming</span>
                        <br />
                        <span>Inter Partner Assistance S.A.</span>
                        <br />
                        <span>Boulevard du Régent 7</span>
                        <br />
                        <span>1000 Brussel</span>
                    </li>
                    <li>
                        <span>per e-mail:</span>
                        <br />
                        <span>dpo.BNL@axa-assistance.com</span>
                    </li>
                </ul>
                <p>AXA ASSISTANCE zal de verzoeken behandelen binnen de wettelijk vastgestelde termijnen. Tenzij het verzoek duidelijk ongegrond of buitensporig is, zal voor de behandeling van deze verzoeken geen betaling worden gevraagd.</p>
                <h2 className="text-secondary">EEN KLACHT INDIENEN</h2>
                <p>Als u van mening bent dat AXA ASSISTANCE de relevante regelgeving niet naleeft, wordt u uitgenodigd om AXA ASSISTANCE met voorrang te raadplegen.</p>
                <p>U kunt ook een klacht indienen bij de Autoriteit Persoonsgegevens op het volgende adres</p>
                <span>GEGEVENSBESCHERMINGSAUTORITEIT</span>
                <br />
                <span>Rue de la Presse, 35</span>
                <br />
                <span>1000 Brussel</span>
                <br />
                <span>Tel. + 32 2 274 48 00 Fax + 32 2 274 48 35</span>
                <br />
                <span>commission@privacycommission.be</span>
                <p>Je kunt ook een klacht indienen bij de rechtbank van eerste aanleg. </p>
            </div>
        </div>
    );
};

export default PrivacyPolicyNL;