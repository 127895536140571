import { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import translate from '../common/Dictionary';
import { UserContext } from '../../auth/auth0-provider-with-history';
import ProjectSteps from './components/ProjectSteps';

//images
import iconExpert from "./../../img/illustration-expert.svg"
import iconHouse from "./../../img/illustration-house.svg"
import iconCheck from "./../../img/icon_fill_check.svg"


const NewProject = () => {
    const [state, setState] = useState({
        successShown: false,
    })
    const { userData, setUserData } = useContext(UserContext);
    let { subcatid, serviceid } = useParams();


    const getServices = async () => {
        const serviceResponse = await axios.get("api/servicecategory/getallactive");
        var newState = {
            services: serviceResponse.data.data
        }
        setState(newState);
    }

    const showSuccessMessage = () => {
        setState({
            ...state,
            successShown: true
        })
    }

    useEffect(() => {
        getServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div >
            <div className="container">
                <div className="mt-5 mb-3">
                    <Link className="btn text-secondary ps-0" to="/" data-element-label="back home">
                        <FontAwesomeIcon className="me-1" icon={faArrowLeft} />
                        {translate("newProjectBackToHome", userData.language)}
                    </Link>
                </div>
                <ProjectSteps subcatId={subcatid} serviceId={serviceid} showSuccessMessage={showSuccessMessage} />
            </div>
            <div style={{ display: (state.successShown ? "" : "none") }}>
                <div className="axa-center-text overflow-clip" >
                    <div className="container ps-5 pe-5 pt-9">
                        <label className="title mb-4">{translate("newProjectAfterSuccessStepsTitle", userData?.language)}</label>
                        <div className="title-sub-text pb-8">
                            {translate("newProjectAfterSuccessStepsText", userData?.language)}
                        </div>
                        <div className="row justify-content-center g-0 pb-6">
                            <div className="col-lg-3 col-sm-12 mb-5">
                                <div className="axa-step-lines blue axa-step-first-child mb-3">
                                    <div className="axa-step blue">
                                        <div className="axa-step-circle-content">1</div>
                                    </div>
                                </div>
                                <div className="axa-step-title-text-content">
                                    <div className="axa-step-title">{translate("newProjectAfterSuccessStep1Title", userData?.language)}</div>
                                    {translate("newProjectAfterSuccessStep1Text", userData?.language)}
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-12 mb-5">
                                <div className="axa-step-lines blue mb-3">
                                    <div className="axa-step blue">
                                        <div className="axa-step-circle-content">2</div>
                                    </div>
                                </div>
                                <div className="axa-step-title-text-content">
                                    <div className="axa-step-title">{translate("newProjectAfterSuccessStep2Title", userData?.language)}</div>
                                    {translate("newProjectAfterSuccessStep2Text", userData?.language)}
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-12 mb-5">
                                <div className="axa-step-lines blue axa-step-last-child mb-3">
                                    <div className="axa-step blue">
                                        <div className="axa-step-circle-content">3</div>
                                    </div>
                                </div>
                                <div className="axa-step-title-text-content">
                                    <div className="axa-step-title">{translate("newProjectAfterSuccessStep3Title", userData?.language)}</div>
                                    {translate("newProjectAfterSuccessStep3Text", userData?.language)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 axa-bg-success1">
                    <div className="col-12" align="center">
                        <div className="axa-header-success1">{translate("newProjectAfterSuccessInsuranceNeedsHeader", userData.language)}</div>
                        <div className="axa-text-success1">{translate("newProjectAfterSuccessInsuranceNeedsText", userData.language)}</div>
                    </div>
                </div>
                <div className="row axa-endrow-success">
                    <div className="col-md-6 col-sm-12 axa-bg-success2">
                        <img src={iconHouse} alt="house home" />
                        <div className="axa-header-success2 mt-5">{translate("newProjectAfterSuccessHouseHeader", userData.language)}</div>
                        <div className="axa-text-success2 mt-5">{translate("newProjectAfterSuccessHouseText", userData.language)}</div>
                        <div className="mt-5">
                            <img className="axa-check-20" src={iconCheck} alt="check" />
                            <span className="ms-1">{translate("newProjectAfterSuccessHouseList1", userData.language)}</span>
                        </div>
                        <div className="mt-5">
                            <img className="axa-check-20" src={iconCheck} alt="check" />
                            <span className="ms-1">{translate("newProjectAfterSuccessHouseList2", userData.language)}</span>
                        </div>
                        <div className="mt-5">
                            <img className="axa-check-20" src={iconCheck} alt="check" />
                            <span className="ms-1">{translate("newProjectAfterSuccessHouseList3", userData.language)}</span>
                        </div>
                        <div className="mt-5">
                            <img className="axa-check-20" src={iconCheck} alt="check" />
                            <span className="ms-1">{translate("newProjectAfterSuccessHouseList4", userData.language)}</span>
                        </div>
                        <div className="d-flex flex-column flex-md-column flex-lg-row mt-5">
                            <button className="btn btn-primary flex-sm-fill" data-element-label="contact broker">{translate("newProjectAfterSuccessHouseContanctBrokerBtn", userData.language)}</button>
                            <button className="btn flex-sm-fill" data-element-label="more info broker">
                                {translate("newProjectAfterSuccessHouseMoreInfoBtn", userData.language)}
                                <FontAwesomeIcon className="ms-1" icon={faArrowRight} />
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 axa-bg-success3">
                        <img src={iconExpert} alt="house home" />
                        <div className="axa-header-success2 mt-5">{translate("newProjectAfterSuccessExpertHeader", userData.language)}</div>
                        <div className="axa-text-success2 mt-5">{translate("newProjectAfterSuccessExpertText", userData.language)}</div>
                        <div className="mt-5">
                            <img className="axa-check-20" src={iconCheck} alt="check" />
                            <span className="ms-1">{translate("newProjectAfterSuccessExpertList1", userData.language)}</span>
                        </div>
                        <div className="mt-5">
                            <img className="axa-check-20" src={iconCheck} alt="check" />
                            <span className="ms-1">{translate("newProjectAfterSuccessExpertList2", userData.language)}</span>
                        </div>
                        <div className="mt-5">
                            <img className="axa-check-20" src={iconCheck} alt="check" />
                            <span className="ms-1">{translate("newProjectAfterSuccessExpertList3", userData.language)}</span>
                        </div>
                        <div className="mt-5">
                            <img className="axa-check-20" src={iconCheck} alt="check" />
                            <span className="ms-1">{translate("newProjectAfterSuccessExpertList4", userData.language)}</span>
                        </div>
                        <div className="mt-5">
                            <img className="axa-check-20" src={iconCheck} alt="check" />
                            <span className="ms-1">{translate("newProjectAfterSuccessExpertList5", userData.language)}</span>
                        </div>
                        <div className="d-flex flex-column flex-md-column flex-lg-row mt-5">
                            <button className="btn btn-secondary flex-sm-fill" data-element-label="price simulation">{translate("newProjectAfterSuccessExpertCalculateBtn", userData.language)}</button>
                            <button className="btn flex-sm-fill" data-element-label="more info price simulation">
                                {translate("newProjectAfterSuccessExpertMoreInfoBtn", userData.language)}
                                <FontAwesomeIcon className="ms-1" icon={faArrowRight} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewProject;