import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import moment from 'moment';

import DataTable from 'react-data-table-component';
import customStyles from '../common/CustomDataTableStyle';
import handleResponse from '../common/ResponseHandler'
import TableLoadingPlaceholder from '../common/TableLoadingPlaceholder'

import { useAuth0 } from '@auth0/auth0-react';


const HomeProjectListNetwork = () => {
    const [state, setState] = useState({
        homeProjectColumns: [
            {
                name: "Project ID",
                selector: row => (
                    <>
                        <a className="btn btn-link p-2" href={"network/homeprojectdetails/" + row.id}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} className="me-1" />
                        </a>
                        #{row.id}
                    </>
                ),
                
            },
            {
                name: "Create Date",
                selector: row => (
                    <>
                        {moment(row.createDate).format('DD.MM.YYYY HH:mm')}
                    </>
                ),
                wrap: true
            },
            {
                name: "Provider",
                selector: row => <>{row.provider?.name}</>,
                wrap: true
            },
            {
                name: "Customer Name",
                selector: row => <>{row.customerName} {row.customerLastName}</>,
                wrap: true
            },
            {
                name: "Customer Postal Code",
                selector: row => <>{row.postalArea?.postCode}</>,
                wrap: true
            },
            {
                name: "Service",
                selector: row => <>{row.service?.name}</>,
                wrap: true
            },
            {
                name: "Status",
                selector: row => <>{row.projectStatus?.name}</>,
                wrap: true
            },
            {
                name: "Invoice",
                selector: row => <>{row.providerInvoiceFileName? "Yes" : "No"}</>,
                wrap: true
            },
        ],
        conditionalRowStyles: [
            {
                when: row => (row.projectStatus.id === 1 || row.projectStatus.id === 3 || row.projectStatus.id === 8) && !row.provider,
                style: {
                    backgroundColor: 'yellow',
                },
            },
        ],
        tableLoading: true,
        homeProjectData: [],
        providerFilter: 0,
        noInput: true,
        searchFilter: "",

    });
    let { providerId } = useParams();
    const { getAccessTokenSilently } = useAuth0();

    const handleKeyDownSearch = evt => {
        if (evt.key === 'Enter') {
            getHomeProjects();
        }
    };

    const handleTextInputChange = evt => {
        setState({ ...state, searchFilter: evt.target.value });
    };

    const filterSearchArray = searchData => {
        if (state.searchFilter) {
            searchData = searchData.filter(item => {
                return (
                    (item.customerName.toUpperCase() + " " + item.customerLastName.toUpperCase()).includes(state.searchFilter.toUpperCase())
                );
            });
        }
        return searchData;
    };

    const getHomeProjects = async provider => {
        try {
            if (state.noInput) {
                var providerFilter = (state.providerFilter === 0 ? (providerId ? providerId : 0) : state.providerFilter);
            }
            const token = await getAccessTokenSilently();
            var response;
            if (providerFilter !== 0) {
                response = await axios.get(`api/homeproject/getbyproviderid?providerid=${providerFilter}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            } else {
                response = await axios.get(`api/homeproject/getall`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            }
            handleResponse(response, "Project retrieval failed.", true)
            if (!response.data.isError) {
                let filteredData = filterSearchArray(response.data.data);
                setState({ ...state, tableLoading: false, homeProjectData: filteredData })
            }
        } catch (error) {
            toast.error("Project retrieval failed.");
        }
    };

    useEffect(() => {
        getHomeProjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-subtitle text-secondary">Project List</h2>
                            <hr className="hr-fade-20 m-0 mb-1" />
                            <div className="row">
                                <div className="col-lg-6 col-sm-12 mb-1">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="searchFilter"
                                            placeholder="Customer Name"
                                            onChange={handleTextInputChange}
                                            onKeyDown={handleKeyDownSearch}
                                        />
                                        <button className="btn btn-outline-primary" type="button" onClick={getHomeProjects}>
                                            <FontAwesomeIcon icon={faSearch} />
                                            <span className="ms-1">Search</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <DataTable
                                        customStyles={customStyles}
                                        data={state.homeProjectData}
                                        columns={state.homeProjectColumns}
                                        progressPending={state.tableLoading}
                                        progressComponent={<TableLoadingPlaceholder columnCount={6} rowCount={8} />}
                                        pagination
                                        striped
                                        conditionalRowStyles={state.conditionalRowStyles}
                                    />
                                    <div className="col-12 mb-2">
                                        <span className="bg-yellow p-1" style={{ borderRadius: "20px" }}>No Provider Assigned</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeProjectListNetwork;