import React, { useEffect, useState } from 'react';

const TableLoadingPlaceholder = (props) => {

    const [state, setState] = useState({
        header: [],
        body: []
    });

    useEffect(() => {
        let newHeader = [];
        let newBody = [];

        let colCount = props.columnCount ? props.columnCount : 3;
        let rowCount = props.rowCount ? props.rowCount : 3;

        for (let i = 0; i < colCount; i++) {
            newHeader.push("Loading Header " + i);
        }
        for (let i = 0; i < rowCount; i++) {
            let row = {}
            for (let k = 0; k < colCount; k++) {
                row["col" + k] = "Loading Data Row " + i + " Col " + k;
            }
            newBody.push(row);
        }
        setState({
            header: newHeader,
            body: newBody
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <table className="table table-striped loading-skeleton">
            <thead>
                <tr>
                    {state.header.map((column) => (
                        <th key={column} >
                            <label>{column}</label>
                        </th>
                    )) }
                </tr>
            </thead>
            <tbody>
                {state.body.map((row, index) => (
                    <tr key={"row-" + index}>
                        {Object.keys(row).map((column,colIndex) => (
                            <td key={"col-" + colIndex}>
                                <label>{row[column]}</label>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TableLoadingPlaceholder;

