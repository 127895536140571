import React from 'react';
import NavMenu from './NavMenu';
import Footer from './Footer'

const Layout = (props) => {
    return (
        <>
            <div className="axa-content-wrap">
                <NavMenu />
                {props.children}
            </div>
            <Footer />
        </>
    );
};

export default Layout;