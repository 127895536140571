import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Auth0LogoutButton from '../login/Auth0LogoutButton';
import LanguageSelector from './LanguageSelector';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from '../../auth/auth0-provider-with-history';

const NavMenu = (props) => {
    const [state, setState] = useState({});
    const { isAuthenticated } = useAuth0();
    const { userData, setUserData } = useContext(UserContext);
    useEffect(() => {
        if (localStorage.getItem("loginType") === "network") {
            setState({
                navItems: (
                    <>
                        <Link className="btn btn-light me-2" to="/network/providerlist">
                            <label className="pt-3">Providers</label>
                        </Link>
                        <a className="btn btn-light me-2" href="/network/homeprojectlist">
                            <label className="pt-3">Projects</label>
                        </a>
                    </>
                )
            });
        }
        else if (localStorage.getItem("loginType") === "provider") {
            setState({
                navItems: (
                    <>
                        <Link className="btn btn-light me-2" to="/provider/homeprojectlist">
                            <label className="pt-3">Projects</label>
                        </Link>
                    </>
                )
            });
        }
        else {
            setState({ navItems: "" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);

    return (
        <header>
            <div className="ax-example p-md-0 navbar-header border-axa-header">
                <nav className="container navbar navbar-light px-1">
                    <Link className="navbar-brand" to="/" aria-label="HomeCare" ></Link>
                    <div className="d-flex" role="search">
                        {state.navItems}
                        {isAuthenticated ? <Auth0LogoutButton /> : ""}
                        <LanguageSelector languages={["NL", "FR"]} />
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default NavMenu;
