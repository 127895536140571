import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { UserContext } from '../../../auth/auth0-provider-with-history';
import translate from "../../common/Dictionary";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import BootstrapModal from '../../common/BootstrapModal'
import { useDropzone } from 'react-dropzone'

//Images
import chevronRight from "./../../../img/chevron_right.svg"
import checkFill from "./../../../img/icon_fill_check.svg"
import warningFill from "./../../../img/icon_fill_warning.svg"
import errorFill from "./../../../img/icon_fill_error.svg"
import uploadIcon from "./../../../img/icon_upload.svg"
import FileIcon from "./../../../img/icon_file.svg"
import DeleteIcon from "./../../../img/icon_delete.svg"
import InfoIcon from "./../../../img/icon_info.svg"
import PrivacyPolicyNL from './PrivacyPolicyNL';
import PrivacyPolicyFR from './PrivacyPolicyFR';

const ProjectSteps = (props) => {
    const [state, setState] = useState({
        subCatData: {},
        currentActiveTabIndex: 0,
        activeTab: 1,
        showOffCanvas: false,
        serviceCategories: [],
        selectedCatId: 0,
        selectedSubcatId: 0,
        selectedServiceId: 0,
        checkPostalCode: "",
        availablePostalAreas: [],
        checkProvider: {},
        checkProviderResult: "no-result",
        areYouSureWarningModalShown: false,
        serviceUpdateRequest: {
            updatedField: "",
            newValue: 0
        },
        customerDescription: "",
        customerUploadedFiles: [],
        customerLastName: "",
        customerFirstName: "",
        customerGsmNumber: "+32",
        customerEmail: "",
        customerAddressStreetAndHouse: "",
        customerAddressBusStation: "",
        customerAddressCity: "",
        customerPrivacyAccepted: false,
        customerPrivacyMarketing: false,
        errorGsm: false,
        errorLastName: false,
        errorFirstName: false,
        errorEmail: false,
        errorPrivacy: false,
        errorCustomerIsAxaCustomer: false,
        errorCustomerAddressStreetAndHouse: false,
        customerAddressPostalAreaId: 0,
        answers: [],
        answersComplete: false,
        createProjectDisabled: false,
        showPostCodeError: false,
        privacyStatementModalShown: false,
        projectCreated: false,
        customerIsAxaCustomer: undefined,
    });
    const errorDivRef = useRef(null);
    const { userData } = useContext(UserContext);

    const onDrop = useCallback(acceptedFiles => {
        setState(prevState => ({
            ...prevState,
            customerUploadedFiles: ((parseInt(acceptedFiles.length) + parseInt(prevState.customerUploadedFiles.length) > 3)
                ? prevState.customerUploadedFiles
                : (prevState.customerUploadedFiles.some(x => acceptedFiles.some(y => x.name === y.name))
                    ? prevState.customerUploadedFiles :
                    [...prevState.customerUploadedFiles, ...acceptedFiles]))
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        // Disable click and keydown behavior
        onDrop,
        noClick: true,
        noKeyboard: true,
        accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'application/pdf': ['.pdf'],
        }
    });

    const getServices = async () => {
        const serviceResponse = await axios.get("api/servicecategory/getallactive");
        setState({
            ...state,
            serviceCategories: serviceResponse.data.data,
            selectedCatId: (props.subcatId ? serviceResponse.data.data.filter(x => x.serviceSubCategories.some(y => y.id === parseInt(props.subcatId)))[0].id : 0),
            selectedSubcatId: (props.subcatId ? props.subcatId : 0),
            selectedServiceId: (props.serviceId ? props.serviceId : 0)
        });
    }

    const updateServiceCat = (catId) => {
        if (!state.selectedServiceId) {
            setState({ ...state, selectedCatId: catId });
        }
        else {
            setState({
                ...state,
                serviceUpdateRequest: {
                    updatedField: "cat",
                    newValue: catId
                },
                areYouSureWarningModalShown: true
            });
        }
    }

    const updateServiceSubCat = (subCatId) => {
        if (!state.selectedServiceId) {
            setState({ ...state, selectedSubcatId: subCatId });
        }
        else {
            setState({
                ...state,
                serviceUpdateRequest: {
                    updatedField: "sub",
                    newValue: subCatId
                },
                areYouSureWarningModalShown: true
            });
        }
    }

    const updateService = (serviceId) => {
        if (!state.selectedServiceId) {
            setState({ ...state, selectedServiceId: serviceId });
        }
        else {
            setState({
                ...state,
                serviceUpdateRequest: {
                    updatedField: "service",
                    newValue: serviceId
                },
                areYouSureWarningModalShown: true
            });
        }
    }

    const acceptServiceChange = () => {
        setState(prevState => ({
            ...prevState,
            selectedServiceId:
                (prevState.serviceUpdateRequest.updatedField === "cat" ? 0 :
                    (prevState.serviceUpdateRequest.updatedField === "sub" ? 0 :
                        prevState.serviceUpdateRequest.newValue)),
            selectedSubcatId:
                (prevState.serviceUpdateRequest.updatedField === "cat" ? 0 :
                    (prevState.serviceUpdateRequest.updatedField === "service" ? prevState.selectedSubcatId :
                        prevState.serviceUpdateRequest.newValue)),
            selectedCatId:
                (prevState.serviceUpdateRequest.updatedField === "sub" ? prevState.selectedCatId :
                    (prevState.serviceUpdateRequest.updatedField === "service" ? prevState.selectedCatId :
                        prevState.serviceUpdateRequest.newValue)),
            areYouSureWarningModalShown: false,
            serviceUpdateRequest: {
                updatedField: "",
                newValue: 0
            },
            checkPostalCode: "",
            checkProvider: {},
            checkProviderResult: "no-result",
            answers: [],
            showPostCodeError: false,
            availablePostalAreas: [],
            selectedPostalAreaId: undefined,
        }));
    }

    const rejectServiceChange = () => {
        setState(prevState => ({
            ...prevState,
            areYouSureWarningModalShown: false,
            serviceUpdateRequest: {
                updatedField: "",
                newValue: 0
            }
        }));
    }

    const handleAnswer = (event, questionId, answerType, answerId) => {
        var newAnswers = state.answers
        if (answerType === "radio") {
            newAnswers = newAnswers.filter(x => x.questionId !== questionId);
            var radioAnswer = {
                questionId: questionId,
                answers: [answerId]
            }
            newAnswers.push(radioAnswer);
        }
        else if (answerType === "check") {
            if (newAnswers.some(x => x.questionId === questionId)) {
                newAnswers.forEach(x => {
                    if (x.questionId === questionId) {
                        if (event.target.checked) {
                            x.answers.push(answerId);
                        }
                        else {
                            x.answers = x.answers.filter(y => y !== answerId)
                        }
                    }
                });
                newAnswers = newAnswers.filter(x => x.answers.length > 0)
            } else {
                var checkAnswer = {
                    questionId: questionId,
                    answers: [answerId]
                }
                newAnswers.push(checkAnswer);
            }
        }
        setState({ ...state, answers: newAnswers });
    }

    const getPostCode = async () => {
        if (!state.checkPostalCode) {
            return;
        }
        try {
            const postalAreaResponse = await axios.get(`api/postalarea/getpostalareabypostalcode?postCode=${state.checkPostalCode}`);
            if (!(postalAreaResponse.data?.data)) {
                setState({
                    ...state,
                    availablePostalAreas: [],
                    selectedPostalAreaId: undefined,
                    showPostCodeError: true
                });
                window.dataLayer.push({
                    "event": "message",
                    "message": `postal code invalid - ${state.checkPostalCode}`
                });
                return;
            }
            setState({
                ...state,
                availablePostalAreas: postalAreaResponse.data.data,
                selectedPostalAreaId: postalAreaResponse.data.data[0].id,
                showPostCodeError: false
            })
            window.dataLayer.push({
                "event": "message",
                "message": `postal code valid - ${state.checkPostalCode}`
            });
        } catch {
            setState({
                ...state,
                availablePostalAreas: [],
                selectedPostalAreaId: undefined,
                showPostCodeError: true
            });
            window.dataLayer.push({
                "event": "message",
                "message": `postal code invalid - ${state.checkPostalCode}`
            });
            return;
        }
    }

    const getProvider = async () => {
        try {
            const providerResponse = await axios.get(`api/provider/getbypostalcodeservicewithhighestscore?serviceId=${state.selectedServiceId}&postalAreaId=${state.selectedPostalAreaId}`);
            setState(prevState =>({
                ...state,
                checkProvider: providerResponse.data.data,
                checkProviderResult: (!(providerResponse.data?.data) || providerResponse.data?.data.isError) ? "not-found" : "found",
                postalArea: prevState.availablePostalAreas.filter(x => x.id === parseInt(prevState.selectedPostalAreaId))[0],
                customerAddressCity: prevState.availablePostalAreas.filter(x => x.id === parseInt(prevState.selectedPostalAreaId))[0].cityPostalArea,
                showPostCodeError: false
            }));
            return;
        } catch {
            setState(prevState => ({
                ...state,
                checkProvider: undefined,
                checkProviderResult: "not-found",
                postalArea: undefined,
                customerAddressCity: undefined,
                showPostCodeError: true
            }));
        }
    }

    const handleGSMInput = async (event) => {
        const gsmNumberRegex = /^\+[0-9\s]{3,20}$/;
        setState({
            ...state,
            errorGsm: !gsmNumberRegex.test(event.target.value),
            customerGsmNumber: event.target.value
        })
    }

    const handleLastNameInput = async (event) => {
        const lastNameRegex = /^[A-Za-z\u00C0-\u02AF\u0370-\u03FF\u0400-\u04FF\u0500-\u052F\u2D00-\u2DFF\uA640-\uA69F\u1C80-\u1C88' -]+$/;
        setState({
            ...state,
            errorLastName: !lastNameRegex.test(event.target.value),
            customerLastName: event.target.value
        })
    }

    const handleFirstNameInput = async (event) => {
        const nameRegex = /^[A-Za-z\u00C0-\u02AF\u0370-\u03FF\u0400-\u04FF\u0500-\u052F\u2D00-\u2DFF\uA640-\uA69F\u1C80-\u1C88' -]+$/;
        setState({
            ...state,
            errorFirstName: !nameRegex.test(event.target.value),
            customerFirstName: event.target.value
        })
    }

    const handleEmailInput = async (event) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        setState({
            ...state,
            errorEmail: !emailRegex.test(event.target.value),
            customerEmail: event.target.value
        })
    }

    const createProject = async () => {
        const nameRegex = /^[A-Za-z\u00C0-\u02AF\u0370-\u03FF\u0400-\u04FF\u0500-\u052F\u2D00-\u2DFF\uA640-\uA69F\u1C80-\u1C88' -]+$/;
        const gsmNumberRegex = /^\+[0-9\s]{3,20}$/;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        const showErrorDiv = (!nameRegex.test(state.customerLastName)
            || !nameRegex.test(state.customerFirstName)
            || !gsmNumberRegex.test(state.customerGsmNumber)
            || !emailRegex.test(state.customerEmail)
            || !state.customerPrivacyAccepted
            || !state.customerAddressStreetAndHouse
            || !state.customerIsAxaCustomer)
          

        setState(prevState => ({
            ...prevState,
            errorLastName: !nameRegex.test(prevState.customerLastName),
            errorFirstName: !nameRegex.test(prevState.customerFirstName),
            errorGsm: !gsmNumberRegex.test(prevState.customerGsmNumber),
            errorEmail: !emailRegex.test(prevState.customerEmail),
            errorPrivacy: !prevState.customerPrivacyAccepted,
            errorCustomerAddressStreetAndHouse: !prevState.customerAddressStreetAndHouse,
            showErrorDiv: showErrorDiv,
            createProjectDisabled: !showErrorDiv && state.customerAddressStreetAndHouse && state.customerAddressCity,
            errorCustomerIsAxaCustomer: !state.customerIsAxaCustomer
        }));

        if (showErrorDiv) {
            return;
        }

        if (!state.customerAddressStreetAndHouse || !state.customerAddressCity) {
            return;
        }
        
        var newProjectRequest = {
            ServiceId: state.selectedServiceId,
            PostalAreaId: state.postalArea.id,
            ProviderId: state.checkProvider ? state.checkProvider.id : null,

            CustomerIsAxaCustomer: state.customerIsAxaCustomer === "true" ? true : false,
            CustomerLastName: state.customerLastName,
            CustomerName: state.customerFirstName,
            CustomerGSM: state.customerGsmNumber,
            CustomerEmail: state.customerEmail,
            CustomerDescription: state.customerDescription,
            CustomerStreetHouseNumber: state.customerAddressStreetAndHouse,
            CustomerBus: state.customerAddressBusStation,
            CustomerPrivacyAccepted: state.customerPrivacyAccepted,
            CustomerPrivacyMarketing: state.customerPrivacyMarketing,
            CustomerLanguage: userData.language,

            Answers: state.answers
        }

        var response = await axios.post("api/homeproject/addproject", newProjectRequest);

        const formData = new FormData();
        formData.append("projectId", parseInt(response.data.data))
       
        for (const file of state.customerUploadedFiles) {
            formData.append("files", file)
        }

        const requestHeaders = {
            "Content-Type": "multipart/form-data"
        }

        await axios.post("api/homeproject/addfilestoproject", formData, { headers: requestHeaders })

        if (response.data.isError) {
            setState({
                subCatData: {},
                currentActiveTabIndex: 0,
                activeTab: 1,
                showOffCanvas: false,
                serviceCategories: [],
                selectedCatId: 0,
                selectedSubcatId: 0,
                selectedServiceId: 0,
                checkPostalCode: "",
                checkProvider: {},
                checkProviderResult: "no-result",
                areYouSureWarningModalShown: false,
                serviceUpdateRequest: {
                    updatedField: "",
                    newValue: 0
                },
                customerDescription: "",
                customerUploadedFiles: [],
                customerLastName: "",
                customerFirstName: "",
                customerGsmNumber: "+32",
                customerEmail: "",
                customerAddressStreetAndHouse: "",
                customerAddressBusStation: "",
                customerAddressCity: "",
                customerPrivacyAccepted: false,
                customerPrivacyMarketing: false,
                errorGsm: false,
                errorLastName: false,
                errorFirstName: false,
                errorEmail: false,
                errorPrivacy: false,
                errorCustomerIsAxaCustomer: false,
                customerAddressPostalAreaId: 0,
                answers: [],
                answersComplete: false,
                createProjectDisabled: false
            });
        }
        else {
            setState({
                ...state,
                activeTab: 5,
                projectCreated: true
            })
            props.showSuccessMessage();
            setState({ ...state, activeTab: 5 });
            window.dataLayer.push({
                "event": "virtual_page_view",
                "page": {
                    "language": localStorage.getItem("language")?.toLowerCase(),
                    "event": "virtual_page_view",
                    "step number": "5",
                    "name": "confirmation",
                    "axa client": state.customerIsAxaCustomer,
                    "marketing optin": state.customerPrivacyMarketing ? "true" : "false",
                }
            });
        }
    }

    const initialStep = async () => {
        setState({ ...state, activeTab: 1 });
        window.dataLayer.push ({
            "page": {
                "language": localStorage.getItem("language")?.toLowerCase(),
                "step number": "1",
                "name": "service selection"
            }
        });
    };

    const moveToStepTwo = async () => {
        setState({ ...state, activeTab: 2 });
        window.dataLayer.push({
            "event": "virtual_page_view",
            "page": {
                "language": localStorage.getItem("language")?.toLowerCase(),
                "step number": "2",
                "name": "pro finder",
                "service sub category": state.serviceCategories?.find(x => x.id === parseInt(state.selectedCatId))?.serviceSubCategories.find(x => x.id === parseInt(state.selectedSubcatId)).name?.toLowerCase(),
                "service": state.serviceCategories?.find(x => x.id === parseInt(state.selectedCatId))?.serviceSubCategories.find(x => x.id === parseInt(state.selectedSubcatId)).services.find(x => x.id === parseInt(state.selectedServiceId)).name.toLowerCase(),
            }
        });
    };

    const moveToStepThree = async () => {
        setState({ ...state, activeTab: 3 });
        window.dataLayer.push({
            "event": "virtual_page_view",
            "page": {
                "language": localStorage.getItem("language").toLowerCase(),
                "event": "virtual_page_view",
                "step number": "3",
                "name": "intervention detail",
                "postal code": state.checkProviderResult === "no-result" ? "" : state.availablePostalAreas.find(x => x.id === parseInt(state.selectedPostalAreaId)).postCode
            }
        });
    };

    const moveToStepFour = async () => {
        setState({ ...state, activeTab: 4 });
        window.dataLayer.push({
            "event": "virtual_page_view",
            "page": {
                "language": localStorage.getItem("language").toLowerCase(),
                "event": "virtual_page_view",
                "step number": "4",
                "name": "personal data",
            }
        });
    };

    useEffect(() => {
        var allAnserwsExist = true;
        state.serviceCategories
            ?.find(x => x.id === parseInt(state.selectedCatId))
            ?.serviceSubCategories?.find(x => x.id === parseInt(state.selectedSubcatId))
            ?.services.find(x => x.id === parseInt(state.selectedServiceId))
            ?.serviceQuestions.forEach(x => {
                if (!state.answers.some(y => y.questionId === x.questionId)) {
                    allAnserwsExist = false;
                }
            });
        setState({ ...state, answersComplete: allAnserwsExist });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.answers, state.selectedServiceId])

    useEffect(() => {
        if (state.showErrorDiv) {
            errorDivRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.showErrorDiv])

    useEffect(() => {
        if (!(state.errorLastName || state.errorFirstName || state.errorGsm || state.errorEmail || state.errorPrivacy || state.errorCustomerAddressStreetAndHouse || state.errorCustomerIsAxaCustomer)) {
            setState({ ...state, showErrorDiv: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.errorLastName, state.errorFirstName, state.errorGsm, state.errorEmail, state.errorPrivacy, state.errorCustomerIsAxaCustomer])

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.activeTab])

    useEffect(() => {
        getServices();
        initialStep();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="d-flex axa-steps-list">
                <div className="flex-fill axa-step-list-item bg-grey-100 px-0 active axa-project-steps-border-color">
                    <span className="axa-stepbar-number active me-1">1</span>
                    <label className={"axa-stepsbar-text past axa-mobile-hidden"}>{translate("newProjectStepService", userData.language)}</label>
                </div>
                <div className="axa-step-list-item bg-grey-100 px-0 active axa-project-steps-border-color">
                    <img src={chevronRight} alt="chv-r-1" />
                </div>
                <div className={"flex-fill axa-step-list-item bg-grey-100 px-0 axa-project-steps-border-color " + (state.activeTab >= 2 ? "active" : "")} align="center">
                    <span className={"axa-stepbar-number me-1 " + (state.activeTab >= 2 ? "active" : "")}>2</span>
                    <label className={"axa-stepsbar-text axa-mobile-hidden " + (state.activeTab >= 2 ? "past" : "")}>{translate("newProjectStepExpert", userData.language)}</label>
                </div>
                <div className={"axa-step-list-item bg-grey-100 px-0 axa-project-steps-border-color " + (state.activeTab >= 2 ? "active" : "")} align="center">
                    <img src={chevronRight} alt="chv-r-1" />
                </div>
                <div className={"flex-fill axa-step-list-item bg-grey-100 px-0 axa-project-steps-border-color " + (state.activeTab >= 3 ? "active" : "")} align="center">
                    <span className={"axa-stepbar-number me-1 " + (state.activeTab >= 3 ? "active" : "")}>3</span>
                    <label className={"axa-stepsbar-text axa-mobile-hidden " + (state.activeTab >= 3 ? "past" : "")}>{translate("newProjectStepDetails", userData.language)}</label>
                </div>
                <div className={"axa-step-list-item bg-grey-100 px-0 axa-project-steps-border-color " + (state.activeTab >= 3 ? "active" : "")} align="center">
                    <img src={chevronRight} alt="chv-r-1" />
                </div>
                <div className={"flex-fill axa-step-list-item bg-grey-100 px-0 axa-project-steps-border-color " + (state.activeTab >= 4 ? "active" : "")} align="center">
                    <span className={"axa-stepbar-number me-1 " + (state.activeTab >= 4 ? "active" : "")}>4</span>
                    <label className={"axa-stepsbar-text axa-mobile-hidden " + (state.activeTab >= 4 ? "past" : "")}>{translate("newProjectStepIformation", userData.language)}</label>
                </div>
                <div className={"axa-step-list-item bg-grey-100 px-0 axa-project-steps-border-color " + (state.activeTab >= 4 ? "active" : "")} align="center">
                    <img src={chevronRight} alt="chv-r-1" />
                </div>
                <div className={"flex-fill axa-step-list-item bg-grey-100 px-0 axa-project-steps-border-color " + (state.activeTab >= 5 ? "active" : "")} align="center">
                    <span className={"axa-stepbar-number me-1 " + (state.activeTab >= 5 ? "active" : "")}>5</span>
                    <label className={"axa-stepsbar-text axa-mobile-hidden " + (state.activeTab >= 5 ? "past" : "")}>{translate("newProjectStepConfirmation", userData.language)}</label>
                </div>
            </div>
            <div align="center" >
                <div className="tab-content">
                    <div className={"tab-pane fade " + (state.activeTab === 1 ? "show active" : "")} tabIndex="0">
                        <div className="axa-project-card">
                            <div className="axa-subcat-card-padding axa-card-shadow mt-4 bg-white">
                                <div className="axa-mobile-hidden" style={{ "height": "32px" }}></div>
                                <div className="axa-service-card-title align-left pt-5">{translate("newProjectTitleService", userData.language)}</div>
                                <div className="title-sub-text align-left pt-4 pb-4">{translate("newProjectTextService", userData.language)}</div>
                                <hr className="axa-service-card-diveder" />
                                <div className="title-sub-text align-left pt-4">
                                    <label htmlFor="select-service-category">{translate("newProjectLabelCategory", userData.language)}</label>
                                </div>
                                <div className="title-sub-text align-left pt-1">
                                    <div className="row">
                                        <div className="col-md-8 col-sm-12">
                                            <select
                                                id="select-service-category"
                                                value={state.selectedCatId}
                                                className="form-select text-primary"
                                                onChange={(event) => updateServiceCat(event.target.value)}
                                            >
                                                <option className="text-black" value={0} style={{ display: (state.selectedCatId === 0 ? "" : "none") }}>{translate("newProjectSelectMakeASelectionText", userData.language)}</option>
                                                {state.serviceCategories?.map((cat, index) => {
                                                    return (
                                                        <option
                                                            key={"catOption-" + index}
                                                            className="text-black"
                                                            value={cat.id}
                                                        >
                                                            {translate(cat.code, userData.language)}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: state.selectedCatId !== 0 ? "" : "none" }}>
                                    <div className="title-sub-text align-left pt-3">
                                        <label htmlFor="select-service-subcategory">{translate("newProjectLabelSubCategory", userData.language)}</label>
                                    </div>
                                    <div className="title-sub-text align-left pt-1">
                                        <div className="row">
                                            <div className="col-md-8 col-sm-12">
                                                <select
                                                    id="select-service-subcategory"
                                                    value={state.selectedSubcatId}
                                                    className="form-select text-primary"
                                                    onChange={(event) => updateServiceSubCat(event.target.value)}
                                                >
                                                    <option className="text-black" value={0} style={{ display: (state.selectedSubcatId === 0 ? "" : "none") }}>{translate("newProjectSelectMakeASelectionText", userData.language)}</option>
                                                    {state.serviceCategories?.find(x => x.id === parseInt(state.selectedCatId))?.serviceSubCategories?.map((cat, index) => {
                                                        return (
                                                            <option
                                                                key={"catOption-" + index}
                                                                className="text-black"
                                                                value={cat.id}
                                                            >
                                                                {translate(cat.code, userData.language)}
                                                            </option>)
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: state.selectedSubcatId !== 0 ? "" : "none" }}>
                                    <div className="title-sub-text align-left pt-3">{translate("newProjectLabelService", userData.language)}</div>
                                    <div className="axa-under-text align-left">{translate("newProjectLabelServiceUnderText", userData.language)}</div>
                                    <div className="title-sub-text align-left pt-1">
                                        <div className="row">
                                            {state.serviceCategories?.find(x => x.id === parseInt(state.selectedCatId))?.serviceSubCategories?.find(x => x.id === parseInt(state.selectedSubcatId))?.services.map((service, index) => {
                                                return (
                                                    <div
                                                        key={"serviceRadio-" + index}
                                                        className="col-md-4 col-12 d-grid mt-2 axa-service-selection-button-height"
                                                    >
                                                        <input
                                                            type="radio"
                                                            className="btn-check"
                                                            name="optionsService"
                                                            id={"option-" + index}
                                                            value={service.id}
                                                            checked={service.id === parseInt(state.selectedServiceId)}
                                                            onChange={(event) => updateService(event.target.value)}
                                                        />
                                                        <label className="btn btn-outline-primary axa-service-radio-label" htmlFor={"option-" + index}>
                                                            {translate(service.code, userData.language)}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column-reverse flex-md-row justify-content-end pt-5">
                                
                                <button
                                    className="btn btn-secondary"
                                    disabled={parseInt(state.selectedServiceId) === 0}
                                    onClick={() => moveToStepTwo()}
                                >
                                    {translate("btnProjectForward", userData.language)}
                                    <FontAwesomeIcon className="ms-1" icon={faArrowRight} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"tab-pane fade " + (state.activeTab === 2 ? "show active" : "")} tabIndex="0" >
                        <div className="axa-project-card">
                            <div className="axa-subcat-card-padding axa-card-shadow mt-4 bg-white" >
                                <div className="axa-mobile-hidden" style={{ "height": "32px" }}></div>
                                <div className="axa-service-card-title align-left pt-5">{translate("newProjectTitleExpert", userData.language)}</div>
                                <div className="title-sub-text align-left pt-4 pb-4">{translate("newProjectTextExpert", userData.language)}</div>
                                <hr className="axa-service-card-diveder" />
                                <div style={{ display: state.checkProviderResult === "no-result" ? "" : "none" }}>
                                    <div className="text-start " style={{ display: (state.showPostCodeError ? "" : "none") }}>
                                        <div className="axa-error-box mt-5">
                                            <div className="p-3" align="left">
                                                <div className="d-flex gap-3">
                                                    <div>
                                                        <img src={errorFill} alt="check-provider" />
                                                    </div>
                                                    <div className="flex-fill">
                                                        <div className="axa-success-box-header pt-1">
                                                            {translate("newProjectExpertCheckPostCodeErrorHeader", userData.language)}
                                                        </div>
                                                        <div className="axa-success-box-text py-2">
                                                            {translate("newProjectExpertCheckPostCodeErrorText", userData.language)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12 mb-1">
                                            <div className="title-sub-text align-left pt-4 mb-1 d-flex">
                                                <label htmlFor="textbox-postal-code">{translate("newProjectLabelExpertPostCode", userData.language)}</label>
                                            </div>
                                            <div className="input-group">
                                                <input
                                                    id="textbox-postal-code"
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(event) => setState({ ...state, checkPostalCode: event.target.value })}
                                                    value={state.checkPostalCode}
                                                />
                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    type="button"
                                                    onClick={() => getPostCode() }
                                                >
                                                    <FontAwesomeIcon icon={faSearch} />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-sm-12" style={{ display: (state.availablePostalAreas.length === 0 ? "none" : "") }}>
                                            <div className="title-sub-text align-left pt-4 mb-1 d-flex">
                                                <label htmlFor="select-provider-city">{translate("newProjectCustomerInfoLabelCity", userData.language)}</label>
                                            </div>
                                            <select
                                                id="select-provider-city"
                                                value={state.selectedPostalAreaId}
                                                className="form-select text-primary"
                                                onChange={(event) => setState({ ...state, selectedPostalAreaId: event.target.value })}
                                            >
                                                {state.availablePostalAreas.map((area, index) => {
                                                    return <option key={"postalAreaOption-"+index} value={area.id}>{area.cityPostalArea}</option>
                                                }) }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="axa-under-text align-left">{translate("newProjectLabelExpertUnderText", userData.language)}</div>
                                    <div className="row" style={{ display: (state.availablePostalAreas.length === 0 ? "none" : "") }}>
                                        <div className="col-md-6 col-sm-12 mt-4 d-grid" align="left">
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => getProvider()}
                                            >
                                                {translate("newProjectExpertCheckAvailabilityButton", userData.language)}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: state.checkProviderResult === "found" ? "" : "none" }}>
                                    <div className="axa-success-box mt-5">
                                        <div className="p-3" align="left">
                                            <div className="d-flex gap-3">
                                                <div>
                                                    <img src={checkFill} alt="check-provider" />
                                                </div>
                                                <div className="flex-fill">
                                                    <div className="axa-success-box-header pt-1">
                                                        {translate("newProjectExpertCheckSuccessTitle", userData.language)}
                                                    </div>
                                                    <div className="axa-success-box-text py-2">
                                                        {translate("newProjectExpertCheckSuccessText", userData.language)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: state.checkProviderResult === "not-found" ? "" : "none" }}>
                                    <div className="axa-warning-box mt-5">
                                        <div className="p-3" align="left">
                                            <div className="d-flex gap-3">
                                                <div>
                                                    <img src={warningFill} alt="warning-provider" />
                                                </div>
                                                <div className="flex-fill">
                                                    <div className="axa-success-box-header pt-1">
                                                        {translate("newProjectExpertCheckWarningTitle", userData.language)}
                                                    </div>
                                                    <div className="axa-success-box-text py-2">
                                                        {translate("newProjectExpertCheckWarningText", userData.language)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-end pt-5" style={{ display: state.checkProviderResult === "not-found" ? "" : "none" }}>
                                <div className="col axa-header-SansPro-24-700">
                                    {translate("newProjectDoYouWantToContinue", userData.language)}
                                </div>
                            </div>
                            <div className={"d-flex flex-column-reverse flex-md-row pt-4 " + (state.checkProviderResult === "not-found" ? "justify-content-end" : "justify-content-between")}>
                                <button
                                    className={"btn " + (state.checkProviderResult === "not-found" ? "btn-outline-primary me-4" : "text-secondary")}
                                    onClick={() => initialStep()}
                                >
                                    <FontAwesomeIcon className="me-1" icon={faArrowLeft} />
                                    {state.checkProviderResult === "not-found" ? translate("btnNoExpertBack", userData.language) : translate("newProjectBack", userData.language)}
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    disabled={state.checkProviderResult === "no-result"}
                                    onClick={() => moveToStepThree()}
                                >
                                    {state.checkProviderResult === "not-found" ? translate("btnNoExpertProjectForward", userData.language) : translate("btnProjectForward", userData.language)}
                                    <FontAwesomeIcon className="ms-1" icon={faArrowRight} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"tab-pane fade " + (state.activeTab === 3 ? "show active" : "")} tabIndex="0">
                        <div className="axa-project-card">
                            <div className="axa-subcat-card-padding axa-card-shadow mt-4 bg-white" >
                                <div className="axa-mobile-hidden" style={{ "height": "32px" }}></div>
                                <div className="axa-service-card-title align-left pt-5 pb-5">{translate("newProjectDetailsTitle", userData.language)}</div>
                                <hr className="axa-service-card-diveder" />
                                <div
                                    className="axa-newproject-second-title align-left pt-4 pb-4"
                                    style={{
                                        display: (state.serviceCategories
                                            ?.find(x => x.id === parseInt(state.selectedCatId))
                                            ?.serviceSubCategories?.find(x => x.id === parseInt(state.selectedSubcatId))
                                            ?.services.find(x => x.id === parseInt(state.selectedServiceId))
                                            ?.serviceQuestions.length > 0 ? "" : "none")
                                    }}
                                >
                                    {translate("newProjectDetailsSecondaryTitleSpecifics", userData.language)}
                                </div>
                                <div align="left">
                                    {state.serviceCategories
                                        ?.find(x => x.id === parseInt(state.selectedCatId))
                                        ?.serviceSubCategories?.find(x => x.id === parseInt(state.selectedSubcatId))
                                        ?.services.find(x => x.id === parseInt(state.selectedServiceId))
                                        ?.serviceQuestions.map((serviceQuestion, index) => {
                                            return (
                                                <div className="mb-5" key={"serviceQuestion-" + index} align="left">
                                                    <div className="axa-input-label align-left">{translate(serviceQuestion.question.questionCode, userData.language)}</div>
                                                    <div>{translate("newProject-" + serviceQuestion.question.answerType + "AswerTypeDescr", userData.language)}</div>
                                                    {serviceQuestion.question.answerType === "radio" ?
                                                        (serviceQuestion.question.answers.map((answer) => {
                                                            return (
                                                                <div className="form-check" key={"questionGroup-" + answer.questionId + "-answer-" + answer.id}>
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name={"questionGroup-" + answer.questionId}
                                                                        id={"question-" + answer.questionId + "-answer-" + answer.id}
                                                                        onChange={(event) => handleAnswer(event, serviceQuestion.question.id, serviceQuestion.question.answerType, answer.id)}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor={"question-" + answer.questionId + "-answer-" + answer.id}>
                                                                        {translate(answer.answerCode, userData.language)}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })) : (serviceQuestion.question.answers.map((answer) => {
                                                            return (
                                                                <div className="form-check" key={"questionGroup-" + answer.questionId + "-answer-" + answer.id}>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id={"question-" + answer.questionId + "-answer-" + answer.id}
                                                                        onChange={(event) => handleAnswer(event, serviceQuestion.question.id, serviceQuestion.question.answerType, answer.id)}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor={"question-" + answer.questionId + "-answer-" + answer.id}>
                                                                        {translate(answer.answerCode, userData.language)}
                                                                    </label>
                                                                </div>
                                                            )
                                                        }))}
                                                </div>
                                            )
                                        })}
                                </div>
                                <hr
                                    className="axa-service-card-diveder"
                                    style={{
                                        display: (state.serviceCategories
                                            ?.find(x => x.id === parseInt(state.selectedCatId))
                                            ?.serviceSubCategories?.find(x => x.id === parseInt(state.selectedSubcatId))
                                            ?.services.find(x => x.id === parseInt(state.selectedServiceId))
                                            ?.serviceQuestions.length > 0 ? "" : "none")
                                    }}
                                />
                                <div className="axa-newproject-second-title align-left pt-4 pb-4">{translate("newProjectDetailsSecondaryTitleDetails", userData.language)}</div>
                                <div align="left">
                                    <span className="axa-input-label align-left">{translate("newProjectDetailsLabelDetailText", userData.language)}</span>
                                    <span> {translate("newProjectDetailsLabelOptional", userData.language)}</span>
                                </div>
                                <div className="row justify-content-left pt-1 pb-5">
                                    <div className="col-12 col-md-7" align="left">
                                        <textarea
                                            className="form-control"
                                            placeholder="..." rows="5"
                                            value={state.customerDescription}
                                            onChange={(event) => setState({ ...state, customerDescription: event.target.value })}
                                        />
                                    </div>
                                </div>
                                <div align="left">
                                    <span className="axa-input-label align-left">{translate("newProjectDetailsLabelFile", userData.language)}</span>
                                    <span> {translate("newProjectDetailsLabelOptional", userData.language)}</span>
                                </div>
                                <div {...getRootProps()} className="axa-input-file-div">
                                    <img className="mt-5" src={uploadIcon} alt="file-upload" />
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ?
                                            <p>{translate("newProjectDetailsFileUploadDropHere", userData.language)}</p> :
                                            <p>{translate("newProjectDetailsFileUploadDropDescription", userData.language)}</p>
                                    }
                                    <p>
                                        {translate("newProjectDetailsFileUploadOR", userData.language)}
                                    </p>
                                    <button className="btn btn-outline-primary mb-5" onClick={open}>
                                        <span className="axa-mobile-hidden">{translate("btnOpenFileDialog", userData.language)}</span>
                                        <span className="d-md-none">{translate("btnOpenFileDialogMobile", userData.language)}</span>
                                    </button>
                                </div>
                                <div className="mb-3" align="left">
                                    <img src={InfoIcon} alt="info" />
                                    <span className="ms-2">{translate("newProjectFileSizeAndTypeDescr", userData.language)}</span>
                                </div>
                                <div>
                                    <table className="table table-striped" style={{ display: state.customerUploadedFiles.length > 0 ? "" : "none" }}>
                                        <thead className="axa-files-table-header">
                                            <tr>
                                                <th></th>
                                                <th className="axa-files-table-cell">{translate("newProjectFileTableFileNameHeader", userData.language)}</th>
                                                <th className="text-center axa-files-table-cell">{translate("newProjectFileTableDeleteHeader", userData.language)}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="axa-files-table-body">
                                            {state.customerUploadedFiles.map((file, index) => {
                                                return (
                                                    <tr key={"files-row-" + index}>
                                                        <td className="axa-files-table-cell" align="right"><img src={FileIcon} alt="file" /></td>
                                                        <td className="axa-files-table-cell">{file.name}</td>
                                                        <td className="axa-files-table-cell" align="center">
                                                            <button
                                                                className="btn btn-sm"
                                                                onClick={() => setState(prevState => ({ ...prevState, customerUploadedFiles: prevState.customerUploadedFiles.filter(x => x.name !== file.name) }))}
                                                            >
                                                                <img src={DeleteIcon} alt="delete file" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={"d-flex flex-column-reverse flex-md-row pt-4 " + (state.checkProviderResult === "not-found" ? "justify-content-end" : "justify-content-between")}>
                                <button
                                    className="btn text-secondary"
                                    onClick={() => setState({ ...state, activeTab: 2 })}
                                >
                                    <FontAwesomeIcon className="me-1" icon={faArrowLeft} />
                                    {translate("newProjectBack", userData.language)}
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => moveToStepFour()}
                                    disabled={!state.answersComplete}
                                >
                                    {translate("btnProjectForward", userData.language)}
                                    <FontAwesomeIcon className="ms-1" icon={faArrowRight} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"tab-pane fade " + (state.activeTab === 4 ? "show active" : "")} tabIndex="0">
                        <div className="axa-project-card">
                            <div className="axa-subcat-card-padding axa-card-shadow mt-4 bg-white" >
                                <div className="axa-mobile-hidden" style={{ "height": "32px" }}></div>
                                <div className="axa-service-card-title align-left pt-5 pb-5">{translate("newProjectCustomerInfoHeader", userData.language)}</div>
                                <hr className="axa-service-card-diveder" />
                                <div className="axa-error-box" style={{ display: (state.showErrorDiv ? "" : "none") }} ref={errorDivRef}>
                                    <div className="p-3" align="left">
                                        <div className="d-flex gap-3">
                                            <div>
                                                <img src={errorFill} alt="error icon" />
                                            </div>
                                            <div className="flex-fill">
                                                <div className="axa-success-box-header pt-1">
                                                    {translate("newProjectCustomerInfoErrorDivHeader", userData.language)}
                                                </div>
                                                <div className="py-2">
                                                    <ul>
                                                        <li style={{ display: (state.errorLastName ? "" : "none") }}>
                                                            {translate("newProjectCustomerInfoErrorDivLastName", userData.language)}
                                                        </li>
                                                        <li style={{ display: (state.errorFirstName ? "" : "none") }}>
                                                            {translate("newProjectCustomerInfoErrorDivFirsName", userData.language)}
                                                        </li>
                                                        <li style={{ display: (state.errorGsm ? "" : "none") }}>
                                                            {translate("newProjectCustomerInfoErrorDivGsm", userData.language)}
                                                        </li>
                                                        <li style={{ display: (state.errorEmail ? "" : "none") }}>
                                                            {translate("newProjectCustomerInfoErrorDivEmail", userData.language)}
                                                        </li>
                                                        <li style={{ display: (state.errorPrivacy ? "" : "none") }}>
                                                            {translate("newProjectCustomerInfoErrorDivPrivacy", userData.language)}
                                                        </li>
                                                        <li style={{ display: (state.errorCustomerAddressStreetAndHouse ? "" : "none") }}>
                                                            {translate("newProjectCustomerInfoErrorDivCustomerAddress", userData.language)}
                                                        </li>
                                                        <li style={{ display: (state.errorCustomerIsAxaCustomer ? "" : "none") }}>
                                                            {translate("newProjectCustomerInfoErrorDivCustomerIsAxaCustomer", userData.language)}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="axa-newproject-second-title align-left pt-4 pb-4">{translate("newProjectCustomerInfoSecondaryTitleContact", userData.language)}</div>
                                <div className="axa-input-label align-left">{translate("newProjectCustomerInfoLabelAxaCustomer", userData.language)}</div>
                                <div className="row mt-2 gap-1" align="left">
                                    <div className="col-md-3 col-sm-6 d-grid" align="left">
                                        <input type="radio" className="btn-check" name="optionsAxaCustomer" id="rdbAxaCustomerYes" value={true} onChange={(event) => setState({ ...state, customerIsAxaCustomer: event.target.value })} />
                                        <label className="btn btn-outline-primary" htmlFor="rdbAxaCustomerYes">{translate("newProjectCustomerInfoBtnAxaCustomerYes", userData.language)}</label>
                                    </div>
                                    <div className="col-md-3 col-sm-6 d-grid" align="left">
                                        <input type="radio" className="btn-check" name="optionsAxaCustomer" id="rdbAxaCustomerNo" value={false} onChange={(event) => setState({ ...state, customerIsAxaCustomer: event.target.value })} />
                                        <label className="btn btn-outline-primary" htmlFor="rdbAxaCustomerNo">{translate("newProjectCustomerInfoBtnAxaCustomerNo", userData.language)}</label>
                                    </div>
                                </div>
                                <div className="axa-input-label align-left mt-4">
                                    <label htmlFor="textbox-customer-last-name">{translate("newProjectCustomerInfoLabelLastName", userData.language)}</label>
                                </div>
                                <div className="row mt-1" align="left">
                                    <div className="col-md-7 col-sm-12 d-grid" align="left">
                                        <input
                                            id="textbox-customer-last-name"
                                            type="text"
                                            className={"form-control " + (state.errorLastName ? "border-danger" : "")}
                                            onChange={(event) => handleLastNameInput(event)}
                                            value={state.customerLastName}
                                        />
                                    </div>
                                </div>
                                <div className="text-danger" style={{ display: (state.errorLastName ? "" : "none") }} align="left">{translate("newProjectCustomerInfoErrorLastName", userData.language)}</div>
                                <div className="axa-input-label align-left mt-4">
                                    <label htmlFor="textbox-customer-name">{translate("newProjectCustomerInfoLabelFirstName", userData.language)}</label>
                                </div>
                                <div className="row mt-1" align="left">
                                    <div className="col-md-7 col-sm-12 d-grid" align="left">
                                        <input
                                            id="textbox-customer-name"
                                            type="text"
                                            className={"form-control " + (state.errorFirstName ? "border-danger" : "")}
                                            onChange={(event) => handleFirstNameInput(event)}
                                            value={state.customerFirstName}
                                        />
                                    </div>
                                </div>
                                <div className="text-danger" style={{ display: (state.errorFirstName ? "" : "none") }} align="left">{translate("newProjectCustomerInfoErrorFirstName", userData.language)}</div>
                                <div className="axa-input-label align-left mt-4">
                                    <label htmlFor="textbox-customer-gsm">{translate("newProjectCustomerInfoLabelGsmNumber", userData.language)}</label>
                                </div>
                                <div className="row mt-1" align="left">
                                    <div className="col-md-7 col-sm-12 d-grid" align="left">
                                        <input
                                            id="textbox-customer-gsm"
                                            type="text"
                                            className={"form-control " + (state.errorGsm ? "border-danger" : "")}
                                            onChange={(event) => handleGSMInput(event)}
                                            value={state.customerGsmNumber}
                                        />
                                    </div>
                                </div>
                                <div className="text-danger" style={{ display: (state.errorGsm ? "" : "none") }} align="left">{translate("newProjectCustomerInfoErrorGsmNumber", userData.language)}</div>
                                <div align="left" className={(state.errorGsm ? "text-danger" : "")}>{translate("newProjectCustomerInfoSecondaryLabelGsmNumberExample", userData.language)}</div>
                                <div className="axa-input-label align-left mt-4">
                                    <label htmlFor="textbox-customer-email">{translate("newProjectCustomerInfoLabelEmail", userData.language)}</label>
                                </div>
                                <div className="row mt-1" align="left">
                                    <div className="col-md-7 col-sm-12 d-grid" align="left">
                                        <input
                                            id="textbox-customer-email"
                                            type="text"
                                            className={"form-control " + (state.errorEmail ? "border-danger" : "")}
                                            onChange={(event) => handleEmailInput(event)}
                                            value={state.customerEmail}
                                        />
                                    </div>
                                </div>
                                <div className="text-danger" style={{ display: (state.errorEmail ? "" : "none") }} align="left">{translate("newProjectCustomerInfoErrorEmail", userData.language)}</div>
                                <div align="left" className={(state.errorEmail ? "text-danger" : "")}>{translate("newProjectCustomerInfoSecondaryLabelEmailExample", userData.language)}</div>
                                <hr className="axa-service-card-diveder mt-7" />
                                <div className="axa-newproject-second-title align-left pt-4 pb-4">{translate("newProjectCustomerInfoSecondaryTitleAddress", userData.language)}</div>
                                <div className="axa-input-label align-left">
                                    <label htmlFor="textbox-customer-street-and-home-address">{translate("newProjectCustomerInfoLabelStreetAndHouse", userData.language)}</label>
                                </div>
                                <div className="row mt-1" align="left">
                                    <div className="col-md-7 col-sm-12 d-grid" align="left">
                                        <input
                                            id="textbox-customer-street-and-home-address"
                                            type="text"
                                            className={"form-control " + (state.errorCustomerAddressStreetAndHouse ? "border-danger" : "")}
                                            onChange={(event) => setState({ ...state, customerAddressStreetAndHouse: event.target.value, errorCustomerAddressStreetAndHouse: event.target.value ? false : true })}
                                            onBlur={(event) => setState({ ...state, errorCustomerAddressStreetAndHouse: event.target.value? false : true })}
                                            value={state.customerAddressStreetAndHouse}
                                        />
                                    </div>
                                </div>
                                <div className="text-danger" style={{ display: (state.errorCustomerAddressStreetAndHouse ? "" : "none") }} align="left">{translate("newProjectCustomerInfoErrorCustomerAddress", userData.language)}</div>
                                <div align="left" className="mt-4">
                                    <span className="axa-input-label align-left">
                                        <label htmlFor="textbox-customer-bus">{translate("newProjectCustomerInfoLabelBusStation", userData.language)}</label>
                                    </span>
                                    <span> {translate("newProjectDetailsLabelOptional", userData.language)}</span>
                                </div>
                                <div className="row mt-1" align="left">
                                    <div className="col-md-3 col-sm-12 d-grid" align="left">
                                        <input
                                            id="textbox-customer-bus"
                                            type="text"
                                            className="form-control"
                                            onChange={(event) => setState({ ...state, customerAddressBusStation: event.target.value })}
                                            value={state.customerAddressBusStation}
                                        />
                                    </div>
                                </div>
                                <div className="axa-input-label align-left mt-4">
                                    <label htmlFor="textbox-customer-selected-city">{translate("newProjectCustomerInfoLabelCity", userData.language)}</label>
                                </div>
                                <div className="row mt-1" align="left">
                                    <div className="col-md-7 col-sm-12 d-grid" align="left">
                                        <input
                                            id="textbox-customer-selected-city"
                                            type="text"
                                            className="form-control"
                                            value={state.customerAddressCity}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="axa-input-label align-left mt-4">
                                    <label htmlFor="textbox-customer-selected-potal-code">{translate("newProjectCustomerInfoLabelPostalCode", userData.language)}</label>
                                </div>
                                <div className="row mt-1" align="left">
                                    <div className="col-md-3 col-sm-12 d-grid" align="left">
                                        <input
                                            id="textbox-customer-selected-potal-code"
                                            type="text"
                                            className="form-control"
                                            value={state.checkPostalCode}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form-check mt-5" align="left">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={state.customerPrivacyAccepted}
                                        onChange={(event) => setState({ ...state, customerPrivacyAccepted: event.target.checked })}
                                        id="chkPrivacy"
                                    />
                                    <label className="form-check-label" htmlFor="chkPrivacy">
                                        {translate("newProjectPrivacyBeforeLink", userData.language) + " "}
                                        <button
                                            className="bg-white border-0 btn-link p-0"
                                            onClick={() => setState({ ...state, privacyStatementModalShown: true })}
                                        >
                                            <span style={{"textDecoration" : "underline"} }>{translate("newProjectPrivacyLink", userData.language)}</span>
                                        </button>
                                        {translate("newProjectPrivacyAfterLink", userData.language)}
                                    </label>
                                </div>
                                <div className="form-check mt-3" align="left">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={state.customerPrivacyMarketing}
                                        onChange={(event) => setState({ ...state, customerPrivacyMarketing: event.target.checked })}
                                        id="chkMarketing"
                                    />
                                    <label className="form-check-label" htmlFor="chkMarketing">
                                        {translate("newProjectMarketingAccept", userData.language)}
                                    </label>
                                </div>
                            </div>
                            <div className={"d-flex flex-column-reverse flex-md-row pt-4 " + (state.checkProviderResult === "not-found" ? "justify-content-end" : "justify-content-between")}>
                                <button
                                    className="btn text-secondary"
                                    onClick={() => setState({ ...state, activeTab: 3 })}
                                >
                                    <FontAwesomeIcon className="me-1" icon={faArrowLeft} />
                                    {translate("newProjectBack", userData.language)}
                                </button>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => createProject()}
                                    disabled={state.createProjectDisabled}
                                >
                                    {translate("btnProjectSend", userData.language)}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"tab-pane fade " + (state.activeTab === 5 ? "show active" : "")} tabIndex="0">
                        <div className="axa-project-card">
                            <div className="axa-subcat-card-padding axa-card-shadow mt-4 bg-white">
                                <img id="img-success-check" className="mt-9" src={checkFill} alt="success" style={{ height: "64px" }} />
                                <div className="axa-service-card-title mt-3">{translate("newProjectConfirmationHeader", userData.language)}</div>
                                <div className="axa-confirmation-title mt-1">{translate("newProjectConfirmationSubHeader", userData.language)}</div>
                                <div className="axa-confirmation-text mt-4">{translate("newProjectConfirmationInformationText", userData.language)}</div>
                                <div className="axa-confirmation-text mt-3">{translate("newProjectConfirmationEmailText", userData.language)}</div>
                                <div className="axa-confirmation-email mt-2">{state.customerEmail}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <BootstrapModal
                shown={state.areYouSureWarningModalShown}
                size="modal-lg"
                onClose={() => setState({ ...state, areYouSureWarningModalShown: false })}
                body={
                    <>
                        <div className="d-flex gap-3 axa-warning-modal-padding">
                            <div align="left">
                                <img src={warningFill} alt="check-provider" />
                            </div>
                            <div className="flex-fill" align="left">
                                <div className="axa-success-box-header pt-1">
                                    {translate("newProjectServiceAreYouSureTitle", userData.language)}
                                </div>
                                <div className="axa-success-box-text py-2">
                                    {translate("newProjectServiceAreYouSureText", userData.language)}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-md-row-reverse flex-column mt-3 axa-warning-modal-padding">
                            <button className="btn btn-primary" onClick={() => rejectServiceChange()}>
                                {translate("newProjectServiceAreYouSureCancelButton", userData.language)}
                            </button>
                            <button className="btn" onClick={() => acceptServiceChange()}>
                                {translate("newProjectServiceAreYouSureApproveButton", userData.language)}
                            </button>
                        </div>
                    </>
                }
            />

            <BootstrapModal
                shown={state.privacyStatementModalShown}
                size="modal-xl"
                onClose={() => setState({ ...state, privacyStatementModalShown: false })}
                title={translate("privactPolicyHeader", userData.language)}
                body={
                    <div className="row axa-warning-modal-padding">
                        {userData.language === "NL" ? (<PrivacyPolicyNL />) : <PrivacyPolicyFR />}
                    </div>
                }
                footer={
                    <div className="row" align="center">
                        <div className="col">
                            <button className="btn btn-danger" onClick={() => setState({ ...state, privacyStatementModalShown: false })}>{translate("btnCloseModal", userData.language)}</button>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default ProjectSteps;