import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import handleResponse from '../../common/ResponseHandler';
import { useAuth0 } from '@auth0/auth0-react';

const WorkAreas = (props) => {
    const [postalAreasLoading, setPostalAreasLoading] = useState(true);
    const [workAreas, setWorkAreas] = useState({});
    const [expandedCities, setExpandedCities] = useState([])
    const { getAccessTokenSilently } = useAuth0();

    const onlyUnique = (value, index, array) => {
        return array.indexOf(value) === index;
    }

    const expandAccordion = (accordion) => {
        const index = expandedCities.findIndex(x => x === accordion);
        if (index > -1) {
            setExpandedCities(prevState => prevState.filter(x => x !== accordion));

        } else {
            setExpandedCities([...expandedCities, accordion]);
        }
    }
    
    const updateProviderAreaScore = async (event, workArea) => {
        setPostalAreasLoading(true);

        const existingRecord = workAreas.providerWorkAreas.filter(x => x.postalAreaId === workArea.id)[0];
        const newScore = parseInt(event.target.value);

        var providerWorkArea = {
            ProviderId: parseInt(props.providerId),
            PostalAreaId: workArea.id,
            Score: newScore,
            IsActive: (existingRecord ? existingRecord.isActive : false)
        };

        try {
            var token = await getAccessTokenSilently();
            const response = await axios.post("api/providerpostalarea/updateadd", providerWorkArea, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Provider Update Failed.")
            getPostalAreas();
        } catch (error) {
            toast.error("Provider Work Area Update Failed.", { toastId: "provider-work-area-update-failed" });
        }
        setPostalAreasLoading(false);

    }

    const updateProviderArea = async (event, workArea) => {
        setPostalAreasLoading(true);
        const existingRecord = workAreas.providerWorkAreas.filter(x => x.postalAreaId === workArea.id)[0];
        const newActivity = event.target.checked;

        var providerWorkArea = {
            ProviderId: parseInt(props.providerId),
            PostalAreaId: workArea.id,
            IsActive: newActivity,
            Score: existingRecord ? existingRecord.score : 10
        };
        
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.post("api/providerpostalarea/updateadd", providerWorkArea, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Provider Work Area Update Failed.")
            getPostalAreas();
        } catch (error) {
            toast.error("Provider Work Area Update Failed.", { toastId: "provider-work-area-update-failed" });
        }
        setPostalAreasLoading(false);
    }

    const selectCity = async (city) => {
        setPostalAreasLoading(true);
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get(`api/providerpostalarea/activatebycityproviderid?city=${city}&providerId=${props.providerId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Provider Work Area Update Failed.")
            getPostalAreas();
        } catch (error) {
            toast.error("Provider Work Area Update Failed.", { toastId: "provider-work-area-update-failed" });
        }
        setPostalAreasLoading(false);
    }

    const selectCityScore = async (city, score) => {
        setPostalAreasLoading(true);
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get(`api/providerpostalarea/updatescorebycityproviderid?city=${city}&providerId=${props.providerId}&score=${score}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Provider Work Area Update Failed.")
            getPostalAreas();
        } catch (error) {
            toast.error("Provider Work Area Update Failed.", { toastId: "provider-work-area-update-failed" });
        }
        setPostalAreasLoading(false);
    }

    const getPostalAreas = async () => {
        setPostalAreasLoading(true);
        try {
            var token = await getAccessTokenSilently();
            const postalAreaResponse = await axios.get("api/postalarea/getall", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            var token = await getAccessTokenSilently();
            const providerPostalAreaResponse = await axios.get("api/providerpostalarea/getbyproviderid?providerID=" + props.providerId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (!postalAreaResponse.data?.isError && !providerPostalAreaResponse.data?.isError) {
                let responeWorkAreas = {};
                responeWorkAreas.workAreas = postalAreaResponse.data.data;
                responeWorkAreas.cities = postalAreaResponse.data.data.map(k => k.city).filter(onlyUnique)
                responeWorkAreas.providerWorkAreas = providerPostalAreaResponse.data.data
                setWorkAreas(responeWorkAreas);
            }
        } catch (error) {
            toast.error("Work area list loading failed.", { toastId: "work-area-list-loading-failed" });
        }
        setPostalAreasLoading(false);
    };

    useEffect(() => {
        getPostalAreas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (postalAreasLoading && !workAreas) {
        return (
            <div className="row justify-content-center p-3">
                <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            </div>
        )
    }

    return (
        <div className={"accordion " + (postalAreasLoading ? "loading-skeleton" : "")} id="accordionPanelsStayOpenExample">
            {workAreas?.cities?.map((city) => {
                return (
                    <div key={city} className="accordion-item">
                        <div className="accordion-header p-2">
                            <div className="row">
                                <div className="col-auto me-auto">
                                    <button className="btn bnt-link me-1" onClick={() => expandAccordion(city)}>
                                        <FontAwesomeIcon icon={expandedCities.includes(city) ? faChevronRight : faChevronDown} />
                                    </button>
                                    <label>{city}</label>
                                </div>
                                <div className="col-auto">
                                    <button className="btn btn-sm btn-outline-primary ms-3" onClick={() => selectCity(city)}>Select All</button>
                                    <label className="ms-3 h-5" >Areal Priority:</label>
                                    <div className="btn-group ms-1" role="group" aria-label="Basic outlined example">
                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => selectCityScore(city, 10) }>10</button>
                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => selectCityScore(city, 9)}>9</button>
                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => selectCityScore(city, 8)}>8</button>
                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => selectCityScore(city, 7)}>7</button>
                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => selectCityScore(city, 6)}>6</button>
                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => selectCityScore(city, 5)}>5</button>
                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => selectCityScore(city, 4)}>4</button>
                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => selectCityScore(city, 3)}>3</button>
                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => selectCityScore(city, 2)}>2</button>
                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => selectCityScore(city, 1)}>1</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="panelsStayOpen-collapseOne" className={"accordion-collapse collapse " + (expandedCities.includes(city) ? "show" : "")}>
                            <div className="accordion-body">
                                <ul className="list-group" >
                                    {workAreas.workAreas.filter(x => x.city === city && expandedCities.includes(x.city)).map((workArea) => {
                                        let providerWorkArea = workAreas.providerWorkAreas.filter(x => x.postalAreaId === workArea.id)[0];
                                        return (
                                            <li key={workArea.postCode + "-" + workArea.cityPostalArea} className="list-group-item">
                                                <div className="row justify-content-between">
                                                    <div className="col-sm-6 col-4">
                                                        <div className="form-check pt-1">
                                                            <input className="form-check-input" type="checkbox" checked={providerWorkArea?.isActive} onChange={(evt) => updateProviderArea(evt, workArea)} />
                                                            <label className="form-check-label ms-4" htmlFor="flexCheckDefault">
                                                                {workArea.postCode} - {workArea.cityPostalArea}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
                                                        <div className="input-group" style={{ display: providerWorkArea?.isActive? "" : "none"}}>
                                                            <span className="input-group-text">Priority</span>
                                                            <select className="form-select" value={providerWorkArea ? providerWorkArea.score : 10} onChange={(event) => updateProviderAreaScore(event, workArea)}>
                                                                <option value={10}>10</option>
                                                                <option value={9}>9</option>
                                                                <option value={8}>8</option>
                                                                <option value={7}>7</option>
                                                                <option value={6}>6</option>
                                                                <option value={5}>5</option>
                                                                <option value={4}>4</option>
                                                                <option value={3}>3</option>
                                                                <option value={2}>2</option>
                                                                <option value={1}>1</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default WorkAreas;
