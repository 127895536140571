import { Link } from 'react-router-dom'

const CookiePolicyNL = () => {
    return (
        <div align="left">
            <div className="mb-4" align="center">
                <h2>COOKIEBELEID</h2>
            </div>
            <div className="axa-bg-viridian p-5 pb-3 mb-5 text-white" align="center">
                <p>Een cookie is een klein gegevensbestand (tekstbestand) dat een website, wanneer deze door een gebruiker wordt bezocht, aan uw browser vraagt om op uw apparaat op te slaan om informatie over u te bewaren, zoals uw taalvoorkeuren of inloggegevens. AXA Partners verzamelt en plaatst 'interne' cookies tijdens uw bezoek aan  “HomeCare.be”. </p>
            </div>
            <div className="mb-4">
                <h3>ACTUALISERING VAN HET HUIDIGE BELEID</h3>
                <p>AXA Partners kan dit cookiebeleid van tijd tot tijd bijwerken naar aanleiding van wijzigingen met betrekking tot cookies die tijdens het surfen op de website HomeCare.be worden gedropt. Dit privacybeleid is voor het laatst bijgewerkt op 04/12/2023.</p>
            </div>
            <div className="mb-4">
                <h3>WIE ZIJN DE VERANTWOORDELIJKEN VOOR DE VERWERKING VAN UW PERSOONSGEGEVENS?</h3>
                <p>INTER PARTNER ASSISTANCE NV, waarvan de maatschappelijke zetel gevestigd is te Régent 7, B-1000 Brussel, ingeschreven bij de Kruispuntbank van Ondernemingen onder het nummer 0415.591.055 treedt op als onafhankelijk verantwoordelijke voor de verwerking van uw persoonsgegevens (dit betekent dat hij het doel van en de middelen voor de verwerking van uw persoonsgegevens vaststelt).</p>
                <p>De onafhankelijke gegevensbeheerder wordt in dit beleid "AXA Partners" of "Gegevensbeheerder" of "wij" of "ons" genoemd.</p>
            </div>
            <div className="axa-bg-viridian p-5 pb-3 mb-5 text-white" align="left">
                <h3>WAT ZIJN UW RECHTEN MET BETREKKING TOT UW GEGEVENS?</h3>
                <p>
                    Het is mogelijk dat het plaatsen van deze cookies gepaard gaat met de verwerking van persoonsgegevens. In een dergelijk geval, en in overeenstemming met de RGPD, heb je rechten met betrekking tot deze cookies. Voor meer informatie over deze rechten verwijzen wij u naar de <Link to="/privacypolicy"> Privacybeleid</Link>.
                </p>
                <p className="mb-4">
                    Daarnaast heeft u het recht om uw toestemming voor het plaatsen van optionele cookies in te trekken ("opt-out"). U kunt uw voorkeuren op elk gewenst moment wijzigen voor alle of sommige cookies die worden geplaatst via het <Link to="/cookiesettings"> Cookiesvoorkeurcentrum</Link>.
                </p>
                <h3>HOE KUNT U CONTACT OPNEMEN MET DE DPO VOOR VERZOEKEN OF DE UITOEFENING VAN UW RECHTEN?</h3>
                <p>Als u vragen, klachten of opmerkingen hebt over deze informatiemededeling of over de uitoefening van uw bovengenoemde rechten, kunt u een e-mail sturen naar  dpo.bnl@axa-assistance.com. Uw verzoek zal dan worden doorgestuurd naar de bevoegde DPO. AXA Partners kan u om aanvullende informatie vragen om uw identiteit te bevestigen en/of om u te helpen de gegevens die u zoekt te lokaliseren.</p>
                <h3>HOE EEN KLACHT INDIENEN BIJ EEN TOEZICHTHOUDENDE AUTORITEIT?</h3>
                <p>
                    U hebt het recht uw bezorgdheid over de verwerking van uw persoonsgegevens kenbaar te maken bij een bevoegde toezichthoudende autoriteit in de lidstaat van uw gewone verblijfplaats, uw werkplek of de plaats waar volgens u een vermeende inbreuk op uw rechten heeft plaatsgevonden. De lijst van gegevensbeschermingsautoriteiten in de Europese Unie is beschikbaar via de volgende link: <Link to="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"> https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm</Link>
                    . Voor landen buiten de EU verwijzen wij u naar de website van uw lokale gegevensbeschermingsautoriteit.
                </p>
            </div>
            <div>
                <h3 className="mb-4">WAT ZIJN DE CATEGORIEËN VAN DE COOKIES DIE TIJDENS UW BEZOEK AAN ONZE WEBSITE WORDEN GEPLAATST EN WAT ZIJN HUN DOELEINDEN?</h3>
                <div className="row">
                    <div className="col-12 d-grid">
                        <table className="border border-info">
                            <thead className="bg-info text-white">
                                <tr>
                                    <th className="p-1 pt-2">
                                        <h3>FUNCTIONELE EN TECHNISCHE COOKIES (STRIKT NOODZAKELIJKE COOKIES)</h3>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="p-1 pt-2">
                                        <p>Deze cookies zijn noodzakelijk voor het functioneren van de website en kunnen in onze systemen niet worden uitgeschakeld. Ze worden meestal alleen ingesteld als reactie op handelingen die u verricht om diensten aan te vragen, zoals het instellen van uw privacyvoorkeuren, inloggen of het invullen van formulieren. U kunt uw browser instellen om deze cookies te blokkeren of u te waarschuwen voor deze cookies, maar sommige delen van de website zullen dan niet werken.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="border border-info mb-4">
                            <thead className="bg-info text-white">
                                <tr>
                                    <th className="p-1 pt-2">
                                        <h4>Subgroep koekjes</h4>
                                    </th>
                                    <th className="p-1 pt-2">
                                        <h4>Cookies</h4>
                                    </th>
                                    <th className="p-1 pt-2">
                                        <h4>Gebruikte cookies</h4>
                                    </th>
                                    <th className="p-1 pt-2">
                                        <h4>Levensduur</h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="p-1">
                                        Internal Cookie
                                    </td>
                                    <td className="p-1">
                                        Deze cookie wordt ingesteld door de cookie compliance-oplossing van OneTrust. Het slaat informatie op over de categorieën cookies die de site gebruikt en of bezoekers toestemming hebben gegeven of ingetrokken voor het gebruik van elke categorie. Hierdoor kunnen website-eigenaren voorkomen dat cookies in elke categorie worden ingesteld in de browser van de gebruiker als er geen toestemming is gegeven. De cookie heeft een normale levensduur van een jaar, zodat terugkerende bezoekers van de site hun voorkeuren onthouden. Het bevat geen informatie waarmee de bezoeker van de site kan worden geïdentificeerd.
                                    </td>
                                    <td className="p-1">
                                        OptanonConsent
                                    </td>
                                    <td className="p-1">
                                        179 Days
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-1">
                                        Internal Cookie
                                    </td>
                                    <td className="p-1">
                                        Deze cookie wordt ingesteld door websites die bepaalde versies van de cookie law compliance-oplossing van OneTrust gebruiken.  Hij wordt ingesteld nadat bezoekers een cookie-informatiebericht hebben gezien en in sommige gevallen alleen wanneer ze het bericht actief sluiten.  Het stelt de website in staat om de melding niet meer dan één keer aan een gebruiker te tonen.  De cookie bevat geen persoonlijke informatie.
                                    </td>
                                    <td className="p-1">
                                        OptanonAlertBoxClosed
                                    </td>
                                    <td className="p-1">
                                        179 Days
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-1">

                                    </td>
                                    <td className="p-1">
                                        Deze cookie wordt ingesteld door websites die bepaalde versies van de cookie law compliance-oplossing van OneTrust gebruiken.  Hij wordt ingesteld nadat bezoekers een cookie-informatiebericht hebben gezien en in sommige gevallen alleen wanneer ze het bericht actief sluiten.  Het stelt de website in staat om de melding niet meer dan één keer aan een gebruiker te tonen.  De cookie bevat geen persoonlijke informatie.
                                    </td>
                                    <td className="p-1">

                                    </td>
                                    <td className="p-1">

                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-1">
                                        Internal
                                    </td>
                                    <td className="p-1">
                                        Deze cookie wordt ingesteld door websites die draaien op het Windows Azure-platform. Hij wordt gebruikt voor load balancing om ervoor te zorgen dat de pagina-aanvragen van bezoekers tijdens elke browsersessie naar dezelfde server worden gerouteerd.
                                    </td>
                                    <td className="p-1">
                                        ARRAffinity
                                    </td>
                                    <td className="p-1">
                                        Session Cookie
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="border border-info">
                            <thead className="bg-info text-white">
                                <tr>
                                    <th className="p-1 pt-2">
                                        <h3>COOKIES VOOR PUBLIEKSMETING</h3>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="p-1 pt-2">
                                        <p>Met deze cookies kunnen wij bezoeken en verkeersbronnen tellen, zodat wij de prestaties van onze website kunnen meten en verbeteren. Ze stellen ons in staat te weten welke pagina's het meest en het minst populair zijn en hoe bezoekers zich door de website bewegen. Alle informatie die deze cookies verzamelen is geaggregeerd. Als u deze cookies niet toestaat, weten wij niet wanneer u onze website hebt bezocht en kunnen wij de prestaties ervan niet controleren.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="border border-info mb-4">
                            <thead className="bg-info text-white">
                                <tr>
                                    <th className="p-1 pt-2">
                                        <h4>Subgroep koekjes</h4>
                                    </th>
                                    <th className="p-1 pt-2">
                                        <h4>Cookies</h4>
                                    </th>
                                    <th className="p-1 pt-2">
                                        <h4>Gebruikte cookies</h4>
                                    </th>
                                    <th className="p-1 pt-2">
                                        <h4>Levensduur</h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="p-1">
                                        Internal Cookie
                                    </td>
                                    <td className="p-1">
                                        De belangrijkste cookie die door Google Analytics wordt gebruikt, stelt een dienst in staat om de ene bezoeker van de andere te onderscheiden.
                                    </td>
                                    <td className="p-1">
                                        _ga_xxxxxxx
                                    </td>
                                    <td className="p-1">
                                        365 days
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-1">
                                        Internal Cookie
                                    </td>
                                    <td className="p-1">
                                        Deze cookienaam wordt geassocieerd met Google Universal Analytics - wat een belangrijke update is van de meer algemeen gebruikte analyticsdienst van Google. Deze cookie wordt gebruikt om unieke gebruikers te onderscheiden door een willekeurig gegenereerd nummer toe te wijzen als klantidentificatie. Het wordt opgenomen in elk paginaverzoek op een site en gebruikt om bezoekers-, sessie- en campagnegegevens te berekenen voor de analyserapporten van de site.  Standaard is deze ingesteld om na 2 jaar te verlopen, hoewel dit door website-eigenaren kan worden aangepast.
                                    </td>
                                    <td className="p-1">
                                        _ga
                                    </td>
                                    <td className="p-1">
                                        365 Days
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-1">

                                    </td>
                                    <td className="p-1">
                                        Deze cookienaam wordt geassocieerd met Google Universal Analytics - wat een belangrijke update is van de meer algemeen gebruikte analyticsdienst van Google. Deze cookie wordt gebruikt om unieke gebruikers te onderscheiden door een willekeurig gegenereerd nummer toe te wijzen als klantidentificatie. Het wordt opgenomen in elk paginaverzoek op een site en gebruikt om bezoekers-, sessie- en campagnegegevens te berekenen voor de analyserapporten van de site.  Standaard is deze ingesteld om na 2 jaar te verlopen, hoewel dit door website-eigenaren kan worden aangepast.
                                    </td>
                                    <td className="p-1">

                                    </td>
                                    <td className="p-1">

                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-1">
                                        Internal
                                    </td>
                                    <td className="p-1">
                                        Deze cookie wordt ingesteld door websites die draaien op het Windows Azure cloudplatform. Hij wordt gebruikt voor load balancing om ervoor te zorgen dat de pagina-aanvragen van bezoekers in elke browsersessie naar dezelfde server worden gerouteerd.
                                    </td>
                                    <td className="p-1">
                                        ARRAffinitySameSite
                                    </td>
                                    <td className="p-1">
                                        Session Cookie
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div>
                <h3>HOE COOKIES ACCEPTEREN OF WEIGEREN?</h3>
                <p>Een cookiebanner verschijnt vanaf uw eerste bezoek aan de website HomeCare.be. Deze cookiebanner stelt u in staat om</p>
                <ul>
                    <li>
                        <b>alle optionele cookies accepteren </b>
                        die niet strikt noodzakelijk zijn voor de goede werking van de website, of
                    </li>
                    <li>
                        <b>Weiger alle cookies </b>
                        die niet strikt noodzakelijk zijn voor de goede werking van de website,
                    </li>
                    <li>
                        <b>Ga naar het Cookie Preference Center </b>
                        om
                        <b> uw keuzes </b>
                        in termen van toestemming in te stellen (wat betekent dat u individueel cookies kunt selecteren die u tijdens het browsen accepteert, ook al zijn ze niet essentieel voor de goede werking van de website).
                    </li>
                </ul>
                <p>
                    Wij bewaren uw voorkeuren, zowel toestemming als weigering van cookies, gedurende [6] maanden. Zodra deze periode is verstreken, verschijnt de cookiebanner zodat u uw voorkeuren opnieuw kunt instellen. U kunt de cookie-instellingen te allen tijde aanpassen via het
                    <Link to="/cookiesettings"> Cookie preference center</Link>
                    .
                </p>
            </div>
            <div>
                <h3>IS COOKIE DEPOSIT VERPLICHT?</h3>
                <p>
                    Het strikt noodzakelijke of optionele karakter van elke categorie cookies wordt aangegeven op het moment dat u wordt gevraagd toestemming te verlenen (of te weigeren), hetzij via de cookiebanner bij uw eerste bezoek aan onze website, hetzij via het
                    <Link to="/cookiesettings"> Cookie preference center</Link>
                    .
                </p>
                <p>
                    De
                    <Link to="/cookiesettings"> Cookie Preference Center </Link>
                    blijft te allen tijde toegankelijk in de footer van de website maar ook door te klikken op 'Personaliseer mijn keuzes' in de cookiebanner.
                </p>
                <p>Houd er rekening mee dat door het weigeren van optionele cookies sommige functies, pagina's en gebieden van de website onbeschikbaar kunnen worden, waarvoor wij niet verantwoordelijk kunnen worden gesteld.</p>
            </div>
        </div>
    );
};

export default CookiePolicyNL;