import { useContext } from "react"
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from '../../auth/auth0-provider-with-history';


const Auth0LogoutButton = () => {
    const { logout } = useAuth0();
    const { userData, setUserData } = useContext(UserContext);
    const userLogout = () => {
        logout({ returnTo: window.location.origin + "/login" });
        setUserData({
            ...userData,
            loginType: "",
            providerId: 0
        })
    }
    
    return (
        <button
            className="btn btn-danger btn-block"
            onClick={() => userLogout()}
        >
            Log Out
        </button>
    );
};

export default Auth0LogoutButton;