import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { Auth0ProviderWithHistory } from './auth/auth0-provider-with-history';

import TagManager from 'react-gtm-module'


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_PUBLIC_GTM_ID
}
TagManager.initialize(tagManagerArgs)


root.render(
    <BrowserRouter basename={baseUrl}>
        <Auth0ProviderWithHistory>
            <App />
        </Auth0ProviderWithHistory >
    </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
