import Home from "./components/home/Home";
import Login from "./components/login/Login";
import NewProvider from "./components/network/NewProvider";
import ProviderList from "./components/network/ProviderList";
import EditProvider from "./components/network/EditProvider";
import HomeProjectList from "./components/provider/HomeProjectList";
import LandingPage from "./components/login/LandingPage";
import Error404 from "./components/error/Error404";
import Service from "./components/home/Service";
import NewProject from "./components/home/NewProject";
import CookieSettings from "./components/home/CookieSettings";
import HomeProjectDetailsProvider from "./components/provider/HomeProjectDetailsProvider";
import HomeProjectDetailsNetwork from "./components/network/HomeProjectDetailsNetwork";
import HomeProjectListNetwork from "./components/network/HomeProjectList";
import PrivacyPolicy from "./components/home/PrivacyPolicy";
import CookiePolicy from "./components/home/CookiePolicy";

const AppRoutes = [
    {
        path: "/",
        index: true,
        element: <Home />,
        allow: []
    },
    {
        path: "/:activecategory",
        index: true,
        element: <Home />,
        allow: []
    },
    {
        path: "*",
        index: true,
        element: <Error404 />,
        allow: []
    },
    {
        path: "404",
        index: false,
        element: <Error404 />,
        allow: []
    },
    {
        path: "login",
        index: false,
        element: <Login />,
        allow: []
    },
    {
        path: "login/landingpage",
        index: false,
        element: <LandingPage />,
        allow:[]
    },
    {
        path: "network/newprovider",
        index: false,
        element: <NewProvider />,
        allow: ["network"]
    },
    {
        path: "network/providerlist",
        index: false,
        element: <ProviderList />,
        allow: ["network"]
    },
    {
        path: "network",
        index: false,
        element: <HomeProjectListNetwork />,
        allow: ["network"]
    },
    {
        path: "network/editprovider/:id",
        index: false,
        element: <EditProvider />,
        allow: []
    },
    {
        path: "network/homeprojectlist/:providerId",
        index: false,
        element: <HomeProjectListNetwork />,
        allow: ["network"]
    },
    {
        path: "network/homeprojectlist",
        index: false,
        element: <HomeProjectListNetwork />,
        allow: ["network"]
    },
    {
        path: "network/homeprojectdetails/:id",
        index: false,
        element: <HomeProjectDetailsNetwork />,
        allow: ["network"]
    },
    {
        path: "provider",
        index: false,
        element: <HomeProjectList />,
        allow: ["provider"]
    },
    {
        path: "provider/homeprojectlist",
        index: false,
        element: <HomeProjectList />,
        allow: ["provider"]
    },
    {
        path: "provider/homeprojectdetails/:id",
        index: false,
        element: <HomeProjectDetailsProvider />,
        allow: ["provider"]
    },
    
    {
        path: "service/:id",
        index: false,
        element: <Service />,
        allow: []
    },
    {
        path: "newproject/:subcatid/:serviceid",
        index: false,
        element: <NewProject />,
        allow: []
    },
    {
        path: "newproject/:subcatid",
        index: false,
        element: <NewProject />,
        allow: []
    },
    {
        path: "newproject",
        index: false,
        element: <NewProject />,
        allow: []
    },
    {
        path: "cookiesettings",
        index: false,
        element: <CookieSettings />,
        allow: []
    },
    {
        path: "privacypolicy",
        index: false,
        element: <PrivacyPolicy />,
        allow: []
    },
    {
        path: "cookiepolicy",
        index: false,
        element: <CookiePolicy />,
        allow: []
    },
];

export default AppRoutes;
