import { useState, useEffect } from 'react';

const Accordion = (props) => {
    const [state, setState] = useState({
        activeIndex: -1,
        id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
    });

    const focusOnActive = () => {
        if (state.activeIndex !== -1) {
            var element = document.getElementById(state.id + "-accoirdionBodyItem-" + state.activeIndex);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }

    useEffect(() => {
        if (props.initialIndex === 0 || props.initialIndex) {
            setState({ ...state, activeIndex: props.initialIndex })
        }
        focusOnActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialIndex, state.activeIndex]);

    return (
        <div className={"accordion " + props.className}>
            {props.items?.map((item, index) => {
                return (
                    <div key={"accordion-" + index} className={"accordion-item " + (props.borderDisabled ? " " : "border-bottom-axa-accordion ") + props.itemClassName} >
                        <div className={"accordion-header " + props.headerClassName} onClick={() => setState({ ...state, activeIndex: (state.activeIndex === index ? -1 : index) })} >
                            <button className={"btn accordion-button " + (props.buttonClassName) + (state.activeIndex === index ? " axa-accordion-header-bg" : " bg-grey-100 collapsed")}>
                                {item.header}
                            </button>
                        </div>
                        <div className={"accordion-collapse " + (state.activeIndex === index ? "" : "collapse")} >
                            <div id={state.id + "-accoirdionBodyItem-" + index} className={"accordion-body " + (props.bodyClassName ? props.bodyClassName : "")}>
                                {item.body}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default Accordion;

