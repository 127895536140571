import { useContext } from 'react';
import axaCloseIcon from './../../img/axa_close.svg';
import translate from './Dictionary';
import { UserContext } from '../../auth/auth0-provider-with-history';


const BootstrapModal = (props) => {
    const { userData, setUserData } = useContext(UserContext);
    return (
        <>
            <div className={"modal fade " + (props.shown ? " show" : "")} tabIndex="-1" style={{ display: props.shown ? "block" : "none" }}>
                <div className={"modal-dialog modal-dialog-centered " + props.size}>
                    <div className="modal-content">
                        <div className="modal-header bg-grey-200 p-1">
                            <h5 className="modal-title">{props.title}</h5>
                            <button className="axa-close-offcanvas-button" onClick={props.onClose}>
                                <label className="axa-close-offcanvas-text me-2">{translate("btnCloseModal", userData.language)}</label>
                                <img src={axaCloseIcon} alt="axa-close-btn" style={{ width: "24px", height: "24px" }} />
                            </button>
                        </div>
                        <div className="modal-body">
                            {props.body}
                        </div>
                        <div className="modal-footer" style={{ display: props.footer ? "block" : "none" }}>
                            {props.footer}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" style={{ display: props.shown ? "block" : "none" }}></div>
        </>
    );
};

export default BootstrapModal;
