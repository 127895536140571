import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import DataTable from 'react-data-table-component';
import customStyles from '../common/CustomDataTableStyle';
import handleResponse from '../common/ResponseHandler'
import TableLoadingPlaceholder from '../common/TableLoadingPlaceholder'

import { useAuth0 } from '@auth0/auth0-react';


const HomeProjectList = () => {
    const [state, setState] = useState({
        homeProjectColumns: [
            {
                name: "Project ID",
                selector: row => (
                    <>
                        <a className="btn btn-link p-2" href={"provider/homeprojectdetails/" + row.id}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} className="me-1" />
                        </a>
                        #{row.id}
                    </>
                ),

            },
            {
                name: "Customer Name",
                selector: row => <>{row.customerName} {row.customerLastName}</>,
                wrap: true
            },
            {
                name: "Customer Address",
                selector: row => <>
                    <>{row.customerStreetHouseNumber}</>
                    <br/>
                    <>{row.postalArea.postCode} {row.postalArea.city}</>
                </>,
            },
            {
                name: "Service",
                selector: row => <>{row.service?.name}</>,
                wrap: true
            },
            {
                name: "Status",
                selector: row => <>{row.projectStatus?.name}</>,
                wrap: true
            },
        ],
        tableLoading: true,
        homeProjectData: []
    });

    const { getAccessTokenSilently } = useAuth0();

    const getHomeProjects = async provider => {
        try {
            const token = await getAccessTokenSilently();
            const response = await axios.get(`api/homeproject/getbyproviderid?providerid=${localStorage.getItem("providerId")}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            handleResponse(response, "Project retrieval failed.", true)
            if (!response.data.isError) {
                setState({ ...state, tableLoading: false, homeProjectData: response.data.data })
            }
        } catch (error) {
            toast.error("Project retrieval failed.");
        }
    };

    useEffect(() => {
        getHomeProjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-subtitle text-secondary">Project List</h2>
                            <hr className="hr-fade-20 m-0 mb-1" />
                            <div className="row">
                                <div className="col-12">
                                    <DataTable
                                        customStyles={customStyles}
                                        data={state.homeProjectData}
                                        columns={state.homeProjectColumns}
                                        progressPending={state.tableLoading}
                                        progressComponent={<TableLoadingPlaceholder columnCount={6} rowCount={8} />}
                                        pagination
                                        striped
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeProjectList;