import React from 'react';

const ProviderInfo = (props) => {
    return (
        <div className={"row " + (props.loading ? "loading-skeleton" : "")}>
            <div className="col-sm-12 col-md-6">
                <div className="mb-3">
                    <div className="col-12">
                        <label className="form-label">Provider Name</label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Provider Name"
                            onChange={props.handleTextInputChange}
                            value={props.name}
                        />
                    </div>

                </div>
                <div className="mb-3">
                    <label className="form-label">Provider Address</label>
                    <textarea
                        className="form-control"
                        name="address"
                        placeholder="Provider Address"
                        style={{ height: "130px" }}
                        onChange={props.handleTextInputChange}
                        value={props.address}
                    />
                </div>
            </div>
            <div className="col-sm-12 col-md-6">
                <div className="mb-3">
                    <label className="form-label">Contact Name</label>
                    <input
                        type="text"
                        className="form-control"
                        name="contactName"
                        placeholder="Contact Name"
                        onChange={props.handleTextInputChange}
                        value={props.contactName}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Contact Phone Number</label>
                    <input
                        type="text"
                        className="form-control"
                        name="contactPhoneNumber"
                        placeholder="Contact Phone Number"
                        onChange={props.handleTextInputChange}
                        value={props.contactPhoneNumber ? props.contactPhoneNumber : "+32"}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Contact E-mail</label>
                    <input
                        type="text"
                        className="form-control"
                        name="contactEmail"
                        placeholder="Contact E-mail"
                        onChange={props.handleTextInputChange}
                        value={props.contactEmail}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProviderInfo;
