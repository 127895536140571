import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from '../../auth/auth0-provider-with-history';

const LandingPage = (props) => {
    const navigate = useNavigate();
    const { isLoading, user, getAccessTokenSilently } = useAuth0();
    const { userData, setUserData } = useContext(UserContext);


    useEffect(() => {
        if (!user && !isLoading) {
            navigate('/login');
        }
        if (user) {
            getAccessTokenSilently().then((token) => {
                axios.get("api/loginuser/login", {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    if (response.data.isError) {
                        if (response.data?.data?.authorizationType === 2) {
                            localStorage.removeItem('loginType');
                            localStorage.removeItem("providerId");
                            setUserData({
                                ...userData,
                                loginType: "not-accepted"
                            })
                        }
                        navigate('/login');
                        return;
                    }
                    if (!response.data.data) {
                        navigate('/login');
                        return;
                    }
                    if (response.data.data.authorizationType === 0) {
                        localStorage.setItem('loginType', "network");
                        localStorage.setItem('providerId', 0);
                        setUserData({
                            ...userData,
                            loginType: "network"
                        })
                        navigate('/network');
                    } else if (response.data.data.authorizationType === 1) {
                        localStorage.setItem('loginType', "provider");
                        localStorage.setItem('providerId', response.data.data.providerId);
                        setUserData({
                            ...userData,
                            loginType: "provider"
                        })
                        navigate('/provider');
                    }
                    else {
                        localStorage.removeItem('loginType');
                        localStorage.removeItem("providerId");
                        setUserData({
                            ...userData,
                            loginType: "not-accepted"
                        })
                        navigate('/login');
                    }
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <div className="row mt-5" align="center">
            <div className="col-12">
                <h1 className="mb-4">Loading User Data</h1>
                <FontAwesomeIcon icon={faSpinner} spin size="4x" />
            </div>
        </div>
    );
};

export default LandingPage;
