import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';

import DataTable from 'react-data-table-component';
import customStyles from '../common/CustomDataTableStyle';
import handleResponse from '../common/ResponseHandler'
import TableLoadingPlaceholder from '../common/TableLoadingPlaceholder'

const ProviderList = () => {

    const [providerListColumns] = useState([
        {
            name: "Provider ID",
            selector: row => (
                <a className="btn btn-link" href={"network/editprovider/" + row.id}>
                    <FontAwesomeIcon icon={faPenToSquare} className="me-1" />
                    <span>{row.id}</span>
                </a>
            ),
        },
        {
            name: "Provider",
            selector: row => <label>{row.name}</label>,
        },
        {
            name: "Is Active",
            selector: row => (
                <div className="form-check form-switch ">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={"providerActive-" + row.id}
                        defaultChecked={row.isActive}
                        onChange={() => updateProviderActivity(row)}
                    />
                </div>
            ),
        },
        {
            name: "Projects",
            selector: row => <a className="btn btn-link p-0" href={`network/homeprojectlist/${row.id}` }>Projects({row.homeProjects.length})</a>,
        },
    ]);

    const [providerList, setProviderList] = useState([]);
    const [searchFilter, setSearchFilter] = useState("");
    const [listLoading, setListLoading] = useState(true);

    const { getAccessTokenSilently } = useAuth0();

    const handleTextInputChange = evt => {
        setSearchFilter(evt.target.value);
    };

    const handleKeyDownSearch = evt => {
        if (evt.key === 'Enter') {
            getProviders();
        }
    };

    const filterSearchArray = searchData => {
        if (searchFilter) {
            searchData = searchData.filter(item => {
                return (
                    item.name.toUpperCase().includes(searchFilter.toUpperCase()) ||
                    item.id === searchFilter
                );
            });
        }
        return searchData;
    };

    const getProviders = async () => {
        setListLoading(true);
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get("api/provider/getall", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setListLoading(false);
            if (response.status === 200) {
                if (response.data.isError) {
                    toast.error(response.data.userMessage, { toastId: "provider-list-loading-failed" });
                } else {
                    let filteredData = filterSearchArray(response.data.data);
                    setProviderList(filteredData);
                    if (filteredData.length > 0) {
                        toast.success(response.data.userMessage, { toastId: "provider-list-loaded" });
                    } else {
                        toast.warning("No Matching Providers Found.", { toastId: "no-matching-providers-found" });
                    }
                }
            } else {
                toast.error("Provider List Loading Failed.", { toastId: "provider-list-loading-failed" });
            }
        } catch (error) {
            toast.error("Provider List Loading Failed.", { toastId: "provider-list-loading-failed" });
        }
    };

    const updateProviderActivity = async provider => {
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get(`api/provider/updateprovideractivity?id=${provider.id}&isActive=${!provider.isActive}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Activity Update Failed.", false)
        } catch (error) {
            toast.error("Activity Update Failed For " + provider.name, { toastId: "activity-update-failed-" + provider.id });
        }
    };

    useEffect(() => {
        getProviders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-subtitle text-secondary">Provider List</h2>
                            <hr className="hr-fade-20 m-0 mb-1" />
                            <div className="row">
                                <div className="col-lg-6 col-sm-12 mb-1">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="searchFilter"
                                            placeholder="Search..."
                                            onChange={handleTextInputChange}
                                            onKeyDown={handleKeyDownSearch}
                                        />
                                        <button className="btn btn-outline-primary" type="button" onClick={getProviders}>
                                            <FontAwesomeIcon icon={faSearch} />
                                            <span className="ms-1">Search</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-3 offset-lg-3 col-sm-12 mb-1 d-grid">
                                    <a className="btn btn-primary" type="button" href="network/newprovider">
                                        <FontAwesomeIcon icon={faPlus} />
                                        <span className="ms-1">New Provider</span>
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <DataTable
                                        customStyles={customStyles}
                                        data={providerList}
                                        columns={providerListColumns}
                                        progressPending={listLoading}
                                        progressComponent={<TableLoadingPlaceholder columnCount={4} rowCount={8} />}
                                        pagination
                                        striped
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProviderList;