import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Accordion from './Accordion';
import { UserContext } from '../../auth/auth0-provider-with-history';
import translate from './Dictionary';


const Footer = (props) => {
    const { userData } = useContext(UserContext);

    return (
        <>
            <footer className="justify-content-between align-items-center py-3 axa-footer mt-11 axa-desktop-footer">
                <div className="d-flex flex-wrap">
                    <div className="col-md-2 col-sm-12 d-flex align-items-center">
                        <span className="axa-footer-title">AXA Belgium</span>
                    </div>
                    <ul className="nav col-md-10 justify-content-end list-unstyled">
                        <li className="px-1">
                            <Link className="axa-footer-link" to="/privacypolicy">
                                {translate("footPrivacyPolicy", userData?.language)}
                            </Link>
                        </li>
                        <li>
                            <span className="axa-footer-link">|</span>
                        </li>
                        <li className="px-1">
                            <Link className="axa-footer-link" to="/cookiepolicy">
                                {translate("footCookiePolicy", userData?.language)}
                            </Link>
                        </li>
                        <li>
                            <span className="axa-footer-link">|</span>
                        </li>
                        <li className="px-1">
                            <Link className="axa-footer-link" to="/cookiesettings">
                                {translate("footCookieSettings", userData?.language)}
                            </Link>
                        </li>
                        <li>
                            <span className="axa-footer-link">|</span>
                        </li>
                        <li className="px-1">
                            <span className="axa-footer-link">
                                ©{(new Date().getFullYear())} AXA {translate("footRightsReserved", userData?.language)}
                            </span>
                        </li>
                    </ul>
                </div>
            </footer>
            <footer className="mt-11 axa-mobile-footer axa-footer">
                <div className="px-4 py-3 axa-mobile-footer-item-border">
                    <span className="axa-footer-title">AXA Belgium</span>
                </div>
                <div className="axa-mobile-footer-item-border">
                    <Accordion
                        itemClassName="axa-mobile-footer-accordion"
                        headerClassName="axa-mobile-footer-accordion"
                        buttonClassName="axa-mobile-footer-accordion py-3 px-4"
                        borderDisabled={true}
                        items={[
                            {
                                header:
                                    <span className="axa-footer-link">
                                        Links
                                    </span>,
                                body: <ul className="list-group list-group-flush">
                                    <li className="list-group-item axa-mobile-footer-subitem py-3 axa-mobile-footer-item-border">
                                        <Link className="axa-footer-link" to="/privacypolicy">
                                            {translate("footPrivacyPolicy", userData?.language)}
                                        </Link>
                                    </li>
                                    <li className="list-group-item axa-mobile-footer-subitem py-3 axa-mobile-footer-item-border">
                                        <Link className="axa-footer-link" to="/cookiepolicy">
                                            {translate("footCookiePolicy", userData?.language)}
                                        </Link>
                                    </li>
                                    <li className="list-group-item axa-mobile-footer-subitem py-3 axa-mobile-footer-item-border">
                                        <Link className="axa-footer-link" to="/cookiesettings">
                                            {translate("footCookieSettings", userData?.language)}
                                        </Link>
                                    </li>

                                    <li className="list-group-item axa-mobile-footer-subitem py-3 axa-mobile-footer-item-border">
                                        <span className="axa-footer-link">
                                            ©{(new Date().getFullYear())} AXA {translate("footRightsReserved", userData?.language)}
                                        </span>
                                    </li>
                                </ul>,
                                bodyClassName: "axa-mobile-footer-subitem p-0"
                            }
                        ]} />
                </div>
                <div className="py-3 axa-center-text axa-bg-footer">
                    <Link className="axa-footer-link" to="#">
                        ©{(new Date().getFullYear())} 2023 AXA All rights Reserved
                    </Link>
                </div>
            </footer>
        </>
    );
}

export default Footer;
