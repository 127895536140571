import { useState, useEffect } from 'react';
import axios from 'axios';
import handleResponse from '../../common/ResponseHandler';
import { useAuth0 } from '@auth0/auth0-react';


const Services = (props) => {
    const [state, setState] = useState({
        services: [],
        providerServices: []
    });
    const { getAccessTokenSilently } = useAuth0();

    const getServices = async () => {
        var token = await getAccessTokenSilently();
        const serviceResponse = await axios.get("api/servicecategory/getallactive", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const serviceProviderResponse = await axios.get(`api/serviceprovider/getactivebyproviderid?providerId=${props.providerId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        var newState = {
            providerServices: serviceProviderResponse.data.data,
            services: serviceResponse.data.data
        }
        setState(newState);
    }

    const changeServicesActivity = async (evt, serviceId) => {
        var token = await getAccessTokenSilently();
        const response = await axios.get(`api/serviceprovider/updateadd?providerId=${props.providerId}&serviceId=${serviceId}&isActivce=${evt.target.checked}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        handleResponse(response, "Provider Service Update Failed.");
        getServices();
    }

    useEffect(() => {
        getServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={"row " + (props.loading ? "loading-skeleton" : "")}>
            <div className="col-12">
                <ul className="list-group">
                    {state.services?.map(serviceCategory => {
                        return (
                            <li key={"category-" + serviceCategory.id} className="list-group-item text-primary">
                                {/*<input className="form-check-input me-1" type="checkbox" />*/}
                                <label>{serviceCategory.name}</label>
                                <hr/>
                                <ul className="list-group">
                                    {serviceCategory.serviceSubCategories?.map((subCategory) => {
                                        return (
                                            <li key={"subCategory-" + subCategory.id} className="list-group-item text-secondary" >
                                                {/*<input className="form-check-input me-1" type="checkbox"/>*/}
                                                <label>{subCategory.name}</label>
                                                <hr />
                                                <ul className="list-group">
                                                    {subCategory.services?.map((service) => {
                                                        return (
                                                            <li key={"service-" + service.id} className="list-group-item" >
                                                                <input
                                                                    className="form-check-input me-1"
                                                                    type="checkbox"
                                                                    defaultChecked={state.providerServices?.some(x => x.serviceId === service.id)}
                                                                    onChange={(evt) => changeServicesActivity(evt, service.id)}
                                                                />
                                                                <label>{service.name}</label>
                                                            </li>
                                                        );
                                                    }) }
                                                </ul>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Services;
