import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../auth/auth0-provider-with-history';
import { useAuth0 } from '@auth0/auth0-react';

const LocalStorageChecker = () => {
    const navigate = useNavigate();
    const { userData, setUserData } = useContext(UserContext);
    const { isAuthenticated, logout } = useAuth0();

    useEffect(() => {
        if (userData.loginType === "") {
            localStorage.removeItem('loginType');
            localStorage.removeItem('providerId');
        }
        
        if (!localStorage.getItem("loginType") && isAuthenticated) {
            navigate("/login/landingpage");
        }
        if (userData.loginType === "not-accepted") {
            logout();
        }
           
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);

    return null; // This component doesn't render anything
};

export default LocalStorageChecker;