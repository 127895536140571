import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';

import handleResponse from '../../common/ResponseHandler';

const NewLoginUser = (props) => {
    const [state, setState] = useState({
        saveLoading: false,
        newLoginEmail: "",
        newLoginGivenName: "",
        newLoginLastName: ""
    });
    const { getAccessTokenSilently } = useAuth0();

    const handleTextInputChange = (evt) => {
        setState({
            ...state,
            [evt.target.name]: evt.target.value
        });
    };

    const saveLoginUser = async () => {
        setState({ ...state, saveLoading: true });

        if (!state.newLoginGivenName) {
            toast.warning("Given Name is required", { toastId: "given-name-required" });
            setState({ ...state, saveLoading: false });
            return;
        }

        if (!state.newLoginLastName) {
            toast.warning("Last Name is required", { toastId: "last-name-required" });
            setState({ ...state, saveLoading: false });
            return;
        }

        if (!state.newLoginEmail) {
            toast.warning("Login Email Required", { toastId: "login-email-required" });
            setState({ ...state, saveLoading: false });
            return;
        }

        var newLoginUserRequest = {
            GivenName: state.newLoginGivenName,
            LastName: state.newLoginLastName,
            Email: state.newLoginEmail,
            ProviderId: props.providerId
        }
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.post("api/loginuser/addloginuser", newLoginUserRequest, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Login creation failed.")
            setState({
                newLoginGivenName: response.data?.isError ? state.newLoginGivenName : "",
                newLoginLastName: response.data?.isError ? state.newLoginLastName : "",
                newLoginEmail: response.data?.isError ? state.newLoginEmail : "",
                saveLoading: false
            });
            if (!response.data?.isError) {
                props.onSave();
            }
        } catch (error) {
            toast.error("Login Creation Failed", { toastId: "login-creation-failed" });
            setState({ ...state, saveLoading: false });
        }
    };

    return (
        <>
            <div className="row mb-1">
                <div className="col-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text">Given Name</span>
                        <input type="text"
                            className="form-control"
                            name="newLoginGivenName"
                            onChange={handleTextInputChange}
                            value={state.newLoginGivenName}
                        />
                    </div>                    
                </div>
                <div className="col-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text">Last Name</span>
                        <input type="text"
                            className="form-control"
                            name="newLoginLastName"
                            onChange={handleTextInputChange}
                            value={state.newLoginLastName}
                        />
                    </div>                    
                </div>
                <div className="col-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text">Login E-mail</span>
                        <input type="text"
                            className="form-control"
                            name="newLoginEmail"
                            onChange={handleTextInputChange}
                            value={state.newLoginEmail}
                        />
                    </div>                    
                </div>
            </div>
            <div className="row">
                <div className="col-12 d-flex justify-content-end">
                    <button className="btn btn-primary" disabled={state.saveLoading} onClick={saveLoginUser}>Save New Login</button>
                </div>
            </div>
        </>
    );
};

export default NewLoginUser;
