import { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Accordion from '../common/Accordion';
import axios from 'axios';
import translate from '../common/Dictionary';
import { UserContext } from '../../auth/auth0-provider-with-history';
import BootsrapOffcanvas from '../common/BootsrapOffcanvas';

//images-hero
import heroHeatingMaintenance from './../../img/subcategory-hero/heating-cooling/heating-maintenance.jpg'
import heroHeatingInstallationRepairAndBreakdown from './../../img/subcategory-hero/heating-cooling/heating-installation-repair-and-breakdown.jpg'
import heroRadiatorHeatPumps from './../../img/subcategory-hero/heating-cooling/radiators-heat-pumps.jpg'
import heroAirconditioning from './../../img/subcategory-hero/heating-cooling/airconditioning.jpg'
import heroSewagePipesSanitairy from './../../img/subcategory-hero/pluming-pipes/sewage-pipes-sanitairy.jpg'
import heroMoistureLeak from './../../img/subcategory-hero/pluming-pipes/moisture-leaks.jpg'
import heroFloorTiles from './../../img/subcategory-hero/electricity-finishing/floor-tiles.jpg'
import heroPaintingDecoration from './../../img/subcategory-hero/electricity-finishing/painting-decoration.jpg'
import heroElectricity from './../../img/subcategory-hero/electricity-finishing/electricity.jpg'
import heroGuttersChimneys from './../../img/subcategory-hero/garden-facade-roof/gutters-and-chimneys.jpg'
import heroPaintingFacade from './../../img/subcategory-hero/garden-facade-roof/painting-facade.jpg'
import heroPruning from './../../img/subcategory-hero/garden-facade-roof/pruning.jpg'
//images-service-logos
import illustrationExpert from './../../img/illustration-expert.svg'
import slogoHeatingCoolingHeatingMaintenanceBoiler from './../../img/service-logos/heating-cooling/heating-maintenance/boiler.svg'
import slogoHeatingCoolingHeatingMaintenanceWaterBoiler from './../../img/service-logos/heating-cooling/heating-maintenance/water-boiler.svg'
import slogoHeatingCoolingHeatingInstallationRepairBoiler from './../../img/service-logos/heating-cooling/heating-installation-repair-and-breakdown/boiler.svg'
import slogoHeatingCoolingHeatingInstallationRepairWaterBoiler from './../../img/service-logos/heating-cooling/heating-installation-repair-and-breakdown/water-boiler.svg'
import slogoHeatingCoolingHeatingInstallationRepairFuelTank from './../../img/service-logos/heating-cooling/heating-installation-repair-and-breakdown/fuel-tank.svg'
import slogoHeatingCoolingHeatingRadiatorsPipesRadiator from './../../img/service-logos/heating-cooling/radiators-heat-pumps/radiator.svg'
import slogoHeatingCoolingHeatingRadiatorsPipesHeatPump from './../../img/service-logos/heating-cooling/radiators-heat-pumps/heat-pump.svg'
import slogoHeatingCoolingAirconditioning from './../../img/service-logos/heating-cooling/airconditioning/airconditioning.svg'
import slogoSanitaryPipesSewagePipesSanitaryPipes from './../../img/service-logos/sanitary-and-pipes/sewage-pipes-sanitairy/pipes.svg'
import slogoSanitaryPipesSewagePipesSanitaryShowerBathCranes from './../../img/service-logos/sanitary-and-pipes/sewage-pipes-sanitairy/bathroom-shower-cranes.svg'
import slogoSanitaryPipesSewagePipesSanitarySepticTank from './../../img/service-logos/sanitary-and-pipes/sewage-pipes-sanitairy/septic-tank.svg'
import slogoSanitaryPipesSewagePipesSanitaryUnclogging from './../../img/service-logos/sanitary-and-pipes/sewage-pipes-sanitairy/unclogging.svg'
import slogoSanitaryPipesMoistureLeaksMoisture from './../../img/service-logos/sanitary-and-pipes/moisture-leaks/moisture.svg'
import slogoSanitaryPipesMoistureLeaksLeaks from './../../img/service-logos/sanitary-and-pipes/moisture-leaks/leaks.svg'
import slogoFinishingElectricityFloorsTilesParquet from './../../img/service-logos/finishing-and-electricity/floor-tiles/parquet.svg'
import slogoFinishingElectricityFloorsTilesTiles from './../../img/service-logos/finishing-and-electricity/floor-tiles/tiles.svg'
import slogoFinishingElectricityPaintingDecorationPaint from './../../img/service-logos/finishing-and-electricity/painting-decoration/paint.svg'
import slogoFinishingElectricityElectricityInsallation from './../../img/service-logos/finishing-and-electricity/electricity/electrical-installation.svg'
import slogoFinishingElectricityElectricityHomeAutomation from './../../img/service-logos/finishing-and-electricity/electricity/home-automation.svg'
import slogoFinishingElectricityElectricityAlarmSystems from './../../img/service-logos/finishing-and-electricity/electricity/alarm-system.svg'
import slogoGardenFacadeRoofGuttersChimneysSweeping from './../../img/service-logos/garden-facade-and-roof/gutters-and-chimneys/chimney-sweeping.svg'
import slogoGardenFacadeRoofGuttersChimneysGuttersChimneys from './../../img/service-logos/garden-facade-and-roof/gutters-and-chimneys/gutters-and-chimneys.svg'
import slogoGardenFacadeRoofGuttersPaintingFacades from './../../img/service-logos/garden-facade-and-roof/painting-facade/painting-facade.svg'
import slogoGardenFacadeRoofsPruning from './../../img/service-logos/garden-facade-and-roof/pruning/pruning.svg'

import iconCheck from "./../../img/icon_fill_check.svg"




const Service = () => {
    const [state, setState] = useState({
        heroImage: "",
        subCatData: {},
        currentActiveTabIndex: 0,
        showOffCanvas: false
    });
    let { id } = useParams();
    const { userData } = useContext(UserContext);

    const getServiceLogo = (id) => {
        switch (id) {
            case 1: return slogoHeatingCoolingHeatingMaintenanceBoiler;
            case 2: return slogoHeatingCoolingHeatingMaintenanceWaterBoiler;
            case 3: return slogoHeatingCoolingHeatingInstallationRepairBoiler;
            case 4: return slogoHeatingCoolingHeatingInstallationRepairWaterBoiler;
            case 5: return slogoHeatingCoolingHeatingInstallationRepairFuelTank;
            case 6: return slogoHeatingCoolingHeatingRadiatorsPipesRadiator;
            case 7: return slogoHeatingCoolingHeatingRadiatorsPipesHeatPump;
            case 8: return slogoHeatingCoolingAirconditioning;
            case 9: return slogoSanitaryPipesSewagePipesSanitaryPipes;
            case 10: return slogoSanitaryPipesSewagePipesSanitaryShowerBathCranes;
            case 11: return slogoSanitaryPipesSewagePipesSanitarySepticTank;
            case 12: return slogoSanitaryPipesSewagePipesSanitaryUnclogging;
            case 13: return slogoSanitaryPipesMoistureLeaksMoisture;
            case 14: return slogoSanitaryPipesMoistureLeaksLeaks;
            case 15: return slogoFinishingElectricityFloorsTilesParquet;
            case 16: return slogoFinishingElectricityFloorsTilesTiles;
            case 17: return slogoFinishingElectricityPaintingDecorationPaint;
            case 18: return slogoFinishingElectricityElectricityInsallation;
            case 19: return slogoFinishingElectricityElectricityHomeAutomation;
            case 20: return slogoFinishingElectricityElectricityAlarmSystems;
            case 21: return slogoGardenFacadeRoofGuttersChimneysGuttersChimneys;
            case 22: return slogoGardenFacadeRoofGuttersChimneysSweeping;
            case 23: return slogoGardenFacadeRoofGuttersPaintingFacades;
            case 25: return slogoGardenFacadeRoofsPruning;
            default: return null;
        }
    }

    const updateTabIndex = (index) => {
        setState({ ...state, currentActiveTabIndex: index });
    }

    const openInfoCanvas = (service) => {
        setState({ ...state, showOffCanvas: true })

        window.dataLayer.push({
            "event": "virtual_page_view",
            "page": {
                "language": localStorage.getItem("language").toLowerCase(),
                "name": "service detail",
                "service sub category": state.subCatData.name.toLowerCase(),
                "service": service.name.toLowerCase()
            }
        });
    }

    const SetActiveTabFromOffCanvas = (index, service) => {
        setState({ ...state, currentActiveTabIndex: index })

        window.dataLayer.push({
            "event": "virtual_page_view",
            "page": {
                "language": localStorage.getItem("language").toLowerCase(),
                "name": "service detail",
                "service sub category": state.subCatData.name.toLowerCase(),
                "service": service.name.toLowerCase()
            }
        });
    }

    const closeOffCanvas = (index, service) => {
        setState({ ...state, showOffCanvas: false });
        window.dataLayer.push({
            "event": "virtual_page_view",
            "page": {
                "language": localStorage.getItem("language").toLowerCase(),
                "name": "service",
                "service sub category": state.subCatData.name.toLowerCase()
            }
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`api/servicesubcategory/getbyid?id=${id}`).then((response) => {
            switch (id) {
                case "1":
                    setState({ ...state, heroImage: heroHeatingMaintenance, subCatData: response.data.data })
                    break;
                case "2":
                    setState({ ...state, heroImage: heroHeatingInstallationRepairAndBreakdown, subCatData: response.data.data })
                    break;
                case "3":
                    setState({ ...state, heroImage: heroRadiatorHeatPumps, subCatData: response.data.data })
                    break;
                case "4":
                    setState({ ...state, heroImage: heroAirconditioning, subCatData: response.data.data })
                    break;
                case "5":
                    setState({ ...state, heroImage: heroSewagePipesSanitairy, subCatData: response.data.data })
                    break;
                case "6":
                    setState({ ...state, heroImage: heroMoistureLeak, subCatData: response.data.data })
                    break;
                case "7":
                    setState({ ...state, heroImage: heroFloorTiles, subCatData: response.data.data })
                    break;
                case "8":
                    setState({ ...state, heroImage: heroPaintingDecoration, subCatData: response.data.data })
                    break;
                case "9":
                    setState({ ...state, heroImage: heroElectricity, subCatData: response.data.data })
                    break;
                case "10":
                    setState({ ...state, heroImage: heroGuttersChimneys, subCatData: response.data.data })
                    break;
                case "11":
                    setState({ ...state, heroImage: heroPaintingFacade, subCatData: response.data.data })
                    break;
                case "13":
                    setState({ ...state, heroImage: heroPruning, subCatData: response.data.data })
                    break;
                default:
                    return;
            }
            window.dataLayer.push({
                "event": "virtual_page_view",
                "page": {
                    "language": localStorage.getItem("language").toLowerCase(),
                    "name": "service",
                    "service type": response.data.data.name.toLowerCase()
                }
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="text-black axa-bg-subcat">
                <img className="card-img hero-cover-img" src={state?.heroImage} alt="hero axa home" />
                <div className="container px-3">
                    <div className="axa-mobile-hidden" style={{ "height": "32px" }}></div>
                    <div className="cover-overlay-axa">
                        <div className="card axa-box-shadow-hero axa-hero-card-padding overflow-clip">
                            <div className="card-body">
                                <div className="title">{translate(state?.subCatData?.code, userData.language)}</div>
                                <br />
                                <label>{translate(state?.subCatData?.code + "Descr", userData.language)}</label>
                                <br />
                                <a className="btn btn-secondary mt-5 axa-mobile-hidden" href={`/newproject/${id}`} data-element-label="to contact form - box">{translate("buttonRequestYourQuote", userData.language)}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="axa-center-text axa-bg-subcat">
                <div className="container">
                    <div style={{ height: 32 }}></div>
                    <div className="axa-service-overview-text">
                        <Link className="axa-footer-link" to="/">
                            <FontAwesomeIcon className="me-1" icon={faArrowLeft} />
                            {translate("serviceBackToOverview", userData.language)}
                        </Link>
                    </div>
                    <div className="axa-mobile-hidden" style={{ height: 32 }}></div>
                    <div style={{ height: 32 }}></div>
                    <span className="axa-service-title" style={{ margin: "auto" }}>{translate("serviceChooseYourServiceTitle", userData.language)}</span>
                    <div style={{ height: 56 }}></div>
                </div>
            </div>
            <div className="axa-bg-subcat axa-subcat-card-padding">
                <div className="container">
                <div className="bg-grey-100">
                    <div className="axa-nav d-flex">
                        {state.subCatData.services?.map((service, index) => {
                            return (
                                <button
                                    key={"tabButton-" + index}
                                    className={"text-sm-center axa-tab axa-flex-even " + (state.currentActiveTabIndex === index ? "axa-active-tab" : "")}
                                    style={{ minWidth:"130px" }}
                                    onClick={() => updateTabIndex(index)}
                                >
                                    <img src={getServiceLogo(service.id)} alt="expert" style={{ maxHeight: "45px" }} className="axa-mobile-hidden" />
                                    <br />
                                    <span className="axa-break-word" >{translate(service.code, userData.language)}</span>
                                </button>
                            );
                        })}
                    </div>
                    <div className="tab-content">
                        {state.subCatData.services?.map((service, index) => {
                            return (
                                <div key={"service-" + index} className={"tab-pane fade " + (state.currentActiveTabIndex === index ? "show active" : "")} tabIndex="0">
                                    <div className="card">
                                        <div className="card-body">
                                            <div style={{ "height": "24px" }}></div>
                                            <div className="axa-mobile-hidden" style={{ "height": "32px" }}></div>
                                            <div className="row gap-2 justify-content-center px-2">
                                                <div className="col-md-6 col-sm-12 axa-order-mobile-2">
                                                    <label className="axa-service-card-title">{translate(service.code + "CardTitle", userData.language)}</label>
                                                    <div className="mt-4">
                                                        {[1, 2, 3, 4, 5].map(x => {
                                                            return (
                                                                <div key={"bulletpoint-" + x }>
                                                                    <div className="mt-2">
                                                                        <img className="axa-check-20" src={iconCheck} alt="check" />
                                                                        <span className="ms-1">{translate(service.code + "BulletPoint" + x, userData.language)}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) }
                                                    </div>
                                                    <div style={{ "height": "28px" }}></div>
                                                    <div className="axa-mobile-hidden" style={{ "height": "20px" }}></div>
                                                    <div className="d-flex flex-column flex-md-row gap-2">
                                                        <a className="btn btn-secondary" href={`/newproject/${id}/${service.id}`} data-element-label={`to contact form - ${service.name.toLowerCase()}`}>{translate("buttonRequestYourQuote", userData.language)}</a>
                                                        <button className="btn btn-outline-primary" onClick={() => openInfoCanvas(service)} data-element-label={`more info - ${service.name.toLowerCase()}`}>
                                                            {translate("buttonMoreInfo", userData.language)}
                                                            <FontAwesomeIcon className="ms-1" icon={faArrowRight} />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-12 axa-order-mobile-1 mb-5" align="center">
                                                    <img className="axa-service-representer-image" src={getServiceLogo(service.id)} alt="expert" />
                                                </div>
                                            </div>
                                            <div style={{ "height": "40px" }}></div>
                                            <div className="axa-mobile-hidden" style={{ "height": "32px" }}></div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    </div>
                </div>
            </div>
            <div className="axa-tertialy-viridian-green-bg axa-center-text overflow-clip">
                <div className="container pt-9">
                    <label className="title mb-4">{translate("homeHowItWorksTitle", userData?.language)}</label>
                    <div className="row justify-content-center">
                        <div className="col-md-8 axa-center-text">
                            <div className="title-sub-text pb-8">
                                {translate("homeHowItWorksTextLine1", userData?.language)} {translate("homeHowItWorksTextLine2", userData?.language)}
                            </div>
                        </div>
                    </div>
                    <div className="row g-0 pb-6">
                        <div className="col-lg-3 col-sm-12 mb-5">
                            <div className="axa-step-lines axa-step-first-child mb-3">
                                <div className="axa-step">
                                    <div className="axa-step-circle-content">1</div>
                                </div>
                            </div>
                            <div className="axa-step-title-text-content">
                                <div className="axa-step-title">{translate("homeHowItWorksStep1Title", userData?.language)}</div>
                                {translate("homeHowItWorksStep1Text", userData?.language)}
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 mb-5">
                            <div className="axa-step-lines mb-3">
                                <div className="axa-step">
                                    <div className="axa-step-circle-content">2</div>
                                </div>
                            </div>
                            <div className="axa-step-title-text-content">
                                <div className="axa-step-title">{translate("homeHowItWorksStep2Title", userData?.language)}</div>
                                {translate("homeHowItWorksStep2Text", userData?.language)}
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 mb-5">
                            <div className="axa-step-lines mb-3">
                                <div className="axa-step">
                                    <div className="axa-step-circle-content">3</div>
                                </div>
                            </div>
                            <div className="axa-step-title-text-content">
                                <div className="axa-step-title">{translate("homeHowItWorksStep3Title", userData?.language)}</div>
                                {translate("homeHowItWorksStep3Text", userData?.language)}
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="axa-step-lines axa-step-last-child mb-3">
                                <div className="axa-step-left-line"></div>
                                <div className="axa-step">
                                    <div className="axa-step-circle-content">4</div>
                                </div>
                            </div>
                            <div className="axa-step-title-text-content">
                                <div className="axa-step-title">{translate("homeHowItWorksStep4Title", userData?.language)}</div>
                                {translate("homeHowItWorksStep4Text", userData?.language)}
                            </div>
                        </div>
                    </div>
                    <a className="btn btn-secondary mb-11" href={`/newproject/${id}`} data-element-label="to contact form - how to">{translate("buttonRequestYourQuote", userData.language)}</a>
                </div>
            </div>
            <div className="container axa-center-text overflow-clip">
                <div className="row justify-content-center">
                    <div className="col-md-10 axa-center-text">
                        <label className="title mt-9 mb-5">{translate("homeFAQTitle", userData?.language)}</label>
                        <div align="left">
                        <Accordion items={[
                            {
                                header: <span className="title-accordion-alt">{translate("homeQ2Title", userData?.language)}</span>,
                                body: <span>
                                    {translate("homeQ2Text", userData?.language)}
                                </span>,
                                bodyClassName: "axa-center-start bg-grey-100"
                            },
                            {
                                header: <span className="title-accordion-alt">{translate("homeQ3Title", userData?.language)}</span>,
                                body: <span>
                                    {translate("homeQ3Text", userData?.language)}
                                </span>,
                                bodyClassName: "axa-center-start bg-grey-100"
                            },
                            {
                                header: <span className="title-accordion-alt">{translate("homeQ4Title", userData?.language)}</span>,
                                body: <span>
                                    {translate("homeQ4Text", userData?.language)}
                                </span>,
                                bodyClassName: "axa-center-start bg-grey-100"
                            },
                            {
                                header: <span className="title-accordion-alt">{translate("homeQ5Title", userData?.language)}</span>,
                                body: <span>
                                    {translate("homeQ5Text", userData?.language)}
                                </span>,
                                bodyClassName: "axa-center-start bg-grey-100"
                            },
                            ]} />
                        </div>
                    </div>
                </div>
            </div>
            <BootsrapOffcanvas
                show={state.showOffCanvas}
                close={() => closeOffCanvas()}
                body={(
                    <>
                        <div className="d-flex axa-bottom-border">
                            <div className="axa-mobile-hidden" style={{ "width": "65px" }}></div>
                            <div style={{ "width": "20px" }}></div>
                            <div className="axa-offcanvas-nav axa-sm-p-b-1">
                                {state.subCatData.services?.map((service, index) => {
                                    return (
                                        <button
                                            key={"offcanvas-tab-" + index}
                                            className={"axa-offcanvas-nav-item axa-tab " + (state.currentActiveTabIndex === index ? "active" : "bg-white")}
                                            onClick={() => SetActiveTabFromOffCanvas(index, service)}
                                            style={{ minWidth: "130px" }}
                                        >
                                            <span>{translate(service.code, userData.language)}</span>
                                        </button>
                                    );
                                })}
                            </div>
                            <div style={{ "width": "20px" }}></div>
                            <div className="axa-mobile-hidden" style={{ "width": "65px" }}></div>
                        </div>
                        <div className="tab-content mt-6">
                            {state.subCatData.services?.map((service, index) => {
                                return (
                                    <div key={"offcanvasService-" + index}
                                        className={"tab-pane fade axa-offcanvas-tabcontent-padding " + (state.currentActiveTabIndex === index ? "show active" : "")}
                                        tabIndex="0"
                                    >
                                        <div className="row axa-sm-p-x-3">
                                            <div className="col-12 px-0" align="center">
                                                <div style={{ "maxWidth": "730px" }} align="left">
                                                    <label className="axa-service-card-title">{translate(service.code, userData.language)}</label>
                                                    <div className="mt-4">
                                                        {[1, 2, 3, 4, 5].map(x => {
                                                            return (
                                                                <div key={"bulletpointoffcanvas-" + x}>
                                                                    <div className="mt-2">
                                                                        <img className="axa-check-20" src={iconCheck} alt="check" />
                                                                        <span className="ms-1">{translate(service.code + "BulletPoint" + x, userData.language)}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="mt-5">{translate(service.code + "OffCanvasBulletPointSubText", userData.language)}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12 order-md-1 order-sm-2">
                                                <a className="btn btn-secondary mt-5" href={`/newproject/${id}/${service.id}`} data-element-label="to contact form">{translate("buttonRequestYourQuote", userData.language)}</a>
                                            </div>
                                            <div className="col-md-4 col-sm-12 order-md-2 order-sm-1" align="center">
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            />
            <a className="btn btn-secondary fixed-bottom d-md-none" href="/newproject">{translate("buttonRequestYourQuote", userData.language)}</a>
        </div>
    );
};

export default Service;