import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import customStyles from '../common/CustomDataTableStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';

import ProviderInfo from './components/ProviderInfo';
import NewLoginUser from './components/NewLoginUser'
import WorkAreas from './components/WorkAreas'
import Services from './components/Services'
import BootstrapModal from '../common/BootstrapModal'
import handleResponse from '../common/ResponseHandler'

const EditProvider = () => {
    const [state, setState] = useState({
        name: "",
        address: "",
        contactName: "",
        contactPhoneNumber: "",
        contactEmail: "",
        currentActiveTabIndex: 0,
        providerInfoLoading: true,
        newLoginModalShown: false
    });

    const { getAccessTokenSilently } = useAuth0();
    let { id } = useParams();

    let webUserColumns = [
        {
            name: "Login Email",
            selector: row => <span>{row.loginEmail}</span>
        },
        {
            name: "Create Date",
            selector: row => <span>{moment(row.createDate).format('DD.MM.YYYY HH:mm')}</span>
        }
    ];
    const handleTextInputChange = (evt) => {
        setState({
            ...state,
            [evt.target.name]: evt.target.value
        });
    };

    const updateTabIndex = (index) => {
        setState({ ...state, currentActiveTabIndex: index });
    }

    const closeNewLoginModal = () => {
        setState({
            ...state,
            newLoginModalShown: false
        })
    }

    const openNewLoginModal = () => {
        setState({
            ...state,
            newLoginModalShown: true
        })
    }

    const updateProviderInfo = async () => {
        setState({ ...state, saveLoading: true });

        if (!state.name) {
            toast.warning("Provider Creation Failed: Missing Name.",
                { toastId: "provider-missing-name" });
            setState({ ...state, saveLoading: false });
            return;
        }

        var provider = {
            Id: id,
            Name: state.name,
            Address: state.address,
            ContactName: state.contactName,
            ContactPhoneNumber: state.contactPhoneNumber,
            ContactEmail: state.contactEmail
        };

        try {
            var token = await getAccessTokenSilently();
            const response = await axios.post("api/provider/updateprovider", provider, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Provider Update Failed.");
        } catch (error) {
            toast.error("Provider Update Failed.", { toastId: "provider-update-failed" });
        }
        setState({ ...state, saveLoading: false });
    }

    const getProvider = async () => {
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get("api/provider/getproviderbyid?id=" + id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.data?.isError) {
                await setState({
                    ...state,
                    id: response.data.data.id,
                    name: response.data.data.name,
                    address: response.data.data.address,
                    contactName: response.data.data.contactName,
                    contactPhoneNumber: response.data.data.contactPhoneNumber,
                    contactEmail: response.data.data.contactEmail,
                    providerInfoLoading: false
                });
            }
            else {
                toast.error("Provider Info Loading Failed.", { toastId: "provider-info-loading-failed" });
            }
        } catch (error) {
            toast.error("Provider Info Loading Failed.", { toastId: "provider-info-loading-failed" });
        }
    };

    const getLoginUser = async () => {
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get("api/loginuser/getloginusersbyproviderid?providerid=" + id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Error Loading Login Data");
            if (!response.data.isError) {
                await setState({
                    ...state,
                    loginUserData: response.data.data
                });
            }
        } catch (error) {
            toast.error("Error Loading Login Data", { toastId: "error-loading-login-data" });
        }
    }; 

    useEffect(() => {
        switch (state.currentActiveTabIndex) {
            case 0:
                getProvider();
                break;
            case 3:
                getLoginUser();
                break;
            default:
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.currentActiveTabIndex]);

    return (
        <div className="container">
            <div className="row border px-1 py-3">
                <div className="col-12">
                    <ul className="nav mb-1 border-bottom" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className={"nav-link " + (state.currentActiveTabIndex === 0 ? "active" : "")}
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-providerInfo"
                                type="button"
                                role="tab"
                                onClick={() => updateTabIndex(0)}
                            >
                                Information
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={"nav-link " + (state.currentActiveTabIndex === 1 ? "active" : "")}
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-workArea"
                                type="button"
                                role="tab"
                                onClick={() => updateTabIndex(1)}
                            >
                                Services
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={"nav-link " + (state.currentActiveTabIndex === 2 ? "active" : "")}
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-workArea"
                                type="button"
                                role="tab"
                                onClick={() => updateTabIndex(2)}
                            >
                                Work Area
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={"nav-link " + (state.currentActiveTabIndex === 3 ? "active" : "")}
                                id="pills-contact-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-webUsers"
                                type="button"
                                role="tab"
                                onClick={() => updateTabIndex(3)}
                            >
                                Web Users
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className={"tab-pane fade " + (state.currentActiveTabIndex === 0 ? "show active" : "")} id="pills-providerInfo" role="tabpanel" tabIndex="0">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-4">
                                            <div className="input-group mb-3">
                                                <span className="input-group-text">#</span>
                                                <div type="text" className="form-control" >{state.id}</div>
                                                <button className="btn btn-outline-info p-2" type="button" onClick={() => { navigator.clipboard.writeText(state.id); toast.success("Id Copied") }}>
                                                    <FontAwesomeIcon icon={faCopy} size="lg" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <ProviderInfo
                                        name={state.name}
                                        address={state.address}
                                        contactName={state.contactName}
                                        contactPhoneNumber={state.contactPhoneNumber}
                                        contactEmail={state.contactEmail}
                                        handleTextInputChange={handleTextInputChange}
                                        loading={state.providerInfoLoading}
                                    />
                                    <div className="d-flex justify-content-end">
                                        <button className="btn btn-primary" disabled={state.saveLoading} onClick={() => updateProviderInfo()}>Update Information</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"tab-pane fade " + (state.currentActiveTabIndex === 1 ? "show active" : "")} id="pills-workArea" role="tabpanel" tabIndex="1">
                            <div className="card">
                                <div className="card-body">
                                    <Services providerId={id} />
                                </div>
                            </div>
                        </div>
                        <div className={"tab-pane fade " + (state.currentActiveTabIndex === 2 ? "show active" : "")} id="pills-workArea" role="tabpanel" tabIndex="1">
                            <WorkAreas providerId={id} />
                        </div>
                        <div className={"tab-pane fade " + (state.currentActiveTabIndex === 3 ? "show active" : "")} id="pills-webUsers" role="tabpanel" tabIndex="2">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 mb-1">
                                            <button className="btn btn-outline-primary" onClick={openNewLoginModal}>
                                                <FontAwesomeIcon icon={faPlus} />
                                                <span className="ms-1">New Login</span>
                                            </button>
                                            <BootstrapModal
                                                shown={state.newLoginModalShown}
                                                onClose={closeNewLoginModal}
                                                title={state.name + ": New Login"}
                                                body={<NewLoginUser providerId={id} onSave={getLoginUser} />}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <DataTable
                                                customStyles={customStyles}
                                                data={state.loginUserData}
                                                columns={webUserColumns}
                                                pagination
                                                striped
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProvider;