import { useContext } from 'react';
import { UserContext } from '../../auth/auth0-provider-with-history';
import PrivacyPolicyNL from './components/PrivacyPolicyNL';
import PrivacyPolicyFR from './components/PrivacyPolicyFR';

const PrivacyPolicy = () => {
    const { userData } = useContext(UserContext);
    
    return (
        <div className="container mt-4">
            <div style={{ display: (userData?.language === "NL" ? "" : "none") }}>
                <PrivacyPolicyNL/>
            </div>
            <div style={{ display: (userData?.language !== "NL" ? "" : "none") }}>
                <PrivacyPolicyFR />
            </div>
        </div>
    );
};

export default PrivacyPolicy;