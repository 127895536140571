import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';

import handleResponse from '../common/ResponseHandler'
import BootsrapModal from '../common/BootstrapModal'
import { useDropzone } from 'react-dropzone';

const HomeProjectDetailsProvider = () => {
    const [state, setState] = useState({
        project: {},
        questions: [],
        invoiceLoaded: false,
        acceptShown: false,
        rejectShown: false,
        invoiceLoading: false,
        noAgreementText: ''
    });

    let { id } = useParams();
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const onDrop = async (acceptedFiles) => {
        await UploadInvoice(acceptedFiles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'application/pdf': ['.pdf']
        },
        maxFiles: 1
    });

    const getHomeProject = async provider => {
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get(`api/homeproject/get?id=${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Project retrieval failed.", true);
            if (!response.data.isError) {
                var questions = []
                response.data.data.homeProjectAnswers.forEach(x => {
                    if (!questions.some(y => y.id === x.answer.question.id)) {
                        questions.push({
                            id: x.answer.questionId,
                            questionText: x.answer.question.questionText
                        })
                    }
                });
                setState({
                    ...state,
                    project: response.data.data,
                    questions: questions,
                    invoiceLoaded: !isNullOrWhiteSpace(response.data.data.providerInvoiceFileName)
                });
            }
        } catch (error) {
            toast.error("Project retrieval failed.");
        }

    };

    const GetFile = async (fileName) => {
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get(`api/homeproject/getfile?name=${fileName}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.data.isError) {
                window.open(response.data.data, '_blank');
            }

        } catch (error) {
            toast.error("File retrieval failed.");
        }
    };

    const GetInvoice = async () => {
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get(`api/homeproject/getinvoice?name=${state.project.providerInvoiceFileName}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.data.isError) {
                window.open(response.data.data, '_blank');
            }

        } catch (error) {
            toast.error("File retrieval failed.");
        }
    };

    const AcceptProject = async () => {
        try {
            var token = await getAccessTokenSilently();
            const response = await axios.get(`api/homeproject/accept?id=${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Project could not be accepted.", true);
            if (!response.data.isError) {
                await getHomeProject();
            }
        } catch (error) {
            toast.error("Project could not be accepted.");
        }
    };

    const RejectProject = async () => {
        try {
            var token = await getAccessTokenSilently()
            const response = await axios.get(`api/homeproject/reject?id=${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Project could not be rejected.", true);
            if (!response.data.isError) {
                await navigate('/provider');;
            }
        } catch (error) {
            toast.error("Project could not be rejected.");
        }
    };

    const NoAgreementReached = async () => {
        try {
            if (!state.noAgreementText) {
                toast.error("No agreement text is missing");
                return;
            }
            var token = await getAccessTokenSilently()
            const response = await axios.get(`api/homeproject/noagreement?id=${id}&noAgreementText=${state.noAgreementText}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            handleResponse(response, "Project could not be rejected.", true);
            if (!response.data.isError) {
                await navigate('/provider');;
            }
        } catch (error) {
            toast.error("Project could not be rejected.");
        }
    };
    
    const UploadInvoice = async (files) => {
        setState({ ...state, invoiceLoading: true });
        
        const formData = new FormData();
        formData.append("homeProjectId", parseInt(id))
        formData.append("fileContent", files[0])
        
        try {
            var token = await getAccessTokenSilently()
            const requestHeaders = {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
            const response = await axios.post(`api/homeproject/uploadinvoice`, formData, {
                headers: requestHeaders
            });
            if (!response.data.isError) {
                await getHomeProject();
                return;
            }
            else {
                toast.error("File could not be loaded.");
            }
        } catch (error) {
            console.log(error)
            toast.error("File could not be loaded.");
        }
    }

    const isNullOrWhiteSpace = (input) => {
        return input === null || input === undefined || input.trim() === '';
    }

    useEffect(() => {
        getHomeProject();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="card axa-provider-info-card">
                        <div className="card-body">
                            <h2 className="card-subtitle text-secondary mt-4">Project Details</h2>
                            <hr className="hr-fade-20 m-0 mb-3" />
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Project Information</h4>
                                    <div className="axa-provider-info-card p-3 mb-3">
                                        <p><strong>Project ID:</strong> {state.project?.id}</p>
                                        <p><strong>Create Date:</strong> {moment(state.project?.createDate).format('DD.MM.YYYY HH:mm')}</p>
                                        <p><strong>Service Name:</strong> {state.project?.service?.name}</p>
                                        <p><strong>Customer Description:</strong> {state.project?.customerDescription}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Customer Information</h4>
                                    <div className="axa-provider-info-card p-3 mb-3">
                                        <p><strong>Customer First Name:</strong> {state.project?.customerName}</p>
                                        <p><strong>Customer Last Name:</strong> {state.project?.customerLastName}</p>
                                        <p><strong>Customer Telephone Number:</strong> {state.project?.customerGSM}</p>
                                        <p><strong>Customer Email:</strong> {state.project?.customerEmail}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4>Customer Address Details</h4>
                                    <div className="axa-provider-info-card p-3 mb-3">
                                        <p><strong>Customer Street/House Number:</strong> {state.project?.customerStreetHouseNumber}</p>
                                        <p><strong>Customer Bus:</strong> {state.project?.customerBus}</p>
                                        <p><strong>City:</strong> {state.project?.postalArea?.city}</p>
                                        <p><strong>City Postal Area:</strong> {state.project?.postalArea?.cityPostalArea}</p>
                                        <p><strong>Post Code:</strong> {state.project?.postalArea?.postCode}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {state.questions.map((question, index) => {
                                    return (
                                        <div key={"customer-questions-" + index} className="col-md-6">
                                            <h4>{question.questionText}</h4>
                                            <div className="axa-provider-info-card p-3 mb-3">
                                                <ul className="list-group">
                                                    {state.project.homeProjectAnswers.filter(x => x.answer.questionId === question.id).map((answer, index) => {
                                                        return (
                                                            <li key={"customer-answers-" + index} className="list-group-item">
                                                                {answer.answer.answerText}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="row">
                                <div className="col-md-6" style={{ display: (state.project?.homeProjectFile?.length === 0 ? "none" : "") }} >
                                    <h4>Customer Files</h4>
                                    <div className="axa-provider-info-card p-3 mb-3">
                                        <ul className="list-group">
                                            {state.project?.homeProjectFile?.map((file, index) => {
                                                return (
                                                    <li key={"customer-files-" + index} className="list-group-item p-0">
                                                        <button className="btn btn-link" onClick={() => GetFile(file.fileName)}>
                                                            <FontAwesomeIcon className="text-primary me-3" icon={faDownload} />{file.fileName.substring(36)}
                                                        </button>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Project Status</h4>
                                    <div className="axa-provider-info-card p-3 mb-3">
                                        <p><strong>Project Status:</strong> {state.project?.projectStatus?.name}</p>
                                        <div className="d-flex flex-column flex-md-column flex-lg-row">
                                            {(state.project?.projectStatus?.id === 1 || state.project?.projectStatus?.id === 6) ? (<>
                                                <button className="btn btn-outline-success flex-fill mx-1 mt-1" onClick={() => AcceptProject()}>I will contact the client</button>
                                                <button className="btn btn-outline-danger flex-fill mx-1 mt-1" onClick={() => RejectProject()}>Not interested, I won't contact the client</button>
                                            </>) : ""}
                                        </div>
                                        <div className={"row " + (state.project?.projectStatus?.id === 2 ? "d-flex" : "d-none")} >
                                            <textarea
                                                className="form-control  mb-1"
                                                name="address"
                                                placeholder="No Agreement Reason"
                                                onChange={(evt) => setState({ ...state, noAgreementText: evt.target.value })}
                                                value={state.noAgreementText}
                                            />
                                            <button className="btn btn-outline-danger" onClick={() => NoAgreementReached()}>No Agreement Reached</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4>Invoice</h4>
                                    <div className="axa-provider-info-card p-3 mb-3">
                                        <div style={{ display: (state.invoiceLoading ? "" : "none") }} align="center">
                                            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                                        </div>
                                        <div style={{ display: (state.invoiceLoading ? "none" : "") }}>
                                            <div className="axa-input-file-div p-1" style={{ display: (state.invoiceLoaded || state.project?.projectStatus?.id !== 2 ? "none" : "") }}>
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>Drag and drop a PDF file here, or click to select one</p>
                                                </div>
                                            </div>
                                            <ul className="list-group" style={{ display: (state.invoiceLoaded ? "" : "none") }}>
                                                <li className="list-group-item p-0">
                                                    <button className="btn btn-link" onClick={() => GetInvoice()}>
                                                        <FontAwesomeIcon className="text-primary me-3" icon={faDownload} />Invoice
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BootsrapModal
                body={<>Are you sure you want to accept this project?</>}
                footer={
                    <div className="row justify-content-end">
                        <div className="col"><button className="btn btn-success">Yes</button></div>
                        <div className="col"><button className="btn btn-danger">No</button></div>
                    </div>
                }
                shown={state.acceptShown}
                onClose={() => setState({ ...state, acceptShown: false })}
            />
            <BootsrapModal
                body={<>Are you sure you want to reject the project?</>}
                footer={<>footer test</>}
                shown={state.rejectShown}
                onClose={() => setState({ ...state, rejectShown: false })}
            />
        </div>
    );
};

export default HomeProjectDetailsProvider;