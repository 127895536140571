import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <div className="container">
            <div className="row mt-3">
                <div className="row">
                    <div className="col-12" align="center">
                        <h1>Axa Home Care Login</h1>
                        <h5 className="text-secondary">This page is used for internal users</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6" align="end">
                        <button className="btn btn-primary btn-block" onClick={() => loginWithRedirect()} >
                            <FontAwesomeIcon className="me-1" icon={faRightToBracket} />
                            Login
                        </button>
                    </div>
                    <div className="col-6" align="start">
                        <a href="/" className="btn btn-primary btn-block">
                            <FontAwesomeIcon className="me-1" icon={faHome} />
                            Home
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
