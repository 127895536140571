const customStyles = {
    rows: {
        style: {
            "font-size": '1rem', 
        },
    },
    headCells: {
        style: {
            "border-bottom": "solid",
            "font-size": '1rem'
        },
    },
    cells: {
        style: {
            "margin": "4px",
        },
    },
};

export default customStyles;