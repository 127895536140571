import { useContext } from 'react';
import axaCloseIcon from './../../img/axa_close.svg';
import translate from './Dictionary';
import { UserContext } from '../../auth/auth0-provider-with-history';


const BootsrapOffcanvas = (props) => {
    const { userData, setUserData } = useContext(UserContext);
    return (
        <>
            <div className={"offcanvas offcanvas-end " + (props.show? "show" : "") } tabIndex="-1">
                <div className="offcanvas-header bg-grey-100">
                    <div></div>
                    <button className="axa-close-offcanvas-button" onClick={() => props.close()}>
                        <label className="axa-close-offcanvas-text me-2">{translate("btnCloseOffcanvas", userData.language)}</label>
                        <img src={axaCloseIcon} alt="axa-close-btn" style={{ width: "24px", height:"24px" }} />
                    </button>
                </div>
                <div className="offcanvas-body p-0">
                    {props.body}
                </div>
            </div>
            <div className={"offcanvas-backdrop fade " + (props.show ? "show" : "")} style={{ display: (props.show? "" : "none") }}></div>
        </>
    );
};

export default BootsrapOffcanvas;

