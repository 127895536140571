
const PrivacyPolicyFR = () => {
   return (
       <div align="left">
           <h1 className="text-info">HOMECARE</h1>
           <h1 className="text-info">Politique en matière de vie privée</h1>
           <p>Ce document constitue la politique en matière de traitement des données à caractère personnel, applicable à l’utilisation de l’Application web.</p>
           <p>Ce document pourra être adapté à l’avenir. Nous vous invitons à le consulter régulièrement.</p>
           <div className="ms-5">
               <h2 className="text-secondary">MENTIONS LÉGALES – RESPONSABLE DU TRAITEMENT</h2>
               <span>SA INTER PARTNER ASSISTANCE</span>
               <br />
               <span>Membre du Groupe AXA ASSISTANCE</span>
               <br />
               <span>Siège social : Boulevard du Régent 7, 1000 Bruxelles</span>
               <br />
               <span>Immatriculée à la Banque Carrefour des Entreprises sous le n° 0415.591.055, RPM Bruxelles Entreprise d’assurance agréée sous le code 0487</span>
               <br />
               <br />
               <p>(ci-après dénommée « AXA ASSISTANCE »).</p>
               <h2 className="text-secondary">DEFINITIONS</h2>
               <p>L’Editeur attire l’attention des Utilisateurs de son site sur les points ci-après :</p>
               <ul>
                   <li>« Application web» désigne HOMECARE.</li>
                   <li>« AXA ASSISTANCE » désigne la SA INTER PARTNER ASSISTANCE, décrite ci-dessus ;</li>
                   <li>« Données » désigne les données à caractère personnelle ;</li>
                   <li>« Editeur » désigne AXA ASSISTANCE ;</li>
                   <li>« Législation » désigne les législations et réglementations belges, européennes et internationales, relatives à la protection de la vie prive et au traitement des données personnelles, notamment le RGPD et les lois et arrêtés belges qui complète ce Règlement ;</li>
                   <li>« Responsable du Traitement » désigne le responsable du traitement au sens donné par le RGPD ; le responsable du traitement est AXA ASSISTANCE ;</li>
                   <li>« RGPD » désigne le Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE ;</li>
                   <li>« Sous-traitant » désigne le sous-traitant, au sens donné par le RGPD ;</li>
                   <li>« Utilisateur » désigne toute personne utilisant l’Application mobile ;</li>
                   <li>« Vous » ou « Bénéficiaire » désignent le bénéficiaire des prestations d’assistance (telles que définies dans les conditions contractuelles de votre contrat ou de votre police), qui accède, consulte, utilise l’Application mobile pour la mise en œuvre des Prestations d’assistance.</li>
               </ul>
               <h2 className="text-secondary">FINALITE DU TRAITEMENT</h2>
               <p>Les données à caractère personnel que vous communiquez via l’Application Web à AXA ASSISTANCE  peuvent être traitées par celle-ci pour les finalités suivantes :</p>
               <ul>
                   <li>La fourniture des services d’assistance,</li>
                   <li>Le développement, la gestion, l’adaptation et la mise à jour de l’Application mobile et/ou de ses fonctionnalités,</li>
                   <li>l’analyse (en ce compris l’analyse technique ou statistique) des usages de l’Application mobile et/ou de ses fonctionnalités et des connexions à l’Application,</li>
                   <li>la gestion du fichier des personnes,</li>
                   <li>le service à la clientèle,</li>
                   <li>la détection, prévention et lutte contre la fraude,</li>
                   <li>la lutte contre le blanchiment de capitaux et le financement du terrorisme,</li>
                   <li>la surveillance du portefeuille,</li>
                   <li>le respect des législations applicables (fiscales, sociales, comptables, etc.) aux services d’assistance prévus par votre contrat ou votre police d’assurance, • les études statistiques.</li>
               </ul>
               <h2 className="text-secondary">DONNEES TRAITEES VIA L’APPLICATION WEB</h2>
               <p>AXA ASSISTANCE peut récolter, via l’Application web, les Données Personnelles suivantes : vos nom et prénom, votre numéro de téléphone, votre adresse, votre adresse mail, le type de service/ prestation demandé, votre adresse IP, votre langue, vos données de connexion, ainsi que les données relatives aux événements liés aux appareils que vous utilisez (activité du système, date et heure de connexion, paramètre du matériel, problèmes techniques, etc.).</p>
               <h2 className="text-secondary">BASES LEGALES DU TRAITEMENT</h2>
               <p>AXA ASSISTANCE traite vos Données Personnelles en se fondant sur les bases légales suivantes :</p>
               <ul>
                   <li>L’exécution de votre contrat ou de votre police d’assurance ainsi que des conditions générales d’utilisation de l’Application mobile : en vue de vous fournir les prestations d’assistance, de gérer ou d’exécuter votre contrat ou votre police, de gérer l’Application mobile,</li>
                   <li>La poursuite des intérêts légitimes d’AXA ASSISTANCE : en vue de gérer l’Application mobile, de procéder aux analyses techniques ou statistiques, en vue de gérer la relation avec l’assuré, le Bénéficiaire ou l’Utilisateur, en vue de détecter, prévenir et lutter contre les fraudes, en vue de surveiller son portefeuille ;</li>
                   <li>L’exécution d’obligation légale et réglementaire : en vue de respecter et d’appliquer les législations applicables (fiscales, sociales, comptables, etc.) aux services d’assistance prévus par votre contrat ou votre police d’assurance.</li>
               </ul>
               <p>Les Données Personnelles qu’AXA ASSISTANCE vous demande de fournir sont nécessaires à la bonne utilisation de l’Application mobile et des services d’assistance liés. Ne pas fournir ces données peut rendre impossible ou difficile la conclusion ou la bonne utilisation de l’Application mobile, de ses fonctionnalités et/ou des services d’assistance liés.</p>
               <h2 className="text-secondary">TRANSFERT DES DONNÉES</h2>
               <p>Dans la mesure où la communication des données à caractère personnel est nécessaire pour permettre de réaliser les finalités énumérées ci-dessus, AXA ASSISTANCE peut transférer vos Données Personnelles à d’autres personnes, qu’il s’agisse des autres entreprises du Groupe AXA ou de tiers à d’autres entreprises membres du Groupe AXA, à des entreprises et/ou à des personnes en relation avec celles-ci (prestataires de services d’assistance, avocats, experts, médecins conseils, réassureurs, co-assureurs, intermédiaires d’assurances, prestataires de services, autres entreprises d’assurances, représentants, bureau de suivi de la tarification, bureaux de règlement de sinistres, Datassur). Les autres entreprises du Groupe AXA, les entreprises et/ou les personnes en relation avec celles-ci auxquelles les Données Personnelles sont communiquées, peuvent être situées aussi bien dans l’Union Européenne qu’en dehors.</p>
               <p>En cas de transferts de données à caractère personnel à des tiers situés en dehors de l’Union Européenne, AXA ASSISTANCE se conforme aux dispositions légales et réglementaires en vigueur en matière de tels transferts. Elle assure, notamment, un niveau de protection adéquat aux données à caractère personnel ainsi transférées sur la base des mécanismes alternatifs mis en place par la Commission européenne, tels les clauses contractuelles standard, ou encore les règles d’entreprise contraignantes   du Groupe AXA en cas de transferts intragroupe (Mon. B. 6/10/2014, p. 78547).</p>
               <p>Ces données peuvent également être communiquées aux autorités de contrôle, aux services publics compétents ainsi qu’à tout autre organisme public ou privé avec lequel AXA ASSISTANCE peut être amenée à échanger des Données Personnelles conformément à la législation applicable.</p>
               <h2 className="text-secondary">CONSERVATION DES DONNÉES</h2>
               <p>AXA ASSISTANCE conserve les Données Personnelles récoltées via l’Application mobile pendant toute la durée de l’utilisation de cette Application et de la fourniture des services d’assistance liés. Cette durée peut être prolongée du délai légal de conservation (en matière comptable ou fiscale, notamment) ou du délai de prescription de manière à pouvoir faire face aux demandes ou aux éventuels recours qui seraient engagés après la fin de l’utilisation de l’Application mobile ou après la clôture du dossier sinistre.</p>
               <p>AXA ASSISTANCE s’engage à garder vos Données Personnelles de manière sécurisée, confidentielle, exacte pendant la période d’utilisation autorisée par les présentes. Au terme de la période de conservation, vos Données Personnelles seront rendues anonymes ou détruites.</p>
               <h2 className="text-secondary">CONFIDENTIALITÉ</h2>
               <p>AXA ASSISTANCE a pris toutes les mesures nécessaires afin de préserver la confidentialité des Données Personnelles et afin de se prémunir contre tout accès non autorisé, toute mauvaise utilisation, modification ou suppression de celles-ci.</p>
               <p>A cette fin, AXA ASSISTANCE suit les standards de sécurité et de continuité de service et évalue régulièrement le niveau de sécurité de ses processus, systèmes et applications ainsi que ceux de ses partenaires.</p>
               <h2 className="text-secondary">DROITS DE LA PERSONNE CONCERNÉE</h2>
               <p>Vous avez le droit :</p>
               <ul>
                   <li>d'obtenir d’AXA ASSISTANCE la confirmation que des Données Personnelles vous concernant sont ou ne sont pas traitées et, lorsqu'elles le sont, d’accéder à ces données ;</li>
                   <li>de faire rectifier et, le cas échéant, de faire compléter vos Données Personnelles qui sont inexactes ou incomplètes ;</li>
                   <li>de faire effacer vos Données Personnelles dans certaines circonstances ;</li>
                   <li>de faire limiter le traitement de vos Données Personnelles dans certaines circonstances ;</li>
                   <li>de vous opposer, pour des raisons tenant à votre situation particulière, au traitement de vos Données Personnelles, fondé sur les intérêts légitimes d’AXA ASSISTANCE. AXA ASSISTANCE ne traite plus les Données Personnelles, à moins qu'elle ne démontre qu'il existe des motifs légitimes et impérieux pour le traitement qui prévalent sur les intérêts et les droits et libertés de la personne concernée ;</li>
                   <li>de vous opposer au traitement de vos Données Personnelles à des fins de marketing direct, y compris au profilage effectué à des fins de marketing direct ;</li>
                   <li>de ne pas faire l’objet d’une décision fondée exclusivement sur un traitement  automatisé, y compris le profilage, produisant des effets juridiques la concernant ou l’affectant de manière significative ; toutefois, si  ce traitement automatisé est nécessaire à la conclusion ou à l'exécution d'un contrat, vous avez le droit d'obtenir une intervention humaine de la part d’AXA ASSISTANCE, d'exprimer votre point de vue et de contester la décision d’AXA ASSISTANCE;</li>
                   <li>de recevoir vos Données Personnelles fournies à AXA ASSISTANCE, dans un format structuré, couramment utilisé et lisible par machine ; de transmettre ces données à un autre responsable du traitement, lorsque (i) le traitement de vos Données Personnelles est fondé sur son consentement ou pour les besoins de l’exécution d’un contrat et (ii) le traitement est effectué à l'aide de procédés automatisés ; et d'obtenir que vos Données Personnelles soient transmises directement d’AXA ASSISTANCE à un responsable du traitement, lorsque cela est techniquement possible ;</li>
                   <li>de retirer votre consentement à tout moment, sans préjudice des traitements effectués de manière licite avant le retrait de celui, lorsque le traitement de vos Données Personnelles est fondé sur son consentement.</li>
               </ul>
               <h2 className="text-secondary">CONTACTER AXA ASSISTANCE</h2>
               <p>Vous pouvez contacter AXA ASSISTANCE pour exercer vos droits aux adresses suivantes. Votre demande doit être accompagné d'une photocopie recto verso de la carte d'identité (ou de tout document d’identité) :</p>
               <ul>
                   <li>
                       <span>par courrier postal:</span>
                       <br />
                       <span>Data Protection Officer</span>
                       <br />
                       <span>Inter Partner Assistance S.A.</span>
                       <br />
                       <span>Boulevard du Régent 7</span>
                       <br />
                       <span>1000 Bruxelles</span>
                   </li>
                   <li>
                       <span>par courrier électronique:</span>
                       <br />
                       <span>dpo.BNL@axa-assistance.com</span>
                   </li>
               </ul>
               <p>AXA ASSISTANCE traitera les demandes dans les délais prévus par la loi. Sauf demande manifestement infondée ou excessive, aucun paiement ne sera exigé pour le traitement de ses demandes.</p>
               <h2 className="text-secondary">INTRODUIRE UNE PLAINTE</h2>
               <p>Si vous estimez qu’AXA ASSISTANCE ne respecte pas la règlementation en la matière, vous êtes invité.e à consulter AXA ASSISTANCE en priorité.</p>
               <p>Vous pouvez aussi introduire une réclamation auprès de l’Autorité de Protection des Données Personnelles à l’adresse suivante :</p>
               <span>AUTORITE DE PROTECTION DE DONNEES</span>
               <br />
               <span>Rue de la Presse, 35</span>
               <br/>
               <span>1000 Bruxelles</span>
               <br />
               <span>Tél. + 32 2 274 48 00 Fax. + 32 2 274 48 35</span>
               <br />
               <span>commission@privacycommission.be</span>
               <p>Vous pouvez également déposer une plainte auprès du tribunal de première instance de votre domicile.  </p>
           </div>
        </div>
    );
};

export default PrivacyPolicyFR;