import { useState, useContext, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../auth/auth0-provider-with-history';

const LanguageSelector = (props) => {
    const [show, setShow] = useState(false);
    const { userData, setUserData } = useContext(UserContext);

    return (
        <>
            <div className="dropdown language-selector-width m-0 p-0">
                <button className={"btn dropdown-toggle border-0 text-primary language-selector-button " + (show ? "show bg-dark-langugage-selector" : "")} type="button" onClick={() => setShow(!show)}>
                    <label className="language-selector-font">{props.languages.find(x => x === userData.language)}</label>
                    <FontAwesomeIcon className="ms-2" icon={show ? faChevronUp : faChevronDown} />
                </button>
                <div className={"dropdown-menu language-selector-options " + (show ? "show" : "")}>
                    {props.languages.filter(x => x !== userData.language).map((language) => {
                        return (<button key={language} onClick={() => {
                            setUserData({ ...userData, language: language });
                            localStorage.setItem("language", language);
                            document.documentElement.lang = localStorage.getItem("language").toLowerCase();
                            setShow(!show);
                        }
                        } className="dropdown-item language-selector-font py-3">{language}</button>);
                    })}
                </div>
            </div>
        </>
    );
};

export default LanguageSelector;

