import { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Accordion from '../common/Accordion';
import axios from 'axios';
import translate from '../common/Dictionary';
import { UserContext } from '../../auth/auth0-provider-with-history';

//images
import homeHeroImage from './../../img/hero-home-image.jpg'
import catLogHeatingCooling from './../../img/category-logos/heating-cooling.svg'
import catLogSanitaryPipes from './../../img/category-logos/sanitary-pipes.svg'
import catLogFinishingElectricity from './../../img/category-logos/finishing-electricity.svg'
import catLogGardenFacadeRoof from './../../img/category-logos/garden-facade-roof.svg'
import subCatHeatingMaintenance from './../../img/subcategory-logos/heating-cooling/heating-maintenance.svg'
import subCatHeatingInstallationRepairAndBreakdown from './../../img/subcategory-logos/heating-cooling/heating-installation-repair-and-breakdown.svg'
import subCatRadiatorHeatPumps from './../../img/subcategory-logos/heating-cooling/radiators-heat-pumps.svg'
import subCatAirconditioning from './../../img/subcategory-logos/heating-cooling/airconditioning.svg'
import subCatSewagePipesSanitairy from './../../img/subcategory-logos/pluming-pipes/sewage-pipes-sanitairy.svg'
import subCatMoistureLeak from './../../img/subcategory-logos/pluming-pipes/moisture-leaks.svg'
import subCatFloorTiles from './../../img/subcategory-logos/electricity-finishing/floor-tiles.svg'
import subCatPaintingDecoration from './../../img/subcategory-logos/electricity-finishing/painting-decoration.svg'
import subCatElectricity from './../../img/subcategory-logos/electricity-finishing/electricity.svg'
import subCatGuttersChimneys from './../../img/subcategory-logos/garden-facade-roof/gutters-and-chimneys.svg'
import subCatPaintingFacade from './../../img/subcategory-logos/garden-facade-roof/painting-facade.svg'
import subCatPruning from './../../img/subcategory-logos/garden-facade-roof/pruning.svg'

import TagManager from 'react-gtm-module'



const Home = () => {
    const [state, setState] = useState({
        services: [],
        initialCatIndex: undefined
    })
    const { userData, setUserData } = useContext(UserContext);
    let { activecategory } = useParams();
    
    const chooseCategoryLogo = (id) => {
        if (id === 1) return (catLogHeatingCooling);
        if (id === 2) return (catLogSanitaryPipes);
        if (id === 3) return (catLogFinishingElectricity);
        if (id === 4) return (catLogGardenFacadeRoof);
    }

    const chooseSubCategoryLogo = (id) => {
        if (id === 1) return (subCatHeatingMaintenance);
        if (id === 2) return (subCatHeatingInstallationRepairAndBreakdown);
        if (id === 3) return (subCatRadiatorHeatPumps);
        if (id === 4) return (subCatAirconditioning);
        if (id === 5) return (subCatSewagePipesSanitairy);
        if (id === 6) return (subCatMoistureLeak);
        if (id === 7) return (subCatFloorTiles);
        if (id === 8) return (subCatPaintingDecoration);
        if (id === 9) return (subCatElectricity);
        if (id === 10) return (subCatGuttersChimneys);
        if (id === 11) return (subCatPaintingFacade);
        if (id === 13) return (subCatPruning);
    }

    const getServices = async () => {
        const serviceResponse = await axios.get("api/servicecategory/getallactive");
        var newState = {
            services: serviceResponse.data.data
        }
        setState(newState);
    }

    const setLanguage = (language) => {
        localStorage.setItem("language", language);
        setUserData({
            ...userData,
            language: localStorage.getItem("language")
        });
        document.documentElement.lang = localStorage.getItem("language").toLowerCase();
    }

    useEffect(() => {
        getServices();
        switch (activecategory) {
            case "verwarming-verkoeling":
                setState({ ...state, initialCatIndex: 0 });
                setLanguage("NL");
                break;
            case "chauffage-climatisation":
                setState({ ...state, initialCatIndex: 0 });
                setLanguage("FR");
                break;
            case "sanitair-leidingen":
                setState({ ...state, initialCatIndex: 1 });
                setLanguage("NL");
                break;
            case "sanitaires-conduites":
                setState({ ...state, initialCatIndex: 1 });
                setLanguage("FR");
                break;
            case "afwerking-electriciteit":
                setState({ ...state, initialCatIndex: 2 });
                setLanguage("NL");
                break;
            case "finitions-electricite":
                setState({ ...state, initialCatIndex: 2 });
                setLanguage("FR");
                break;
            case "tuin-gevel-dak":
                setState({ ...state, initialCatIndex: 3 });
                setLanguage("NL");
                break;
            case "jardin-facade-toiture":
                setState({ ...state, initialCatIndex: 3 });
                setLanguage("FR");
                break;
            case "nl":
                setLanguage("NL");
                break;
            case "fr":
                setLanguage("FR");
                break;
            default:
                break;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "page": {
                "language": localStorage.getItem("language")?.toLowerCase(),
                "name": "home"
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="text-black bg-grey-100">
                <img className="card-img hero-cover-img" src={homeHeroImage} alt="hero axa home" />
                <div className="container px-3">
                    <div className="cover-overlay-axa">
                        <div className="card axa-box-shadow-hero axa-hero-card-padding overflow-clip">
                            <div className="card-body">
                                <div className="title">{translate("homeHeroTitle", userData?.language)}</div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item border-0 ps-0 pb-0">
                                        <div className="row">
                                            <div className="col-1">
                                                <FontAwesomeIcon icon={faCheck} className="axa-hero-overlay-list-check me-2" />
                                            </div>
                                            <div className="col-10">
                                                <label className="axa-hero-overlay-list-font">
                                                    {translate("homeHeroListItem1", userData?.language)}
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item border-0 ps-0 pb-0">
                                        <div className="row">
                                            <div className="col-1">
                                                <FontAwesomeIcon icon={faCheck} className="axa-hero-overlay-list-check me-2" />
                                            </div>
                                            <div className="col-10">
                                                <label className="axa-hero-overlay-list-font">
                                                    {translate("homeHeroListItem2", userData?.language)}
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item border-0 ps-0 pb-0">
                                        <div className="row">
                                            <div className="col-1">
                                                <FontAwesomeIcon icon={faCheck} className="axa-hero-overlay-list-check me-2" />
                                            </div>
                                            <div className="col-10">
                                                <label className="axa-hero-overlay-list-font">
                                                    {translate("homeHeroListItem3", userData?.language)}
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <a className="btn btn-secondary mt-5 axa-mobile-hidden"
                                    href="/newproject"
                                    data-element-label="to contact form - box"
                                >{translate("buttonRequestYourQuote", userData.language)}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-8 axa-center-text">
                        <div className="axa-mobile-hidden" style={{ height: 32 }}></div>
                        <div style={{ height: 32 }}></div>
                        <span className="title" style={{ margin: "auto" }}>{translate("homeHappyToHelpTitle", userData?.language)}</span>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-8 title-sub-text">
                        <div style={{ height: 24 }}></div>
                            {translate("homeHappyToHelpText", userData?.language)}
                        <div style={{ height: 56 }}></div>
                    </div>
                </div>
            </div>
            <div className="container ps-3 pe-3">
                <Accordion
                    initialIndex={state.initialCatIndex}
                    buttonClassName="axa-accordion-button-padding"
                    bodyClassName="p-0 bg-grey-100"
                    items={state.services.map(((category) => {
                    return {
                        header:
                            <div className="d-flex">
                                <div className="axa-vertical-align-content">
                                    <img src={chooseCategoryLogo(category.id)} className="me-3" alt={category.name} />
                                </div>
                                <div>
                                    <span className="title-accordion">
                                        {translate(category.code, userData?.language)}
                                    </span>
                                </div>
                            </div>,
                        body: <div>
                            <div className="row axa-accordion-row">
                                {category.serviceSubCategories.map((subCategory) => {
                                    return (
                                        <Link key={"subCat-" + subCategory.id} className="col-6 col-lg-3 col-md-4" to={"/service/" + subCategory.id} data-element-label={`${category.name.toLowerCase()} - ${subCategory.name.toLowerCase()}`}>
                                            <div className="axa-service-category-card">
                                                <img className="axa-subcategory-logo" src={chooseSubCategoryLogo(subCategory.id)} alt={subCategory.name} />
                                                <br />
                                                <label className="axa-card-header axa-break-word">{translate(subCategory.code, userData?.language)}</label>
                                            </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>,
                    }
                }))} />
            </div>
            <div className="axa-tertialy-viridian-green-bg axa-center-text mt-11 overflow-clip">
                <div className="container pt-9">
                    <label className="title mb-4">{translate("homeHowItWorksTitle", userData?.language)}</label>
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="title-sub-text pb-8">
                                {translate("homeHowItWorksTextLine1", userData?.language)} {translate("homeHowItWorksTextLine2", userData?.language)}
                            </div>
                        </div>
                    </div>
                    <div className="row g-0 pb-5">
                        <div className="col-lg-3 col-sm-12 mb-5">
                            <div className="axa-step-lines axa-step-first-child mb-3">
                                <div className="axa-step">
                                    <div className="axa-step-circle-content">1</div>
                                </div>
                            </div>
                            <div className="axa-step-title-text-content">
                                <div className="axa-step-title">{translate("homeHowItWorksStep1Title", userData?.language)}</div>
                                {translate("homeHowItWorksStep1Text", userData?.language)}
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 mb-5">
                            <div className="axa-step-lines mb-3">
                                <div className="axa-step">
                                    <div className="axa-step-circle-content">2</div>
                                </div>
                            </div>
                            <div className="axa-step-title-text-content">
                                <div className="axa-step-title">{translate("homeHowItWorksStep2Title", userData?.language)}</div>
                                {translate("homeHowItWorksStep2Text", userData?.language)}
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 mb-5">
                            <div className="axa-step-lines mb-3">
                                <div className="axa-step">
                                    <div className="axa-step-circle-content">3</div>
                                </div>
                            </div>
                            <div className="axa-step-title-text-content">
                                <div className="axa-step-title">{translate("homeHowItWorksStep3Title", userData?.language)}</div>
                                {translate("homeHowItWorksStep3Text", userData?.language)}
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="axa-step-lines axa-step-last-child mb-3">
                                <div className="axa-step-left-line"></div>
                                <div className="axa-step">
                                    <div className="axa-step-circle-content">4</div>
                                </div>
                            </div>
                            <div className="axa-step-title-text-content">
                                <div className="axa-step-title">{translate("homeHowItWorksStep4Title", userData?.language)}</div>
                                {translate("homeHowItWorksStep4Text", userData?.language)}
                            </div>
                        </div>
                    </div>
                    <a className="btn btn-secondary mb-11" href="/newproject" data-element-label="to contact form - how to">{translate("buttonRequestYourQuote", userData.language)}</a>
                </div>
            </div>
            <div className="container ps-5 pe-5 axa-center-text overflow-clip">
                <div className="row justify-content-center">
                    <div className="col-md-10 axa-center-text">
                        <label className="title mt-9 mb-5">{translate("homeFAQTitle", userData?.language)}</label>
                        <div align="left">
                        <Accordion
                            items={[
                                {
                                    header: <span className="title-accordion-alt">{translate("homeQ2Title", userData?.language)}</span>,
                                    body: <span>
                                        {translate("homeQ2Text", userData?.language)}
                                    </span>,
                                    bodyClassName: "axa-center-start bg-grey-100"
                                },
                                {
                                    header: <span className="title-accordion-alt">{translate("homeQ3Title", userData?.language)}</span>,
                                    body: <span>
                                        {translate("homeQ3Text", userData?.language)}
                                    </span>,
                                    bodyClassName: "axa-center-start bg-grey-100"
                                },
                                {
                                    header: <span className="title-accordion-alt">{translate("homeQ4Title", userData?.language)}</span>,
                                    body: <span>
                                        {translate("homeQ4Text", userData?.language)}
                                    </span>,
                                    bodyClassName: "axa-center-start bg-grey-100"
                                },
                                {
                                    header: <span className="title-accordion-alt">{translate("homeQ5Title", userData?.language)}</span>,
                                    body: <span>
                                        {translate("homeQ5Text", userData?.language)}
                                    </span>,
                                    bodyClassName: "axa-center-start bg-grey-100"
                                },
                            ]}
                            buttonClassName="axa-accordion-button-padding"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <a className="btn btn-secondary fixed-bottom d-md-none" href="/newproject">{translate("buttonRequestYourQuote", userData.language)}</a>
        </div>
    );
};

export default Home;