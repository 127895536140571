import { useContext } from 'react';
import { UserContext } from '../../auth/auth0-provider-with-history';
import CookiePolicyNL from './components/CookiePolicyNL';
import CookiePolicyFR from './components/CookiePolicyFR';

const CookiePolicy = () => {
    const { userData } = useContext(UserContext);

    return (
        <div className="container mt-4">
            <div style={{ display: (userData?.language === "NL" ? "" : "none") }}>
                <CookiePolicyNL />
            </div>
            <div style={{ display: (userData?.language !== "NL" ? "" : "none") }}>
                <CookiePolicyFR />
            </div>
        </div>
    );
};

export default CookiePolicy;